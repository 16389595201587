import { createContext } from "react";

const GeneralContext = createContext();

const GeneralProvider = ({ children, userData, setUserData, proxy, value }) => {
  let newValue;
  if (typeof value !== "undefined") {
    newValue = {
      ...value,
      userData: userData,
      setUserData: setUserData,
      proxy: proxy,
    };
  } else {
    newValue = { userData: userData, setUserData: setUserData, proxy: proxy };
  }
  return (
    <GeneralContext.Provider value={newValue}>
      {children}
    </GeneralContext.Provider>
  );
};

export { GeneralContext, GeneralProvider };
