import React, { Component } from "react";
import VistaPM from "./vistaPM";

const ConsultarPM = ({ navigation }) => {
  return (
    <VistaPM
      title="Consultar PMs"
      navigation={navigation}
      endpoint="/pm/consultarPM"
    />
  );
};

export default ConsultarPM;
