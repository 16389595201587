import React, { Component } from "react";
import { View, ActivityIndicator } from "react-native";
import Menu from "../../general/menuGenerico/menu";
import CardPromocion from "./cardPromocion";
import withTheme from "../../general/withTheme";
import withGeneralState from "../../general/withGeneralState";
import Toast from "react-native-root-toast";
import BasicCard from "../../general/menuGenerico/basicCard";

class Promociones extends Component {
  state = {
    promociones: [],
    openedCard: null,
    loading: false,
    changed: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          pms: [],
          openedCard: null,
          dropDownOpen: false,
          changed: false,
        },
        async () => {
          let res = await fetch(
            this.props.value.proxy + `/promoTKTs/promociones`
          );

          if (res.status == 200) {
            let result = await res.json();
            this.setState({
              promociones: result,
              loading: false,
            });
          } else {
            this.error(res.status);
          }
        }
      );
    }
  }

  async error(status) {
    {
      const colors = this.props.value.colors;
      let error;
      if (status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        if (status == 400) {
          error = "Sala no seleccionada.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Salas" }],
          });
        } else {
          switch (status) {
            case 403:
              error = "Permisos insuficientes.";
              break;
            case 406:
              error = "Error de conexión.";
              break;
            default:
              error = "Error de servidor.";
              break;
          }

          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.setState({
            loading: false,
          });
        }
      }
    }
  }

  // Por ahora no hace nada
  updatePromocion(newPromocion) {
    let index = this.state.promociones.findIndex(
      (p) => p.idpromocion == newPM.idpromocion
    );
    let promociones = [...this.state.promociones];
    // Editar de ser necesario
    this.setState({ promociones: promociones });
  }

  render() {
    const { promociones, loading } = this.state;
    const colors = this.props.value.colors;
    return (
      <>
        <Menu
          title={"Promociones"}
          navigation={this.props.navigation}
          refreshing={false}
          onRefresh={() => {
            this.refresh();
          }}
        >
          {loading ? (
            <ActivityIndicator
              size="large"
              color={colors.accent2}
              style={{ width: "100%", marginTop: 30 }}
            />
          ) : (
            <>
              {promociones.length > 0 ? (
                promociones.map((p) => (
                  <CardPromocion
                    key={p.idpromocion}
                    promocion={p}
                    openCard={(value) => {
                      let card = null;
                      if (value) {
                        card = p;
                      }
                      this.setState({ openedCard: card });
                    }}
                    open={this.state.openedCard == p}
                    updatePromocion={(newPromocion) => {
                      this.updatePromocion(newPromocion);
                    }}
                  />
                ))
              ) : (
                <BasicCard title="No hay promociones activas." />
              )}
              <View
                style={{
                  width: "100%",
                  height: 100,
                }}
              />
            </>
          )}
        </Menu>
      </>
    );
  }
}

export default withGeneralState(withTheme(Promociones));
