import React, { Component } from "react";

import withTheme from "../../general/withTheme";
import withGeneralState from "../../general/withGeneralState";
import withNavigation from "../../general/withNavigation";

import { Text, View, StyleSheet } from "react-native";

import StringMask from "string-mask";
import BasicCard from "../../general/menuGenerico/basicCard";

class CardCanje extends Component {
  state = {
    canje: this.props.canje,
  };

  constructor(props) {
    super(props);
    this.maskImporte = new StringMask("#.##0,00", { reverse: true });
    this.maskDNI = new StringMask("#.##0", { reverse: true });
    this.styles = {
      textTopLeft: {
        width: "45%",
        paddingTop: 5,
        fontSize: 18,
        fontWeight: "800",
        textAlign: "left",
      },
      textTopRight: {
        width: "55%",
        paddingTop: 5,
        fontSize: 18,
        fontWeight: "800",
        textAlign: "right",
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.canje != this.props.canje) {
      this.setState({ canje: this.props.canje });
    }
  }

  showDetail() {
    this.props.navigation.navigate("ConsultarCanje", {
      canje: this.state.canje,
      update: (newCanje) => {
        this.setState({ canje: newCanje });
        this.props.updateCanje(newCanje);
      },
    });
  }

  render() {
    const colors = this.props.value.colors;
    const canje = this.state.canje;

    const fechaCanje = new Date(canje.fecha);
    //HUSO HORARIO
    fechaCanje.setHours(fechaCanje.getHours() + 3);
    const fechaActual = new Date();
    const dias = Math.ceil(
      (fechaActual.getTime() - fechaCanje.getTime()) / (1000 * 60 * 60 * 24)
    );

    let accent;
    if (dias <= 1) {
      accent = colors.success;
    } else if (dias <= 10) {
      accent = colors.info;
    } else {
      accent = colors.warning;
    }

    const stylesColor = StyleSheet.create({
      text: {
        color: colors.text2,
      },
    });

    let nombre = "";
    if (canje.apellido_cli) {
      nombre += canje.apellido_cli.toUpperCase();
    }
    if (canje.nombre_cli) {
      if (nombre.length != 0) {
        nombre += ", ";
      }
      nombre += canje.nombre_cli;
    }
    if (nombre.length == 0) {
      nombre = null;
    }

    return (
      <BasicCard
        backgroundColor={!canje.anulador ? accent : colors.danger}
        onPress={() => this.showDetail()}
      >
        <Text
          style={[this.styles.textTopLeft, { width: "65%" }, stylesColor.text]}
        >
          {canje.nombre}
        </Text>
        <Text
          style={[this.styles.textTopRight, { width: "35%" }, stylesColor.text]}
        >
          {("00" + fechaCanje.getDate()).slice(-2) +
            "/" +
            ("00" + (fechaCanje.getMonth() + 1)).slice(-2)}
        </Text>

        <Text style={[this.styles.textTopLeft, stylesColor.text]}>
          {"Importe total:"}
        </Text>
        <Text style={[this.styles.textTopRight, stylesColor.text]}>
          {"$" +
            this.maskImporte.apply(
              canje.valor_total.toFixed(2).replace(".", "")
            )}
        </Text>

        <Text style={[this.styles.textTopLeft, stylesColor.text]}>
          {"DNI " + this.maskDNI.apply(canje.dni_cli)}
        </Text>
        {nombre ? (
          <Text style={[this.styles.textTopRight, stylesColor.text]}>
            {nombre}
          </Text>
        ) : (
          <></>
        )}

        <Text style={[this.styles.textTopLeft, stylesColor.text]}>
          {canje.anulador ? "Anulado por:" : "Canjeado por:"}
        </Text>
        <Text style={[this.styles.textTopRight, stylesColor.text]}>
          {canje.anulador ? canje.anulador : canje.usuario}
        </Text>

        <View style={{ width: "100%", height: 10 }} />
      </BasicCard>
    );
  }
}

export default withNavigation(withGeneralState(withTheme(CardCanje)));
