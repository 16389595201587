import React, { Component } from "react";
import {
  StyleSheet,
  View,
  TextInput,
  TouchableHighlight,
  Text,
  Dimensions,
  Keyboard,
  ActivityIndicator,
} from "react-native";
import NavBar from "../general/navbar";
import { SafeAreaView } from "react-native-safe-area-context";
import withTheme from "../general/withTheme";
import withGeneralState from "../general/withGeneralState";
import Toast from "react-native-root-toast";

class OlvidoPass extends Component {
  state = {
    otp: "",
    validOtp: false,
    newPass1: "",
    newPass2: "",
    validNewPass: false,
    disableButton: true,
    loading: false,
  };

  change() {
    Keyboard.dismiss();
    const colors = this.props.value.colors;

    if (this.state.validOtp && this.state.validNewPass) {
      this.setState({ disableButton: true, loading: true }, async () => {
        let res = await fetch(
          this.props.value.proxy + "/accounts/forgotChange",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user: this.props.route.params.user,
              otp: this.state.otp,
              newPass: this.state.newPass1,
            }),
          }
        );

        if (res.status == 200) {
          const mensaje = "Contraseña cambiada. Por favor ingrese nuevamente.";
          const toast = Toast.show(mensaje, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.success,
            textColor: colors.text2,
          });
          await fetch(this.props.value.proxy + "/accounts/logout", {
            method: "POST",
          });
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Login" }],
          });
        } else {
          let error;
          if (res.status == 401) {
            error = "Sesión expirada. Por favor ingrese nuevamente.";
            const toast = Toast.show(error, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.danger,
              textColor: colors.text2,
            });
            await fetch(this.props.value.proxy + "/accounts/logout", {
              method: "POST",
            });
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: "Login" }],
            });
          } else {
            switch (res.status) {
              case 400:
                error = "Solicitud incorrecta.";
                break;
              case 403:
                error = "Permisos insuficientes.";
                break;
              case 406:
                error = "Error de conexión.";
                break;
              case 409:
                error = "El código ingresado es incorrecto.";
                break;
              default:
                error = "Error de servidor.";
                break;
            }

            const toast = Toast.show(error, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.danger,
              textColor: colors.text2,
            });
            this.setState({
              disableButton: !this.state.validOtp || !this.state.validNewPass,
              loading: false,
            });
          }
        }
      });
    }
  }

  render() {
    const colors = this.props.value.colors;
    const styles = StyleSheet.create({
      background: {
        width: "100%",
        height: Dimensions.get("window").height,
        backgroundColor: colors.background,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 0,
      },
      msg: {
        fontSize: 17,
        textAlign: "justify",
        paddingTop: 10,
        color: colors.placeholder,
      },
      card: {
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        width: "90%",
        maxWidth: 320,
        paddingHorizontal: 20,
        height: 450,
        marginTop: -250,
      },
      field: {
        fontSize: 20,
        backgroundColor: colors.field,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        flex: 1,
        width: "100%",
        maxHeight: 50,
        marginTop: 35,
        paddingLeft: "5%",
        alignItems: "center",
        justifyItems: "center",
        color: colors.text3,
      },
      touch: {
        marginTop: 35,
        width: "70%",
        alignSelf: "center",
        borderRadius: 5,
      },
      button: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.disableButton
          ? colors.field
          : colors.accent1,
        padding: 7,
      },

      buttonText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.disableButton ? colors.placeholder : colors.text1,
      },
    });
    return (
      <SafeAreaView>
        <NavBar
          title="Olvidé mi contraseña"
          navigation={this.props.navigation}
        />
        <View style={styles.background}>
          <View style={styles.card}>
            <Text style={styles.msg}>
              Se envió un código de 6 dígitos a su email. Ingreselo para generar
              una nueva contraseña.
            </Text>
            <TextInput
              ref={(e) => (this.otpRef = e)}
              style={styles.field}
              placeholder="Código"
              keyboardType="numeric"
              placeholderTextColor={colors.placeholder}
              selectionColor={colors.text}
              maxLength={6}
              value={this.state.otp}
              onChangeText={(text) => {
                text = text.replace(/[^0-9]/g, "");
                let valid = text.length == 6 && !isNaN(Number(text));
                if (valid) {
                  this.otpRef.setNativeProps({
                    style: { borderColor: colors.border },
                  });
                } else {
                  this.otpRef.setNativeProps({
                    style: { borderColor: "red" },
                  });
                }
                this.setState({
                  otp: text,
                  validOtp: valid,
                  disableButton: !(valid && this.state.validNewPass),
                });
              }}
              onFocus={() => {
                this.otpRef.setNativeProps({
                  style: {
                    backgroundColor: colors.focus,
                    color: colors.text4,
                  },
                });
              }}
              onBlur={() => {
                this.otpRef.setNativeProps({
                  style: styles.field,
                });
              }}
              onSubmitEditing={() => this.newPass1Ref.focus()}
            />

            <TextInput
              ref={(e) => (this.newPass1Ref = e)}
              value={this.state.newPass1}
              style={styles.field}
              placeholder="Nueva contraseña"
              placeholderTextColor={colors.placeholder}
              selectionColor={colors.text}
              secureTextEntry={true}
              autoCapitalize="none"
              onChangeText={(text) => {
                let valid = text.length > 0;
                if (valid) {
                  this.newPass1Ref.setNativeProps({
                    style: { borderColor: colors.border },
                  });
                } else {
                  this.newPass1Ref.setNativeProps({
                    style: { borderColor: "red" },
                  });
                }
                this.setState({
                  newPass1: text,
                  newPass2: "",
                  validNewPass: false,
                  disableButton: true,
                });
              }}
              onFocus={() => {
                this.newPass1Ref.setNativeProps({
                  style: {
                    backgroundColor: colors.focus,
                    color: colors.text4,
                  },
                });
              }}
              onBlur={() => {
                this.newPass1Ref.setNativeProps({
                  style: styles.field,
                });
              }}
              onSubmitEditing={() => this.newPass2Ref.focus()}
            />
            <TextInput
              ref={(e) => (this.newPass2Ref = e)}
              value={this.state.newPass2}
              style={styles.field}
              placeholder="Confirmar nueva contraseña"
              placeholderTextColor={colors.placeholder}
              selectionColor={colors.text}
              secureTextEntry={true}
              autoCapitalize="none"
              onChangeText={(text) => {
                let valid = text.length > 0 && text === this.state.newPass1;
                if (valid) {
                  this.newPass2Ref.setNativeProps({
                    style: { borderColor: colors.border },
                  });
                } else {
                  this.newPass2Ref.setNativeProps({
                    style: { borderColor: "red" },
                  });
                }
                this.setState({
                  newPass2: text,
                  validNewPass: valid,
                  disableButton: !(valid && this.state.validOtp),
                });
              }}
              onFocus={() => {
                this.newPass2Ref.setNativeProps({
                  style: {
                    backgroundColor: colors.focus,
                    color: colors.text4,
                  },
                });
              }}
              onBlur={() => {
                this.newPass2Ref.setNativeProps({
                  style: styles.field,
                });
              }}
              onSubmitEditing={() => this.change()}
            />
            <TouchableHighlight
              ref={(e) => (this.buttonRef = e)}
              disabled={this.state.disableButton}
              style={styles.touch}
              underlayColor={colors.field}
              onPress={() => this.change()}
            >
              <View style={styles.button}>
                {this.state.loading ? (
                  <ActivityIndicator size="large" color={colors.accent2} />
                ) : (
                  <Text style={styles.buttonText}>Cambiar</Text>
                )}
              </View>
            </TouchableHighlight>
          </View>
        </View>
      </SafeAreaView>
    );
  }
}

export default withGeneralState(withTheme(OlvidoPass));
