import React, { Component } from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Dimensions,
  Text,
  TouchableHighlight,
  Modal,
} from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";
import Menu from "../general/menuGenerico/menu";
import CardPM from "./cardPM";
import DropDownPicker from "react-native-dropdown-picker";
import withTheme from "../general/withTheme";
import withGeneralState from "../general/withGeneralState";
import RenderBadgeItem from "../general/RenderBadgeItem";
import IcoBack from "../../assets/icons/back.svg";
import Toast from "react-native-root-toast";

class VistaPM extends Component {
  state = {
    endpoint: this.props.endpoint,
    pms: [],
    openedCard: null,
    dropDownOpen: false,
    items: [],
    value:
      this.props.value.userData.rol.toUpperCase() === "SUPERVISOR"
        ? ["pendiente", "autorizado", "pendiente de pago"]
        : ["pendiente", "autorizado"],
    loading: false,
    styles: {},
    colors: {},
    search: "",
    searching: false,
    currentPage: 1,
    maxPage: 1,
    changed: false,
    foto: null,
  };

  constructor(props) {
    super(props);
    this.setOpen = this.setOpen.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setItems = this.setItems.bind(this);
  }

  componentDidMount() {
    this.props.navigation.addListener("beforeRemove", (e) => {
      if (this.state.foto != null) {
        e.preventDefault();
        this.setState({ foto: null });
      }
    });
    this.updateTheme();
    this.refresh(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.endpoint != prevProps.endpoint) {
      this.setState({ endpoint: this.props.endpoint }, () => {
        this.refresh();
      });
    }
    if (this.props.value.theme != prevProps.value.theme) {
      this.updateTheme();
    }
  }

  updateTheme() {
    const { colors } = this.props.value;

    const badgeColors = {
      pendiente: colors.pendiente,
      autorizado: colors.autorizado,
      "pendiente de pago": colors.pendienteDePago,
      anulado: colors.anulado,
      pagado: colors.pagado,
      rechazado: colors.rechazado,
      default: colors.placeholder,
    };

    const styles = StyleSheet.create({
      selectContainer: {
        width: "100%",
        marginTop: 7,
        marginBottom: 7,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 51,
      },
      select: {
        alignSelf: "center",
        backgroundColor: colors.card,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        zIndex: 50,
      },
      badgeText: {
        fontSize: 18,
        fontWeight: "500",
        color: colors.text2,
        textAlign: "center",
      },
      label: {
        fontSize: 17,
        fontWeight: "450",
        color: colors.text3,
        textAlign: "left",
      },
      drop: {
        alignSelf: "center",
        backgroundColor: colors.field,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        maxHeight: 500,
        zIndex: 50,
      },
      pageSection: {
        flexDirection: "row",
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        marginTop: 50,
        marginBottom: 50,
        borderRadius: 5,
        height: 45,
      },
      pageButton: {
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: colors.field,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderWidth: 0,
        paddingVertical: 5,
        width: 35,
        height: 45,
      },
      pageText: {
        alignSelf: "center",
        backgroundColor: colors.card,
        color: colors.text3,
        fontSize: 25,
        borderRadius: 0,
        borderWidth: 0,
        paddingHorizontal: 10,
        paddingVertical: 5,
        height: 45,
      },
    });
    const items = [
      {
        label: "Pendientes",
        value: "pendiente",
        labelStyle: styles.label,
      },
      {
        label: "Autorizados",
        value: "autorizado",
        labelStyle: styles.label,
      },
      {
        label: "Pendientes de Pago",
        value: "pendiente de pago",
        labelStyle: styles.label,
        disabled: this.props.value.userData.rol.toUpperCase() !== "SUPERVISOR",
      },
      {
        label: "Anulados",
        value: "anulado",
        labelStyle: styles.label,
      },
      {
        label: "Pagados",
        value: "pagado",
        labelStyle: styles.label,
      },
      {
        label: "Rechazados",
        value: "rechazado",
        labelStyle: styles.label,
      },
      {
        label: "Último día",
        value: "1",
        labelStyle: styles.label,
      },
      {
        label: "Últimos 10 días",
        value: "10",
        labelStyle: styles.label,
      },
      {
        label: "Últimos 30 días",
        value: "30",
        labelStyle: styles.label,
      },
      {
        label: "Todos",
        value: "todos",
        labelStyle: styles.label,
        disabled: true,
      },
    ];
    this.setState({
      colors: colors,
      badgeColors: badgeColors,
      styles: styles,
      items: items,
    });
  }

  setOpen(open) {
    this.setState({
      dropDownOpen: open,
    });
  }

  setValue(callback) {
    this.setState((state) => ({
      oldValue: this.state.value,
      value: callback(state.value),
      changed: true,
    }));
  }

  setItems(callback) {
    this.setState((state) => ({
      items: callback(state.items),
      changed: true,
    }));
  }

  onClose() {
    let { value, search, searching, items } = this.state;
    if (searching && search.trim().length > 0) {
      let newItems = [...items];
      let newValue = [...value];
      let indexBusqueda = newItems.findIndex((i) => i.busqueda == true);
      if (indexBusqueda != -1) {
        newValue = newValue.filter((v) => v != newItems[indexBusqueda].value);
        newItems.splice(indexBusqueda, 1);
      }
      newItems.push({
        label: search,
        value: '"' + search + '"',
        busqueda: true,
      });
      newValue = newValue.filter((v) => v !== "todos");
      newValue.push('"' + search + '"');
      this.setState(
        { searching: false, items: newItems, value: newValue },
        () => {
          this.refresh();
        }
      );
    } else {
      if (value.length == 0) {
        this.setState({ searching: false, value: ["todos"] }, () => {
          this.refresh();
        });
      } else {
        if (this.state.changed) {
          this.refresh();
        }
      }
    }
  }

  // Cantidad de PMs por página
  limit = 6;

  refresh(fallback) {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          pms: [],
          openedCard: null,
          dropDownOpen: false,
          changed: false,
        },
        async () => {
          let res = await fetch(
            this.props.value.proxy +
              this.state.endpoint +
              `?filtros=${JSON.stringify(this.state.value)}&limit=${
                this.limit
              }&page=${this.state.currentPage}`
          );

          if (res.status == 200) {
            let result = await res.json();
            if (!fallback || result.pms.length > 0) {
              this.setState({
                pms: result.pms,
                maxPage: result.pages,
                loading: false,
              });
            } else {
              res = await fetch(
                this.props.value.proxy +
                  this.state.endpoint +
                  `?filtros=${JSON.stringify(["todos"])}&limit=${
                    this.limit
                  }&page=${this.state.currentPage}`
              );
              if (res.status == 200) {
                result = await res.json();
                this.setState({
                  pms: result.pms,
                  maxPage: result.pages,
                  loading: false,
                  value: ["todos"],
                });
              } else {
                this.error(res.status);
              }
            }
          } else {
            this.error(res.status);
          }
        }
      );
    }
  }

  async error(status) {
    {
      const colors = this.props.value.colors;
      let error;
      if (status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        if (status == 400) {
          error = "Sala no seleccionada.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Salas" }],
          });
        } else {
          switch (status) {
            case 403:
              error = "Permisos insuficientes.";
              break;
            case 406:
              error = "Error de conexión.";
              break;
            default:
              error = "Error de servidor.";
              break;
          }

          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.setState({
            loading: false,
          });
        }
      }
    }
  }

  prevPage() {
    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.refresh();
    });
  }

  nextPage() {
    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.refresh();
    });
  }

  updatePM(newPM) {
    let index = this.state.pms.findIndex((p) => p.idpm == newPM.idpm);
    let pms = [...this.state.pms];
    if (this.state.value.findIndex((v) => v === newPM.estado_actual) === -1) {
      pms.splice(index, 1);
    } else {
      pms[index] = newPM;
    }
    this.setState({ pms: pms });
  }

  setFoto(foto) {
    this.setState({ foto: foto });
  }

  render() {
    const {
      colors,
      badgeColors,
      styles,
      items,
      value,
      dropDownOpen,
      pms,
      loading,
      currentPage,
      maxPage,
    } = this.state;
    return (
      <>
        <Modal
          visible={this.state.foto != null}
          transparent={true}
          onRequestClose={() => {
            this.setState({ foto: null });
          }}
        >
          <ImageViewer
            imageUrls={[{ url: "data:image/jpeg;base64," + this.state.foto }]}
            saveToLocalByLongPress={false}
            menuContext={null}
            renderIndicator={() => null}
          />
        </Modal>
        <Menu
          title={this.props.title}
          navigation={this.props.navigation}
          refreshing={this.state.loading}
          onRefresh={() => {
            this.refresh();
          }}
        >
          {this.state.dropDownOpen && (
            <TouchableWithoutFeedback
              style={{
                width: "100%",
                height: Dimensions.get("window").height,
                position: "absolute",
                zIndex: 50,
              }}
              onPress={() => {
                this.setOpen(false);
                this.onClose();
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: Dimensions.get("window").height,
                  position: "absolute",
                  zIndex: 50,
                }}
              />
            </TouchableWithoutFeedback>
          )}

          <View style={[styles.selectContainer]}>
            <DropDownPicker
              style={[
                styles.select,
                {
                  //width: value.length == 0 ? 230 : 230 + (value.length - 1) * 120,
                  //maxWidth: Dimensions.get("window").width * 0.9,
                  width: "90%",
                },
              ]}
              renderBadgeItem={RenderBadgeItem}
              dropDownContainerStyle={[
                styles.drop,
                {
                  //width: value.length == 0 ? 230 : 230 + (value.length - 1) * 120,
                  //maxWidth: Dimensions.get("window").width * 0.9,
                  width: "90%",
                },
              ]}
              disabledItemContainerStyle={{ display: "none" }}
              open={dropDownOpen}
              setOpen={this.setOpen}
              items={items}
              setItems={this.setItems}
              value={value}
              setValue={this.setValue}
              theme={colors.theme}
              multiple={true}
              onChangeValue={(value) => {
                if (value.length == 0) {
                  this.setState({ value: ["todos"] });
                } else {
                  if (value.length > 1) {
                    let newValue = [...value];
                    let change = false;
                    let indexTodos = value.findIndex((v) => v === "todos");
                    if (indexTodos != -1) {
                      newValue.splice(indexTodos, 1);
                      change = true;
                    }
                    let newDias = value.filter(
                      (v) =>
                        !isNaN(Number(v)) &&
                        this.state.oldValue.findIndex((v2) => v2 === v) === -1
                    );
                    if (newDias.length > 0) {
                      //Se sumó un filtro de días
                      newValue = newValue.filter(
                        (v) => isNaN(Number(v)) || v === newDias[0]
                      );
                      change = true;
                    }
                    if (change) {
                      this.setState({ oldValue: value, value: newValue });
                    }
                  }
                }
              }}
              onClose={() => this.onClose()}
              placeholder=""
              mode="BADGE"
              extendableBadgeContainer={true}
              showBadgeDot={false}
              showTickIcon={true}
              badgeProps={{ badgeColors: badgeColors }}
              badgeTextStyle={styles.badgeText}
              listMode="SCROLLVIEW"
              searchable={false}
              disableLocalSearch={true}
              addCustomItem={true}
              onChangeSearchText={(text) => {
                this.setState({
                  changed: true,
                  searching: true,
                  search: text,
                });
              }}
              searchTextInputProps={{
                onSubmitEditing: () => {
                  this.setOpen(false);
                  this.onClose();
                },
              }}
              searchPlaceholder="Búsqueda"
            />
          </View>

          {loading ? (
            <ActivityIndicator
              size="large"
              color={colors.accent2}
              style={{ width: "100%", marginTop: 30 }}
            />
          ) : (
            <>
              {pms.map((p) => (
                <CardPM
                  key={p.idpm}
                  pm={p}
                  setFoto={(foto) => this.setFoto(foto)}
                  openCard={(value) => {
                    let card = null;
                    if (value) {
                      card = p;
                    }
                    this.setState({ openedCard: card });
                  }}
                  open={this.state.openedCard == p}
                  updatePM={(newPM) => {
                    this.updatePM(newPM);
                  }}
                />
              ))}
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View style={styles.pageSection}>
                  <TouchableHighlight
                    disabled={!(1 < currentPage)}
                    underlayColor={colors.card}
                    onPress={() => {
                      this.prevPage();
                    }}
                  >
                    <View style={styles.pageButton}>
                      {1 < currentPage && (
                        <IcoBack stroke={colors.text3} width={20} height={20} />
                      )}
                    </View>
                  </TouchableHighlight>
                  <Text style={styles.pageText}>
                    {currentPage} / {maxPage}
                  </Text>
                  <TouchableHighlight
                    disabled={!(currentPage < maxPage)}
                    underlayColor={colors.card}
                    onPress={() => {
                      this.nextPage();
                    }}
                  >
                    <View
                      style={[
                        styles.pageButton,
                        {
                          transform: [
                            {
                              rotate: "180deg",
                            },
                          ],
                        },
                      ]}
                    >
                      {currentPage < maxPage && (
                        <IcoBack stroke={colors.text3} width={20} height={20} />
                      )}
                    </View>
                  </TouchableHighlight>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 100,
                }}
              />
            </>
          )}
        </Menu>
      </>
    );
  }
}

export default withGeneralState(withTheme(VistaPM));
