import React, { Component } from "react";
import VistaPM from "./vistaPM";

const MisPM = ({ navigation }) => {
  return (
    <VistaPM title="Mis PMs" navigation={navigation} endpoint="/pm/misPM" />
  );
};

export default MisPM;
