import React, { Component } from "react";
import withTheme from "./withTheme.jsx";
import IcoMenu from "../../assets/icons/kebab.svg";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from "react-native-popup-menu";
import { TouchableOpacity } from "react-native";
import withGeneralState from "./withGeneralState.jsx";

const Ajustes = ({ navigation, enableBack, value }) => {
  const colors = value.colors;
  const styles = {
    option: {
      optionsContainer: {
        backgroundColor: colors.field,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        padding: 12,
        marginTop: 47.5,
        width: 250,
      },
      optionWrapper: {
        paddingVertical: 15,
      },
      optionText: {
        fontSize: 22,
        backgroundColor: colors.field,
        color: colors.text3,
      },
    },
    trigger: {
      TriggerTouchableComponent: TouchableOpacity,
      triggerTouchable: {
        activeOpacity: 0.7,
      },
    },
  };
  return (
    <Menu style={{ marginRight: 30 }}>
      <MenuTrigger customStyles={styles.trigger}>
        <IcoMenu width={25} height={25} stroke={colors.text2} />
      </MenuTrigger>
      <MenuOptions customStyles={styles.option}>
        <MenuOption
          onSelect={() => {
            const nav = () => {
              navigation.navigate("CambiarPass");
            };
            if (enableBack) {
              enableBack(nav);
            } else {
              nav();
            }
          }}
          text="Cambiar contraseña"
        />
        <MenuOption
          onSelect={async () => {
            await fetch(value.proxy + "/accounts/logout", {
              method: "POST",
            });
            const nav = () => {
              navigation.reset({
                index: 0,
                routes: [{ name: "Login" }],
              });
            };
            if (enableBack) {
              enableBack(nav);
            } else {
              nav();
            }
          }}
          text="Cerrar sesión"
        />
      </MenuOptions>
    </Menu>
  );
};

export default withGeneralState(withTheme(Ajustes));
