import React, { Component } from "react";
import withTheme from "../withTheme";
import withGeneralState from "../withGeneralState";
import { Animated, TouchableHighlight, View, StyleSheet } from "react-native";

//import IcoBack from "../../assets/icons/back.svg";

class Card extends Component {
  state = {
    accent: this.props.accent,
    top: this.props.children[0],
    middle: this.props.children[1],
    open: false,
    updateCounter: this.props.updateCounter,
    animation: new Animated.Value(0),
    maxMiddleHeight: -1,
  };

  constructor(props) {
    super(props);
    this.styles = {
      card: {
        display: "flex",
        width: "93%",
        maxWidth: 370,
        height: "auto",
        borderRadius: 5,
        justifyContent: "center",
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        marginRight: 15,
        marginLeft: 15,
        marginTop: 15,
        marginBottom: 15,
      },
      cardTop: {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        zIndex: 2,
      },
      cardTopContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        display: "flex",
        width: "100%",
        paddingHorizontal: 15,
        height: "auto",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        justifyContent: "space-between",
      },
      cardMiddle: {
        flexDirection: "row",
        flexWrap: "wrap",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        display: "flex",
        overflow: "hidden",
        justifyContent: "space-between",
        paddingHorizontal: 15,
        width: "100%",
        height: "auto",
        position: "absolute",
        opacity: 0,
        zIndex: 0,
      },
      cardBottom: {
        flexDirection: "row",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.open && prevProps.open && this.state.open) {
      this.toggle();
    }
    if (this.props.accent != this.state.accent) {
      this.setState({ accent: this.props.accent });
    }
    if (this.props.children[0] != this.state.top) {
      this.setState({ top: this.props.children[0] });
    }
    if (this.props.children[1] != this.state.middle) {
      this.setState({
        middle: this.props.children[1],
      });
    }
    if (this.props.updateCounter > this.state.updateCounter) {
      this.styles.cardMiddle.maxHeight = undefined;
      this.setState({
        updateCounter: this.props.updateCounter,
        maxMiddleHeight: -1,
      });
    }
  }

  toggle() {
    const duration = 250;
    if (!this.state.open) {
      Animated.timing(this.state.animation, {
        duration: duration,
        toValue: 1,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(this.state.animation, {
        duration: duration,
        toValue: 0,
        useNativeDriver: false,
      }).start();
    }
    this.setState({ open: !this.state.open });
  }

  open() {
    this.props.openCard(!this.state.open);
    this.toggle();
  }

  onLayout(event) {
    if (this.state.maxMiddleHeight == -1) {
      this.setState(
        {
          maxMiddleHeight: event.nativeEvent.layout.height,
        },
        () => {
          this.styles.cardMiddle.position = "relative";
          this.styles.cardMiddle.opacity = 1;
          this.styles.cardMiddle.maxHeight = this.state.animation.interpolate({
            inputRange: [0, 1],
            outputRange: [0, this.state.maxMiddleHeight],
          });
          this.setState({});
        }
      );
    }
  }

  render() {
    const colors = this.props.value.colors;

    const accent = this.state.accent;

    const stylesColor = StyleSheet.create({
      card: {
        backgroundColor: colors.card,
        shadowColor: colors.shadow,
      },
      cardTopContainer: {
        backgroundColor: accent,
      },
      cardMiddle: {
        backgroundColor: colors.field,
      },
      /*
      cardBottom: {
        backgroundColor: colors.card,
      },
      */
    });
    return (
      <>
        <View style={[this.styles.card, stylesColor.card]}>
          <TouchableHighlight
            style={this.styles.cardTop}
            underlayColor={colors.field}
            onPress={() => {
              this.open();
            }}
          >
            <View
              style={[
                this.styles.cardTopContainer,
                stylesColor.cardTopContainer,
              ]}
            >
              {this.state.top}
            </View>
          </TouchableHighlight>
          <Animated.View
            style={[this.styles.cardMiddle, stylesColor.cardMiddle]}
            onLayout={(event) => {
              this.onLayout(event);
            }}
          >
            {this.state.middle}
          </Animated.View>
          {/*
        <TouchableHighlight
          style={[this.styles.cardBottom, stylesColor.cardBottom]}
          underlayColor={colors.field}
          onPress={() => {
            this.open();
          }}
        >
          <Animated.View
            color={colors.text3}
            style={{
              zIndex: 2,
              transform: [
                {
                  rotate: this.state.animation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ["-90deg", "-270deg"],
                  }),
                },
              ],
            }}
          >
            <IcoBack width={25} height={25} stroke={colors.text3} />
          </Animated.View>
        </TouchableHighlight>
        */}
        </View>
      </>
    );
  }
}

export default withGeneralState(withTheme(Card));
