import React, { Component } from "react";
import { Text, TextInput } from "react-native";
import withTheme from "../../general/withTheme";

class InputOrText extends Component {
  state = { valueInput: this.props.valueInput };
  componentDidUpdate(prevProps) {
    if (prevProps.valueInput != this.props.valueInput) {
      this.setState({ valueInput: this.props.valueInput });
    }
  }

  render() {
    const colors = this.props.value.colors;

    const props = {
      ...this.props,
      value: this.state.valueInput,
      editable: this.props.block ? false : true,
    };
    const textStyle = {
      paddingTop: "0%",
      paddingBottom: "0%",
      paddingLeft: "0%",
      paddingRight: "0%",
      borderWidth: 0,
      backgroundColor: colors.card,
      color: colors.text3,
      fontSize: 25,
      fontWeight: "500",
      zIndex: 49,
      fontSize: 20,
    };

    return this.props.editable ? (
      <TextInput {...props} ref={props.reference}>
        {props.children}
      </TextInput>
    ) : (
      <Text style={[...props.style, textStyle]}>{props.valueInput || "-"}</Text>
    );
  }
}

export default withTheme(InputOrText);
