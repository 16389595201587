import React, { Component } from "react";
import withTheme from "../general/withTheme";
import withGeneralState from "../general/withGeneralState";
import {
  Text,
  TouchableHighlight,
  ActivityIndicator,
  View,
  StyleSheet,
} from "react-native";

import StringMask from "string-mask";
//import IcoBack from "../../assets/icons/back.svg";
import { DataTable } from "react-native-paper";
import Buttons from "./buttons";
import Toast from "react-native-root-toast";
import Card from "../general/menuGenerico/card";

class CardPM extends Component {
  state = {
    pm: this.props.pm,
    fmLoading: false,
    flLoading: false,
    updateCounter: 0,
    open: this.props.open,
  };

  constructor(props) {
    super(props);
    this.maskImporte = new StringMask("#.##0,00", { reverse: true });
    this.maskDNI = new StringMask("#.##0", { reverse: true });
    this.styles = {
      textTopLeft100: {
        width: "100%",
        paddingTop: 5,
        fontSize: 16,
        fontWeight: "700",
        textAlign: "left",
      },
      textTopLeft: {
        width: "35%",
        paddingTop: 5,
        fontSize: 16,
        fontWeight: "700",
        textAlign: "left",
      },
      textTopRight: {
        width: "65%",
        paddingTop: 5,
        fontSize: 16,
        fontWeight: "700",
        textAlign: "right",
      },
      textMiddle: {
        width: "100%",
        fontSize: 17,
        fontWeight: "500",
        zIndex: 0,
      },
      textMiddleLeft: {
        width: "40%",
        fontSize: 17,
        fontWeight: "500",
        textAlign: "left",
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 0,
      },
      textMiddleRight: {
        width: "60%",
        fontSize: 17,
        fontWeight: "450",
        textAlign: "right",
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 0,
      },
      fotoTouch: {
        marginTop: 10,
        marginBottom: 10,
        width: "45%",
        alignSelf: "center",
        borderRadius: 5,
        zIndex: 49,
      },
      fotoButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 3,
        paddingRight: 3,
        zIndex: 49,
      },
      fotoText: {
        fontSize: 25,
        textAlign: "center",
        zIndex: 49,
      },
    };
    const pm = this.props.pm;
    this.apellidoNombre = "";
    if (pm.apellido) {
      this.apellidoNombre += pm.apellido.toUpperCase();
    }
    if (pm.nombre) {
      if (this.apellidoNombre.length > 0) {
        this.apellidoNombre += ", ";
      }
      this.apellidoNombre += pm.nombre;
    }
    if (this.apellidoNombre.length === 0) {
      this.apellidoNombre = "AL PORTADOR";
    }
    const fecha = new Date(pm.created_at);
    this.fechaCreacion =
      ("00" + fecha.getDate()).slice(-2) +
      "/" +
      ("00" + (fecha.getMonth() + 1)).slice(-2) +
      " " +
      ("00" + fecha.getHours()).slice(-2) +
      ":" +
      ("00" + fecha.getMinutes()).slice(-2);
  }

  updatePM(newPM) {
    this.setState(
      { pm: newPM, updateCounter: this.state.updateCounter + 1 },
      () => {
        this.props.updatePM(newPM);
      }
    );
  }

  async getFoto(campo) {
    const colors = this.props.value.colors;
    let res = await fetch(
      this.props.value.proxy +
        `/pm/foto?idPM=${this.state.pm.idpm}&campo=${campo}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status == 200) {
      return await res.json();
    } else {
      let error;
      if (res.status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        switch (res.status) {
          case 403:
            error = "Permisos insuficientes.";
            break;
          case 404:
            error = "Foto no encontrada.";
            break;
          case 406:
            error = "Error de conexión.";
            break;
          default:
            error = "Error de servidor.";
            break;
        }
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        throw new Error("");
      }
    }
  }

  async showFotoMaquina() {
    this.setState({ fmLoading: true }, async () => {
      try {
        const foto = await this.getFoto("maquina");
        this.props.setFoto(foto);
        this.setState({ fmLoading: false });
      } catch (e) {
        this.props.setFoto(null);
        this.setState({
          fmLoading: false,
        });
      }
    });
  }

  async showFotoLog() {
    this.setState({ flLoading: true }, async () => {
      try {
        const foto = await this.getFoto("log");
        this.props.setFoto(foto);
        this.setState({ flLoading: false });
      } catch {
        this.props.setFoto(null);
        this.setState({
          flLoading: false,
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.state.open != this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  render() {
    const pm = this.state.pm;
    const colors = this.props.value.colors;
    let accent = "";
    switch (pm.estado_actual) {
      case "pendiente":
        accent = colors.pendiente;
        break;
      case "autorizado":
        accent = colors.autorizado;
        break;
      case "pendiente de pago":
        accent = colors.pendienteDePago;
        break;
      case "anulado":
        accent = colors.anulado;
        break;
      case "pagado":
        accent = colors.pagado;
        break;
      case "rechazado":
        accent = colors.rechazado;
        break;
      default:
        accent = colors.placeholder;
        break;
    }

    const stylesColor = StyleSheet.create({
      textTop: {
        color: colors.text2,
      },
      textMiddle: {
        color: colors.text3,
      },
      fmButton: {
        borderColor: colors.border,
        backgroundColor: colors.info,
      },
      fmText: {
        color: colors.text2,
      },
      flButton: {
        borderColor: colors.border,
        backgroundColor: colors.info,
      },
      flText: {
        color: colors.text2,
      },
    });

    return (
      <Card
        open={this.state.open}
        accent={accent}
        openCard={this.props.openCard}
        updateCounter={this.state.updateCounter}
      >
        {/*Top*/}
        <>
          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { width: "70%" },
            ]}
          >
            {pm.tipo_pm}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { width: "30%" },
            ]}
          >
            {this.fechaCreacion}
          </Text>
          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { fontSize: 19, fontWeight: "900" },
            ]}
          >
            {"M#" + pm.uid}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { fontSize: 19, fontWeight: "900" },
            ]}
          >
            {"$" +
              this.maskImporte.apply(pm.importe.toFixed(2).replace(".", ""))}
          </Text>
          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { width: "30%" },
            ]}
          >
            {"Marca:"}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { width: "70%" },
            ]}
          >
            {pm.fabricante}
          </Text>
          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { width: "30%" },
            ]}
          >
            {"Juego:"}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { width: "70%" },
            ]}
          >
            {pm.juego}
          </Text>
          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { width: "30%" },
            ]}
          >
            {"Estado:"}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { width: "70%", paddingBottom: 10 },
            ]}
          >
            {pm.estado_actual.toUpperCase()}
          </Text>
        </>
        {/*Middle*/}
        <>
          <View style={{ width: "100%", height: 10 }} />
          <Buttons
            pm={pm}
            updatePM={(newPM) => {
              this.updatePM(newPM);
            }}
          />
          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            PM:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {pm.idpm}
          </Text>
          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Apellido y nombre:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {this.apellidoNombre}
          </Text>

          {pm.dni != null && (
            <>
              <Text
                style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}
              >
                DNI:
              </Text>
              <Text
                style={[this.styles.textMiddleRight, stylesColor.textMiddle]}
              >
                {this.maskDNI.apply(pm.dni)}
              </Text>
            </>
          )}

          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Creado por:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {pm.nombreUsuario}
          </Text>

          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Importe:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {"$" +
              this.maskImporte.apply(pm.importe.toFixed(2).replace(".", "")) +
              "\n" +
              pm.importe_letras.charAt(0).toUpperCase() +
              pm.importe_letras.slice(1)}
          </Text>
          {pm.foto_maquina && (
            <>
              <Text
                style={[
                  this.styles.textMiddle,
                  stylesColor.textMiddle,
                  { paddingBottom: 5 },
                ]}
              >
                Foto Máquina:
              </Text>
              <View style={{ width: "100%" }}>
                <TouchableHighlight
                  disabled={this.state.fmLoading}
                  style={this.styles.fotoTouch}
                  underlayColor={colors.field}
                  onPress={() => this.showFotoMaquina()}
                >
                  {this.state.fmLoading ? (
                    <View
                      style={[this.styles.fotoButton, stylesColor.fmButton]}
                    >
                      <ActivityIndicator size="large" color={colors.field} />
                    </View>
                  ) : (
                    <View
                      style={[this.styles.fotoButton, stylesColor.fmButton]}
                    >
                      <Text style={[this.styles.fotoText, stylesColor.fmText]}>
                        Ver foto
                      </Text>
                    </View>
                  )}
                </TouchableHighlight>
              </View>
            </>
          )}
          {pm.foto_log && (
            <>
              <Text
                style={[
                  this.styles.textMiddle,
                  stylesColor.textMiddle,
                  { paddingBottom: 5 },
                ]}
              >
                Foto Log:
              </Text>
              <View style={{ width: "100%" }}>
                <TouchableHighlight
                  disabled={this.state.flLoading}
                  style={this.styles.fotoTouch}
                  underlayColor={colors.field}
                  onPress={() => this.showFotoLog()}
                >
                  {this.state.flLoading ? (
                    <View
                      style={[this.styles.fotoButton, stylesColor.flButton]}
                    >
                      <ActivityIndicator size="large" color={colors.field} />
                    </View>
                  ) : (
                    <View
                      style={[this.styles.fotoButton, stylesColor.flButton]}
                    >
                      <Text style={[this.styles.fotoText, stylesColor.flText]}>
                        Ver foto
                      </Text>
                    </View>
                  )}
                </TouchableHighlight>
              </View>
            </>
          )}

          {pm.obs != null && pm.obs.trim().length > 0 && (
            <>
              <Text
                style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}
              >
                Observaciones:
              </Text>
              <Text
                style={[this.styles.textMiddleRight, stylesColor.textMiddle]}
              >
                {pm.obs}
              </Text>
            </>
          )}
          <Text
            style={[
              this.styles.textMiddle,
              stylesColor.textMiddle,
              { paddingBottom: 5 },
            ]}
          >
            Historial:
          </Text>
          <DataTable style={{ paddingHorizontal: 0 }}>
            <DataTable.Header>
              <View
                style={{
                  width: "40%",
                  paddingHorizontal: 5,
                  paddingBottom: 5,
                }}
              >
                <Text style={[stylesColor.textMiddle]}>Estado</Text>
              </View>
              <View
                style={{
                  width: "40%",
                  paddingHorizontal: 5,
                  paddingBottom: 5,
                }}
              >
                <Text style={[stylesColor.textMiddle]}>Usuario</Text>
              </View>
              <View
                style={{
                  width: "20%",
                  paddingHorizontal: 5,
                  paddingBottom: 5,
                }}
              >
                <Text style={[stylesColor.textMiddle]}>Fecha</Text>
              </View>
            </DataTable.Header>
            {pm.estados.map((e) => (
              <DataTable.Row style={{ height: "auto" }}>
                <View
                  style={{
                    width: "40%",
                    paddingHorizontal: 5,
                    paddingTop: 5,
                  }}
                >
                  <Text style={[stylesColor.textMiddle]}>{e.estado}</Text>
                </View>
                <View
                  style={{
                    width: "40%",
                    paddingHorizontal: 5,
                    paddingTop: 5,
                  }}
                >
                  <Text style={[stylesColor.textMiddle]}>{e.usuario}</Text>
                </View>
                <View
                  style={{
                    width: "20%",
                    paddingHorizontal: 5,
                    paddingTop: 5,
                  }}
                >
                  <Text style={[stylesColor.textMiddle]}>
                    {(() => {
                      const fecha = new Date(
                        e.fecha.replace(" ", "T") + "-03:00"
                      );
                      return (
                        ("00" + fecha.getDate()).slice(-2) +
                        "/" +
                        ("00" + (fecha.getMonth() + 1)).slice(-2) +
                        " " +
                        ("00" + fecha.getHours()).slice(-2) +
                        ":" +
                        ("00" + fecha.getMinutes()).slice(-2)
                      );
                    })()}
                  </Text>
                </View>
              </DataTable.Row>
            ))}
          </DataTable>

          <View style={{ width: "100%", height: 10 }} />
        </>
      </Card>
    );
  }
}

export default withGeneralState(withTheme(CardPM));
