import React, { Component, useRef } from "react";
import withGeneralState from "../general/withGeneralState";
import withTheme from "../general/withTheme";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Dimensions,
  Text,
  TextInput,
  StyleSheet,
  View,
  ScrollView,
  TouchableHighlight,
  Keyboard,
  Platform,
  Image,
  ActivityIndicator,
} from "react-native";
import CurrencyInput from "react-native-currency-input";
import { BarCodeScanner } from "expo-barcode-scanner";
import { BrowserPDF417Reader } from "@zxing/browser";
import QrScanner from "qr-scanner";
import { Camera, CameraType } from "expo-camera";
import { Modal } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";
import StringMask from "string-mask";
import IcoQR from "../../assets/icons/qr.svg";
import IcoBarcode from "../../assets/icons/barcode.svg";
import IcoCamera from "../../assets/icons/camera.svg";
import IcoTrash from "../../assets/icons/trash.svg";
import NavBar from "../general/navbar";
import * as ImagePicker from "expo-image-picker";
import Toast from "react-native-root-toast";
import CheckBox from "expo-checkbox";
import NumeroALetras from "../general/numeroALetras.js";
import DropDownPicker from "react-native-dropdown-picker";
import Dialog from "../general/dialog";
import * as ImageManager from "../general/imageManager.js";

class AgregarPM extends Component {
  state = {
    renderNumber: 0,
    permissionCamera: false,
    showScannerQR: false,
    showScannerBarcode: false,
    maquina: null,
    maquinaValid: false,
    fabricante: "-",
    juego: "-",
    importe: null,
    importeValid: false,
    importe_letras: null,
    fecha: new Date(),
    dni: null,
    dniMasked: null,
    dniOn: true,
    dniValid: false,
    nombre: null,
    nombreValid: false,
    apellido: null,
    apellidoValid: false,
    fotoMaquina: null,
    fotoMaquinaLoading: false,
    fotoLogPM: null,
    fotoLogPMLoading: false,
    tipo: null,
    obs: null,
    loading: false,
    dropDownOpen: false,
    items: [
      {
        label: "PAGO MANUAL",
        value: "PAGO MANUAL",
        labelStyle: { fontSize: 16, fontWeight: "300" },
      },
      {
        label: "PAGO POR FALLO",
        value: "PAGO POR FALLO",
        labelStyle: { fontSize: 16, fontWeight: "300" },
      },
      {
        label: "POZO WAP",
        value: "POZO WAP",
        labelStyle: { fontSize: 16, fontWeight: "300" },
      },
      {
        label: "RECONOCIMIENTO",
        value: "RECONOCIMIENTO",
        labelStyle: { fontSize: 16, fontWeight: "300" },
      },
    ],
    back: false,
    showFoto: null,
    showConfirm: false,
    showCancel: false,
  };

  constructor(props) {
    super(props);
    this.setOpen = this.setOpen.bind(this);
    this.setValue = this.setValue.bind(this);
    this.setItems = this.setItems.bind(this);
  }

  refreshItems(tipo) {
    const newItems = [
      {
        label: "PAGO MANUAL",
        value: "PAGO MANUAL",
        labelStyle:
          tipo === "PAGO MANUAL"
            ? { fontSize: 23, fontWeight: "500" }
            : { fontSize: 16, fontWeight: "300" },
      },
      {
        label: "PAGO POR FALLO",
        value: "PAGO POR FALLO",
        labelStyle:
          tipo === "PAGO POR FALLO"
            ? { fontSize: 23, fontWeight: "500" }
            : { fontSize: 16, fontWeight: "300" },
      },
      {
        label: "POZO WAP",
        value: "POZO WAP",
        labelStyle:
          tipo === "POZO WAP"
            ? { fontSize: 23, fontWeight: "500" }
            : { fontSize: 16, fontWeight: "300" },
      },
      {
        label: "RECONOCIMIENTO",
        value: "RECONOCIMIENTO",
        labelStyle:
          tipo === "RECONOCIMIENTO"
            ? { fontSize: 23, fontWeight: "500" }
            : { fontSize: 16, fontWeight: "300" },
      },
    ];
    return newItems;
  }

  componentDidMount() {
    this.props.navigation.addListener("beforeRemove", (e) => {
      if (!this.state.back) {
        e.preventDefault();
      }
      if (this.state.showScannerQR) {
        this.setState({ showScannerQR: false });
        return;
      }
      if (this.state.showScannerBarcode) {
        this.setState({ showScannerBarcode: false });
        return;
      }
      if (this.state.showFoto) {
        this.setState({ showFoto: null });
        return;
      }
    });
  }

  enableBack(callback) {
    this.setState({ back: true }, callback);
  }

  async onPressQRButton() {
    if (Platform.OS !== "web") {
      let permissionCamera = this.state.permissionCamera;
      let showScannerQR = true;
      if (!permissionCamera) {
        let permission = await BarCodeScanner.requestPermissionsAsync();
        if (permission.status === "granted") {
          permissionCamera = true;
        } else {
          showScannerQR = false;
          const error = "Se necesitan permisos para usar la cámara.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: this.props.value.colors.danger,
            textColor: this.props.value.colors.text2,
          });
        }
      }
      this.setState({
        permissionCamera: permissionCamera,
        showScannerQR: showScannerQR,
      });
    } else {
      try {
        let result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          quality: 1,
        });
        if (!result.cancelled) {
          const data = await QrScanner.scanImage(result.uri);
          this.handleQRScanned({ undefined, data });
          this.getDatosMaquina();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async onPressBarcodeButton() {
    if (Platform.OS !== "web") {
      let permissionCamera = this.state.permissionCamera;
      let showScannerBarcode = true;
      if (!permissionCamera) {
        let permission = await BarCodeScanner.requestPermissionsAsync();
        if (permission.status === "granted") {
          permissionCamera = true;
        } else {
          showScannerBarcode = false;
          const error = "Se necesitan permisos para usar la cámara.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: this.props.value.colors.danger,
            textColor: this.props.value.colors.text2,
          });
        }
      }
      this.setState({
        permissionCamera: permissionCamera,
        showScannerBarcode: showScannerBarcode,
      });
    } else {
      try {
        let result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          quality: 1,
          base64: true,
        });
        if (!result.cancelled) {
          // Decoder de código de barra
          const codeReader = new BrowserPDF417Reader();
          const data = (await codeReader.decodeFromImageUrl(result.uri)).text;

          if (data) {
            this.handleBarcodeScanned({ undefined, data });
          } else {
            Toast.show("No se detectó un código de barras válido", {
              duration: Toast.durations.LONG,
              backgroundColor: this.props.value.colors.danger,
              textColor: this.props.value.colors.text2,
            });
          }
        }
      } catch (e) {
        Toast.show("No se detectó un código de barras válido", {
          duration: Toast.durations.LONG,
          backgroundColor: this.props.value.colors.danger,
          textColor: this.props.value.colors.text2,
        });
        console.log(e);
      }
    }
  }

  async getDatosMaquina() {
    if (this.state.maquina && this.state.maquina.length > 0) {
      const colors = this.props.value.colors;

      let res = await fetch(
        this.props.value.proxy + `/pm/maquina?uid=${this.state.maquina}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status == 200) {
        const datos = await res.json();
        this.setState({
          maquinaValid: true,
          fabricante: datos.fabricante,
          juego: datos.juego,
        });
        this.maquinaRef.setNativeProps({
          style: { borderColor: colors.border },
        });
      } else {
        let error;
        if (res.status == 401) {
          error = "Sesión expirada. Por favor ingrese nuevamente.";
          const toast = Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          await fetch(this.props.value.proxy + "/accounts/logout", {
            method: "POST",
          });
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Login" }],
          });
        } else {
          switch (res.status) {
            case 403:
              error = "Permisos insuficientes.";
              break;
            case 404:
              error = "Máquina ingresada no válida.";
              break;
            case 406:
              error = "Error de conexión.";
              break;
            default:
              error = "Error de servidor.";
              break;
          }
          const toast = Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.setState(
            {
              maquinaValid: false,
              fabricante: "-",
              juego: "-",
            },
            () => {
              this.maquinaRef.setNativeProps({
                style: { borderColor: "red" },
              });
            }
          );
        }
      }
    } else {
      this.setState(
        {
          maquinaValid: false,
          fabricante: "-",
          juego: "-",
        },
        () => {
          this.maquinaRef.setNativeProps({
            style: { borderColor: "red" },
          });
        }
      );
    }
  }

  async getDatosCliente(dni) {
    let res = await fetch(
      this.props.value.proxy + `/pm/datosCliente?dni=${dni}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status == 200) {
      return await res.json();
    } else {
      return { nombre: null, apellido: null };
    }
  }

  async onPressFoto(field) {
    if (this.state[field]) {
      this.setState({ showFoto: this.state[field] });
    } else {
      let midState = {};
      midState[field + "Loading"] = true;
      this.setState(midState, async () => {
        const image = await ImageManager.getImage();
        let newState = {};
        newState[field] = image;
        newState[field + "Loading"] = false;
        this.setState(newState);
      });
    }
  }

  handleQRScanned({ type, data }) {
    let scanned = null;
    try {
      Number(data);
      scanned = data;
    } catch (e) {}
    this.setState({
      showScanner: scanned == null,
      maquina: scanned,
      maquinaValid: true,
    });
  }

  handleBarcodeScanned({ type, data }) {
    const split = data.split("@");
    if (split.length < 5) {
      Toast.show("No se detectó un código de barras válido", {
        duration: Toast.durations.LONG,
        backgroundColor: this.props.value.colors.danger,
        textColor: this.props.value.colors.text2,
      });
      return;
    }
    // DNI
    const dniFormatter = new StringMask("#.##0", { reverse: true });
    let dni = -1;
    try {
      dni = Number(split[4]);
    } catch (e) {}
    const dniValid = dni != -1 && dni >= 1000000;

    // NOMBRE
    const nombre = split[2];
    const nombreValid = nombre.length > 0;

    // APELLIDO
    const apellido = split[1];
    const apellidoValid = apellido.length > 0;

    // STATE
    this.setState({
      showScannerBarcode: false,
      dni: dniValid ? dni : null,
      dniMasked: dniValid ? dniFormatter.apply(dni) : "-",
      dniOn: true,
      dniValid: dniValid,
      nombre: nombre,
      nombreValid: nombreValid,
      apellido: apellido,
      apellidoValid: apellidoValid,
    });
  }

  findFirstError() {
    const {
      maquinaValid,
      importeValid,
      dniOn,
      dniValid,
      tipo,
      nombreValid,
      apellidoValid,
    } = this.state;
    if (!maquinaValid) {
      this.maquinaRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el número de máquina.";
    }
    if (!importeValid) {
      this.importeRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el importe.";
    }
    if (dniOn && !dniValid) {
      this.dniRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el DNI.";
    }
    if (!tipo) {
      return "Seleccione un tipo.";
    }
    if (dniOn && !nombreValid) {
      this.nombreRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el nombre.";
    }
    if (dniOn && !apellidoValid) {
      this.apellidoRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el apellido.";
    }
    return null;
  }

  confirm() {
    const validationError = this.findFirstError();
    const colors = this.props.value.colors;
    if (!validationError) {
      this.setState(
        {
          loading: true,
          dni: this.state.dniOn ? this.state.dni : null,
        },
        async () => {
          Keyboard.dismiss();

          let res = await fetch(this.props.value.proxy + "/pm/misPM", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pm: this.state,
            }),
          });

          this.setState({ loading: false });

          if (res.status == 200) {
            const mensaje = "PM agregado correctamente.";
            const toast = Toast.show(mensaje, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.success,
              textColor: colors.text2,
            });
            this.setState({ back: true }, () =>
              this.props.navigation.reset({
                index: 0,
                routes: this.props.value.userData.idSala
                  ? [
                      { name: "Salas" },
                      {
                        name: "Opciones",
                      },
                      {
                        name: "PagosManuales",
                      },
                      {
                        name: "MisPM",
                      },
                    ]
                  : [{ name: "Salas" }],
              })
            );
          } else {
            let error;
            if (res.status == 401) {
              error = "Sesión expirada. Por favor ingrese nuevamente.";
              const toast = Toast.show(error, {
                duration: Toast.durations.LONG,
                backgroundColor: colors.danger,
                textColor: colors.text2,
              });
              await fetch(this.props.value.proxy + "/accounts/logout", {
                method: "POST",
              });
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: "Login" }],
              });
            } else {
              if (res.status == 400) {
                error = "Sala no seleccionada.";
                const toast = Toast.show(error, {
                  duration: Toast.durations.LONG,
                  backgroundColor: colors.danger,
                  textColor: colors.text2,
                });
                this.props.navigation.reset({
                  index: 0,
                  routes: [{ name: "Salas" }],
                });
              } else {
                switch (res.status) {
                  case 403:
                    error = "Permisos insuficientes.";
                    break;
                  case 404:
                    error = "La máquina ingresada no coincide con la sala.";
                    this.maquinaRef.setNativeProps({
                      style: { borderColor: "red" },
                    });
                    break;
                  case 406:
                    error = "Error de conexión.";
                    break;
                  default:
                    error = "Error de servidor.";
                    break;
                }

                const toast = Toast.show(error, {
                  duration: Toast.durations.LONG,
                  backgroundColor: colors.danger,
                  textColor: colors.text2,
                });
              }
            }
          }
        }
      );
    } else {
      Toast.show(validationError, {
        duration: Toast.durations.LONG,
        backgroundColor: colors.danger,
        textColor: colors.text2,
      });
    }
  }

  setOpen(open) {
    this.setState({
      dropDownOpen: open,
    });
  }

  setValue(callback) {
    const tipo = callback(this.state.tipo);
    this.setState({
      tipo: tipo,
      changed: true,
      items: this.refreshItems(tipo),
    });
  }

  setItems(callback) {
    this.setState((state) => ({
      items: callback(state.items),
      changed: true,
    }));
  }

  showConfirmDialog() {
    this.setState({ showConfirm: true });
  }

  showCancelDialog() {
    this.setState({ showCancel: true });
  }

  cancel() {
    this.setState({ back: true }, () => this.props.navigation.goBack());
  }

  render() {
    const colors = this.props.value.colors;
    if (this.state.renderNumber == 0) {
      this.setState({ renderNumber: 1 });
    }
    if (this.state.renderNumber == 1) {
      this.setState({ renderNumber: 2 });
      this.importeRef.setNativeProps({
        style: { borderColor: colors.border },
      });
    }

    const { height } = Dimensions.get("window");
    const styles = StyleSheet.create({
      background: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: height,
        justifyContent: "center",
        backgroundColor: colors.background,
        zIndex: 0,
      },
      master: {
        display: "flex",
        width: 400,
        height: "100%",
        zIndex: 1,
      },
      scroll: {
        display: "flex",
      },
      container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: 15,
      },
      card: {
        width: "95%",
        paddingHorizontal: 25,
        paddingVertical: 15,
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        justifyContent: "flex-start",
      },
      field: {
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: 370,
        marginTop: 5,
        marginBottom: 5,
        paddingVertical: 5,
        zIndex: 49,
      },
      fieldLabel: {
        width: "100%",
        color: colors.text3,
        fontSize: 17,
        fontWeight: "550",
        marginBottom: 10,
        zIndex: 49,
      },

      dniLabel: {
        width: "85%",
        color: colors.text3,
        fontSize: 17,
        fontWeight: "550",
        alignSelf: "baseline",
        marginBottom: 10,
      },
      checkbox: {
        width: 18,
        height: 18,
        marginRight: 15,
        flexShrink: 0,
        alignSelf: "baseline",
        justifyContent: "center",
      },
      fieldInput: {
        width: "100%",
        paddingTop: "3%",
        paddingBottom: "3%",
        paddingLeft: "7%",
        paddingRight: "7%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: colors.field,
        color: colors.text3,
        fontSize: 25,
        fontWeight: "500",
        zIndex: 49,
      },
      importeInput: {
        width: "100%",
        paddingTop: "3%",
        paddingBottom: "3%",
        paddingLeft: "7%",
        paddingRight: "7%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: colors.field,
        color: colors.text3,
        fontSize: 25,
        fontWeight: "500",
        textAlign: "right",
        zIndex: 49,
      },
      staticField: {
        width: "100%",
        paddingTop: "0%",
        paddingBottom: "0%",
        paddingLeft: "0%",
        paddingRight: "0%",
        borderWidth: 0,
        backgroundColor: colors.card,
        color: colors.text3,
        fontSize: 25,
        fontWeight: "500",
        zIndex: 49,
      },
      qrButton: {
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
      },
      barcodeButton: {
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
      },
      imageButton: {
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      },
      image: {
        width: 90,
        height: 90,
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      },
      sendTouch: {
        marginTop: 35,
        width: "45%",
        alignSelf: "center",
        borderRadius: 5,
        zIndex: 49,
      },
      buttonsContainer: {
        justifyContent: "space-evenly",
        flexDirection: "row",
        width: "100%",
        maxWidth: 370,
        marginTop: 5,
        marginBottom: 5,
        paddingVertical: 5,
        zIndex: 49,
        backgroundColor: colors.card,
      },
      sendButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.loading ? colors.field : colors.success,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 3,
        paddingRight: 3,
        zIndex: 49,
      },

      cancelButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.loading ? colors.field : colors.accent1,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 3,
        paddingRight: 3,
        zIndex: 49,
      },

      sendText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.loading ? colors.placeholder : colors.text2,
        zIndex: 49,
      },

      cancelText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.loading ? colors.placeholder : colors.text1,
        zIndex: 49,
      },

      select: {
        alignSelf: "center",
        backgroundColor: colors.field,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        zIndex: 50,
      },
      drop: {
        alignSelf: "center",
        backgroundColor: colors.field,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        maxHeight: 500,
        zIndex: 50,
      },
      dialogBox: {
        width: "95%",
        paddingHorizontal: 25,
        paddingVertical: 15,
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        justifyContent: "center",
        alignItems: "center",
      },
      dialogText: {
        width: "100%",
        color: colors.text3,
        fontSize: 17,
        fontWeight: "550",
        marginBottom: 10,
      },
    });
    const dniFormatter = new StringMask("#.##0", { reverse: true });
    return (
      <SafeAreaView>
        {this.state.showScannerQR || this.state.showScannerBarcode ? (
          Platform.OS === "web" ? (
            <Camera
              style={styles.background}
              onBarCodeScanned={(object) => {
                if (this.state.showScannerQR) {
                  this.handleQRScanned(object);
                } else {
                  this.handleBarcodeScanned(object);
                }
              }}
              type={CameraType.back}
              barCodeScannerSettings={{
                barCodeTypes: [
                  this.state.showScannerQR
                    ? BarCodeScanner.Constants.BarCodeType.qr
                    : BarCodeScanner.Constants.BarCodeType.pdf417,
                ],
              }}
              ref={this.props.ref}
            />
          ) : (
            <BarCodeScanner
              onBarCodeScanned={(object) => {
                if (this.state.showScannerQR) {
                  this.handleQRScanned(object);
                } else {
                  this.handleBarcodeScanned(object);
                }
              }}
              barCodeTypes={[this.state.showScannerQR ? "qr" : "pdf417"]}
              style={styles.background}
            />
          )
        ) : (
          <>
            <Modal
              visible={this.state.showFoto != null}
              transparent={true}
              onRequestClose={() => {
                this.setState({ showFoto: null });
              }}
            >
              <ImageViewer
                imageUrls={[
                  { url: "data:image/jpeg;base64," + this.state.showFoto },
                ]}
                saveToLocalByLongPress={false}
                menuContext={null}
                renderIndicator={() => null}
              />
            </Modal>
            <Dialog
              show={this.state.showConfirm}
              hide={() => this.setState({ showConfirm: false })}
              text="¿Desea confirmar el PM?"
              button1={{
                text: "No",
                color1: colors.field,
                color2: colors.text3,
              }}
              button2={{
                text: "Si",
                color1: colors.success,
                color2: colors.text2,
                onPress: () => {
                  this.confirm();
                },
              }}
            />
            <Dialog
              show={this.state.showCancel}
              hide={() => this.setState({ showCancel: false })}
              text="¿Desea cancelar el PM?"
              button1={{
                text: "No",
                color1: colors.field,
                color2: colors.text3,
              }}
              button2={{
                text: "Si",
                color1: colors.accent1,
                color2: colors.text1,
                onPress: () => {
                  this.cancel();
                },
              }}
            />
            <NavBar
              title="Agregar PM"
              navigation={this.props.navigation}
              enableBack={(callback) => this.enableBack(callback)}
            />
            <View style={styles.background}>
              <View style={styles.master}>
                <ScrollView
                  style={styles.scroll}
                  contentContainerStyle={styles.container}
                  showsVerticalScrollIndicator={false}
                  keyboardShouldPersistTaps="always"
                >
                  <View style={styles.card}>
                    <Text style={styles.fieldLabel}>
                      Los campos marcados con * son obligatorios.
                    </Text>
                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}># Máquina*</Text>
                      <TextInput
                        ref={(e) => (this.maquinaRef = e)}
                        style={[
                          styles.fieldInput,
                          {
                            width: "80%",
                            fontWeight:
                              this.state.maquina &&
                              this.state.maquina.length > 0
                                ? "500"
                                : "300",
                          },
                        ]}
                        value={this.state.maquina}
                        placeholder="01234"
                        placeholderTextColor={colors.placeholder}
                        keyboardType="numeric"
                        onChangeText={(text) => {
                          let value = text.replace(/[^0-9]/g, "");
                          let valid = value.length > 0;
                          if (!valid) {
                            this.maquinaRef.setNativeProps({
                              style: { borderColor: "red" },
                            });
                          }
                          this.setState({
                            maquina: value,
                          });
                        }}
                        onSubmitEditing={() => {
                          this.importeRef.focus();
                        }}
                        onFocus={() => {
                          this.maquinaRef.setNativeProps({
                            style: {
                              backgroundColor: colors.focus,
                              color: colors.text4,
                            },
                          });
                        }}
                        onBlur={() => {
                          this.maquinaRef.setNativeProps({
                            style: [
                              styles.fieldInput,
                              {
                                width: "80%",
                                fontWeight:
                                  this.state.maquina &&
                                  this.state.maquina.length > 0
                                    ? "500"
                                    : "300",
                              },
                            ],
                          });
                          this.getDatosMaquina();
                        }}
                      />
                      <TouchableHighlight
                        onPress={() => {
                          this.onPressQRButton();
                        }}
                        style={styles.qrButton}
                      >
                        <IcoQR
                          width={30}
                          height={30}
                          stroke={colors.text3}
                          fill={colors.text3}
                        />
                      </TouchableHighlight>
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Fabricante</Text>
                      <Text style={[styles.staticField, { fontSize: 20 }]}>
                        {this.state.fabricante}
                      </Text>
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Juego</Text>
                      <Text style={[styles.staticField, { fontSize: 20 }]}>
                        {this.state.juego}
                      </Text>
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Importe*</Text>
                      <CurrencyInput
                        ref={(e) => (this.importeRef = e)}
                        style={[
                          styles.importeInput,
                          {
                            fontSize: 32,
                            fontWeight: this.state.importe ? "500" : "300",
                          },
                        ]}
                        value={this.state.importe}
                        placeholder="$ 1.234,56"
                        placeholderTextColor={colors.placeholder}
                        keyboardType="numeric"
                        prefix="$"
                        delimiter="."
                        separator=","
                        precision={2}
                        minValue={0}
                        onChangeValue={(value) => {
                          this.setState({ importe: value });
                        }}
                        onChangeText={(formatted) => {
                          let value = null;
                          try {
                            value = Number(
                              formatted
                                .split("$")
                                .join("")
                                .split(".")
                                .join("")
                                .split(",")
                                .join(".")
                            );
                          } catch (e) {}
                          let valid =
                            value != null && formatted.length > 0 && value > 0;
                          if (valid) {
                            this.importeRef.setNativeProps({
                              style: { borderColor: colors.border },
                            });
                          } else {
                            this.importeRef.setNativeProps({
                              style: { borderColor: "red" },
                            });
                          }
                          this.setState({
                            importeValid: valid,
                            importe_letras: valid
                              ? NumeroALetras(value).trim()
                              : null,
                          });
                        }}
                        onFocus={() => {
                          this.importeRef.setNativeProps({
                            style: {
                              backgroundColor: colors.focus,
                              color: colors.text4,
                            },
                          });
                        }}
                        selectTextOnFocus
                        onBlur={() => {
                          this.importeRef.setNativeProps({
                            style: [
                              styles.importeInput,
                              {
                                fontSize: 32,
                                borderColor: this.state.importeValid
                                  ? colors.border
                                  : "red",
                                fontWeight: this.state.importe ? "500" : "300",
                              },
                            ],
                          });
                        }}
                        onSubmitEditing={() => this.dniRef.focus()}
                      />
                    </View>

                    <View style={styles.field}>
                      <Text
                        style={[
                          styles.staticField,
                          { fontSize: 20, height: "auto" },
                        ]}
                        multiline={true}
                      >
                        {this.state.importeValid
                          ? this.state.importe_letras.charAt(0).toUpperCase() +
                            this.state.importe_letras.slice(1)
                          : "-"}
                      </Text>
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Fecha y hora</Text>
                      <TextInput
                        style={styles.staticField}
                        value={(() => {
                          let fecha = this.state.fecha;
                          let retorno =
                            ("00" + fecha.getDate()).slice(-2) +
                            "/" +
                            ("00" + (fecha.getMonth() + 1)).slice(-2) +
                            "/" +
                            fecha.getFullYear() +
                            " | " +
                            ("00" + fecha.getHours()).slice(-2) +
                            ":" +
                            ("00" + fecha.getMinutes()).slice(-2);
                          return retorno;
                        })()}
                        editable={false}
                      />
                    </View>

                    <View style={styles.field}>
                      <CheckBox
                        value={this.state.dniOn}
                        onValueChange={(value) => {
                          this.setState({ dniOn: value });
                        }}
                        style={styles.checkbox}
                      />
                      <Text style={styles.dniLabel}>DNI</Text>

                      <TextInput
                        ref={(e) => (this.dniRef = e)}
                        style={[
                          this.state.dniOn
                            ? styles.fieldInput
                            : styles.staticField,
                          {
                            width: "80%",
                            fontWeight:
                              !this.state.dniOn ||
                              (this.state.dniMasked &&
                                this.state.dniMasked.length > 0)
                                ? "500"
                                : "300",
                          },
                        ]}
                        value={this.state.dniOn ? this.state.dniMasked : "-"}
                        editable={this.state.dniOn}
                        placeholder="12.345.678"
                        placeholderTextColor={colors.placeholder}
                        keyboardType="numeric"
                        onChangeText={(text) => {
                          let value = -1;
                          try {
                            value = Number(text.replace(/[.,]/g, ""));
                          } catch (e) {}
                          let valid = value != -1 && value >= 1000000;
                          if (valid) {
                            this.dniRef.setNativeProps({
                              style: { borderColor: colors.border },
                            });
                          } else {
                            this.dniRef.setNativeProps({
                              style: { borderColor: "red" },
                            });
                          }
                          this.setState({
                            dni: value,
                            dniMasked: text,
                            dniValid: valid,
                          });
                        }}
                        selectTextOnFocus
                        onFocus={() => {
                          this.dniRef.setNativeProps({
                            style: {
                              backgroundColor: colors.focus,
                              color: colors.text4,
                            },
                          });
                        }}
                        onBlur={async () => {
                          this.dniRef.setNativeProps({
                            style: [
                              styles.fieldInput,
                              {
                                width: "80%",
                                fontWeight:
                                  !this.state.dniOn ||
                                  (this.state.dniMasked &&
                                    this.state.dniMasked.length > 0)
                                    ? "500"
                                    : "300",
                              },
                            ],
                          });
                          if (this.state.dni) {
                            const datosCliente = await this.getDatosCliente(
                              this.state.dni
                            );
                            const { nombre, apellido } = datosCliente;
                            this.setState({
                              dniMasked: dniFormatter.apply(this.state.dni),
                              nombre: nombre,
                              nombreValid: nombre && nombre.length > 0,
                              apellido: apellido,
                              apellidoValid: apellido && apellido.length > 0,
                            });
                          }
                        }}
                        onSubmitEditing={() => this.nombreRef.focus()}
                      />
                      <TouchableHighlight
                        onPress={() => {
                          this.onPressBarcodeButton();
                        }}
                        style={styles.barcodeButton}
                      >
                        <IcoBarcode
                          width={30}
                          height={30}
                          stroke={colors.text3}
                          fill={colors.text3}
                        />
                      </TouchableHighlight>
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Nombre</Text>
                      <TextInput
                        ref={(e) => (this.nombreRef = e)}
                        style={[
                          styles.fieldInput,
                          {
                            fontWeight:
                              this.state.nombre && this.state.nombre.length > 0
                                ? "500"
                                : "300",
                          },
                        ]}
                        value={this.state.nombre}
                        placeholder="María"
                        placeholderTextColor={colors.placeholder}
                        onChangeText={(text) => {
                          let valid = text.length > 0;
                          if (valid || !this.state.dniOn) {
                            this.nombreRef.setNativeProps({
                              style: { borderColor: colors.border },
                            });
                          } else {
                            this.nombreRef.setNativeProps({
                              style: { borderColor: "red" },
                            });
                          }
                          this.setState({
                            nombre: text,
                            nombreValid: valid,
                          });
                        }}
                        onFocus={() => {
                          this.nombreRef.setNativeProps({
                            style: {
                              backgroundColor: colors.focus,
                              color: colors.text4,
                            },
                          });
                        }}
                        onBlur={() => {
                          this.nombreRef.setNativeProps({
                            style: [
                              styles.fieldInput,
                              {
                                fontWeight:
                                  this.state.nombre &&
                                  this.state.nombre.length > 0
                                    ? "500"
                                    : "300",
                              },
                            ],
                          });
                        }}
                        onSubmitEditing={() => this.apellidoRef.focus()}
                      />
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Apellido</Text>
                      <TextInput
                        ref={(e) => (this.apellidoRef = e)}
                        style={[
                          styles.fieldInput,
                          {
                            fontWeight:
                              this.state.apellido &&
                              this.state.apellido.length > 0
                                ? "500"
                                : "300",
                          },
                        ]}
                        value={this.state.apellido}
                        placeholder="González"
                        placeholderTextColor={colors.placeholder}
                        onChangeText={(text) => {
                          let valid = text.length > 0;
                          if (valid || !this.state.dniOn) {
                            this.apellidoRef.setNativeProps({
                              style: { borderColor: colors.border },
                            });
                          } else {
                            this.apellidoRef.setNativeProps({
                              style: { borderColor: "red" },
                            });
                          }
                          this.setState({
                            apellido: text,
                            apellidoValid: valid,
                          });
                        }}
                        onFocus={() => {
                          this.apellidoRef.setNativeProps({
                            style: {
                              backgroundColor: colors.focus,
                              color: colors.text4,
                            },
                          });
                        }}
                        onBlur={() => {
                          this.apellidoRef.setNativeProps({
                            style: [
                              styles.fieldInput,
                              {
                                fontWeight:
                                  this.state.apellido &&
                                  this.state.apellido.length > 0
                                    ? "500"
                                    : "300",
                              },
                            ],
                          });
                        }}
                        onSubmitEditing={() => Keyboard.dismiss()}
                      />
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Foto - Máquina</Text>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          paddingLeft: 50,
                          paddingRight: 50,
                        }}
                      >
                        <TouchableHighlight
                          onPress={() => {
                            this.onPressFoto("fotoMaquina");
                          }}
                          style={styles.imageButton}
                          disabled={this.state.fotoMaquinaLoading}
                        >
                          {this.state.fotoMaquinaLoading ? (
                            <ActivityIndicator
                              size="large"
                              color={colors.text3}
                            />
                          ) : this.state.fotoMaquina ? (
                            <Image
                              source={{
                                uri:
                                  "data:image/jpeg;base64," +
                                  this.state.fotoMaquina,
                              }}
                              style={styles.image}
                            />
                          ) : (
                            <IcoCamera
                              width={70}
                              height={70}
                              stroke={colors.text3}
                              fill={colors.text3}
                            />
                          )}
                        </TouchableHighlight>
                        {this.state.fotoMaquina != null && (
                          <TouchableHighlight
                            onPress={() => {
                              this.setState({ fotoMaquina: null });
                            }}
                            style={styles.imageButton}
                          >
                            <IcoTrash
                              width={50}
                              height={50}
                              stroke={colors.text3}
                              fill={colors.text3}
                            />
                          </TouchableHighlight>
                        )}
                      </View>
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Foto - Log PM</Text>
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          paddingLeft: 50,
                          paddingRight: 50,
                        }}
                      >
                        <TouchableHighlight
                          onPress={() => {
                            this.onPressFoto("fotoLogPM");
                          }}
                          style={styles.imageButton}
                        >
                          {this.state.fotoLogPMLoading ? (
                            <ActivityIndicator
                              size="large"
                              color={colors.text3}
                            />
                          ) : this.state.fotoLogPM ? (
                            <Image
                              source={{
                                uri:
                                  "data:image/jpeg;base64," +
                                  this.state.fotoLogPM,
                              }}
                              style={styles.image}
                            />
                          ) : (
                            <IcoCamera
                              width={70}
                              height={70}
                              stroke={colors.text3}
                              fill={colors.text3}
                            />
                          )}
                        </TouchableHighlight>
                        {this.state.fotoLogPM != null && (
                          <TouchableHighlight
                            onPress={() => {
                              this.setState({ fotoLogPM: null });
                            }}
                            style={styles.imageButton}
                          >
                            <IcoTrash
                              width={50}
                              height={50}
                              stroke={colors.text3}
                              fill={colors.text3}
                            />
                          </TouchableHighlight>
                        )}
                      </View>
                    </View>

                    <View style={[styles.field, { zIndex: 100 }]}>
                      <Text style={styles.fieldLabel}>Tipo*</Text>
                      <DropDownPicker
                        dropDownContainerStyle={styles.drop}
                        style={styles.select}
                        open={this.state.dropDownOpen}
                        setOpen={this.setOpen}
                        items={this.state.items}
                        setItems={this.setItems}
                        value={this.state.tipo}
                        setValue={this.setValue}
                        theme={colors.theme}
                        multiple={false}
                        placeholder="Tipo"
                        listMode="SCROLLVIEW"
                      />
                    </View>

                    <View style={styles.field}>
                      <Text style={styles.fieldLabel}>Observaciones</Text>
                      <TextInput
                        ref={(e) => (this.obsRef = e)}
                        style={[
                          styles.fieldInput,
                          {
                            fontWeight:
                              this.state.obs && this.state.obs.length > 0
                                ? "500"
                                : "300",
                            fontSize: 18,
                            height: 100,
                            textAlignVertical: "top",
                          },
                        ]}
                        value={this.state.obs}
                        placeholder="Observaciones"
                        placeholderTextColor={colors.placeholder}
                        onChangeText={(text) => {
                          this.setState({
                            obs: text,
                          });
                        }}
                        onFocus={() => {
                          this.obsRef.setNativeProps({
                            style: {
                              backgroundColor: colors.focus,
                              color: colors.text4,
                            },
                          });
                        }}
                        onBlur={() => {
                          this.obsRef.setNativeProps({
                            style: [
                              styles.fieldInput,
                              {
                                fontWeight:
                                  this.state.obs && this.state.obs.length > 0
                                    ? "500"
                                    : "300",
                                fontSize: 18,
                                height: 100,
                                textAlignVertical: "top",
                              },
                            ],
                          });
                        }}
                        multiline={true}
                        numberOfLines={1}
                      />
                    </View>
                    <View style={styles.buttonsContainer}>
                      <TouchableHighlight
                        disabled={this.state.loading}
                        style={styles.sendTouch}
                        underlayColor={colors.field}
                        onPress={() => this.showCancelDialog()}
                      >
                        <View style={styles.cancelButton}>
                          <Text style={styles.cancelText}>Cancelar</Text>
                        </View>
                      </TouchableHighlight>

                      <TouchableHighlight
                        disabled={this.state.loading}
                        style={styles.sendTouch}
                        underlayColor={colors.field}
                        onPress={() => this.showConfirmDialog()}
                      >
                        <View style={styles.sendButton}>
                          {this.state.loading ? (
                            <ActivityIndicator
                              size="large"
                              color={colors.success}
                            />
                          ) : (
                            <Text style={styles.sendText}>Confirmar</Text>
                          )}
                        </View>
                      </TouchableHighlight>
                    </View>

                    <View style={{ width: "100%", height: 30 }} />
                  </View>

                  <View style={{ width: "100%", height: 300 }} />
                </ScrollView>
              </View>
            </View>
          </>
        )}
      </SafeAreaView>
    );
  }
}

const withRef = (Component) => (props) => {
  const ref = useRef(null);
  return <Component {...props} ref={ref} />;
};

export default withRef(withGeneralState(withTheme(AgregarPM)));
