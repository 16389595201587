import React, { Component } from "react";
import {
  TouchableHighlight,
  ActivityIndicator,
  Text,
  StyleSheet,
  View,
} from "react-native";
import withTheme from "./withTheme";

class Button extends Component {
  state = {
    loading: false,
    disabled: false,
    accent: this.props.accent || this.props.value.colors.field,
  };

  componentDidUpdate(prevProps) {
    if (this.props.loading != prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
    if (this.props.disabled != prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
    if (prevProps.accent != this.props.accent) {
      this.setState({ accent: this.props.accent });
    }
  }

  render() {
    const colors = this.props.value.colors;
    const styles = StyleSheet.create({
      container: {
        backgroundColor: this.state.accent,
        marginTop: 15,
        marginBottom: 10,
        width: "40%",
        height: 45,
      },
      button: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: colors[this.props.background],
        padding: 7,
        zIndex: 1,
      },
      text: {
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
        color: this.props.color === "dark" ? colors.text2 : colors.text3,
      },
    });
    return (
      <TouchableHighlight
        style={styles.container}
        disabled={this.state.disabled}
        underlayColor={colors.field}
        onPress={this.props.onPress}
      >
        {this.state.loading ? (
          <View style={styles.button}>
            <ActivityIndicator size="large" color={colors.field} />
          </View>
        ) : (
          <View style={styles.button}>
            <Text style={styles.text}>{this.props.text}</Text>
          </View>
        )}
      </TouchableHighlight>
    );
  }
}

export default withTheme(Button);
