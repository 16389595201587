import React, { Component } from "react";
import withTheme from "../withTheme";
import {
  View,
  StyleSheet,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { RefreshControl } from "react-native-web-refresh-control";
import NavBar from "../navbar";
import { SafeAreaView } from "react-native-safe-area-context";

const Menu = ({
  title,
  children,
  navigation,
  value,
  refreshing,
  onRefresh,
}) => {
  const refreshable = typeof onRefresh !== "undefined";
  const colors = value.colors;
  const { width, height } = useWindowDimensions();
  const styles = StyleSheet.create({
    background: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: height,
      justifyContent: "center",
      backgroundColor: colors.background,
      zIndex: 0,
    },
    master: {
      display: "flex",
      width: "100%",
      maxWidth: width > 400 ? 400 * Math.floor(width / 400) : "100%",
      height: "100%",
      zIndex: 1,
    },
    scroll: {
      display: "flex",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      alignContent: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      marginTop: 15,
    },
  });
  return (
    <SafeAreaView>
      <NavBar title={title} navigation={navigation} />
      <View style={styles.background}>
        <View style={styles.master}>
          <ScrollView
            style={styles.scroll}
            contentContainerStyle={styles.container}
            showsVerticalScrollIndicator={false}
            refreshControl={
              refreshable ? (
                <RefreshControl
                  enabled={true}
                  colors={[colors.accent2]}
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                />
              ) : null
            }
          >
            {children}
            <View style={{ width: "100%", height: 100 }} />
          </ScrollView>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default withTheme(Menu);
