import React, { Component } from "react";
import { throttle } from "lodash";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import withTheme from "./withTheme.jsx";
import Ajustes from "./ajustes.jsx";
import IcoBack from "../../assets/icons/back.svg";

const NavBar = ({ title, navigation, enableBack, value }) => {
  const colors = value.colors;
  const index = navigation.getState().index;
  const route = navigation.getState().routes[index].name;
  const showBack =
    route !== "Salas" && route !== "AgregarPM" && route !== "Canjear";
  const showAjustes = route !== "CambiarPass" && route !== "OlvidoPass";
  const styles = StyleSheet.create({
    background: {
      flexDirection: "row",
      width: "100%",
      height: 70,
      backgroundColor: colors.accent2,
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 0,
    },
    backButton: {
      width: 50,
      marginLeft: 5,
      height: 50,
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      fontSize: 25,
      fontWeight: "700",
      textAlign: "center",
      color: colors.text2,
    },
  });
  return (
    <View style={styles.background}>
      {showBack ? (
        <TouchableOpacity
          onPress={throttle(
            () => {
              const nav = () => {
                navigation.goBack();
              };
              if (enableBack) {
                enableBack(nav);
              } else {
                nav();
              }
            },
            500,
            { trailing: false }
          )}
          activeOpacity={0.7}
        >
          <View style={styles.backButton}>
            <IcoBack
              width={25}
              height={25}
              fill={colors.text2}
              stroke={colors.text2}
            />
          </View>
        </TouchableOpacity>
      ) : (
        <View style={[styles.background, { marginLeft: 5, width: 50 }]} />
      )}

      <Text style={styles.title}>{title}</Text>
      {showAjustes ? (
        <Ajustes navigation={navigation} enableBack={enableBack} />
      ) : (
        <View style={{ width: 25, marginRight: 30 }} />
      )}
    </View>
  );
};

export default withTheme(NavBar);
