import React, { Component } from "react";
import { useContext } from "react";
import { GeneralContext } from "./generalProvider";

const withGeneralState = (Component) => (props) => {
  let newProps = { ...props };
  if (typeof props.value !== "undefined") {
    newProps.value = { ...props.value, ...useContext(GeneralContext) };
  } else {
    newProps.value = { ...useContext(GeneralContext) };
  }
  return <Component {...newProps} />;
};

export default withGeneralState;
