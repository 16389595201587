import React, { Component } from "react";
import Button from "../general/button";
import { Platform, View, StyleSheet, Alert } from "react-native";
import Toast from "react-native-root-toast";
import withTheme from "../general/withTheme";
import withGeneralState from "../general/withGeneralState";
import withNavigation from "../general/withNavigation";
import * as FileSystem from "expo-file-system";
import * as IntentLauncher from "expo-intent-launcher";
import Dialog from "../general/dialog";

class Buttons extends Component {
  state = {
    anularLoading: false,
    autorizarLoading: false,
    ticketLoading: false,
    disabled: false,
    pm: this.props.pm,
    showAnular: false,
    showAutorizar: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.pm != this.props.pm) {
      this.setState({ pm: this.props.pm });
    }
  }

  async cambiarEstado(endpoint, estado, callback) {
    const colors = this.props.value.colors;
    const res = await fetch(this.props.value.proxy + "/pm/misPM/" + endpoint, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idPM: this.state.pm.idpm,
      }),
    });

    if (res.status == 200) {
      const mensaje = `PM ${estado}.`;
      Toast.show(mensaje, {
        duration: Toast.durations.LONG,
        backgroundColor: colors.success,
        textColor: colors.text2,
      });
      const newPM = { ...this.state.pm, estado_actual: estado };
      this.props.updatePM(newPM);
    } else {
      let error;
      if (res.status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        switch (res.status) {
          case 403:
            error = "Permisos insuficientes.";
            break;
          case 404:
            error = "No se encontró el PM.";
            break;
          case 406:
            error = "Error de conexión.";
            break;
          default:
            error = "Error de servidor.";
            break;
        }

        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        callback();
      }
    }
  }

  showAnularDialog() {
    this.setState({ showAnular: true });
  }

  anular() {
    this.setState(
      {
        disabled: true,
        anularLoading: true,
      },
      () => {
        this.cambiarEstado("anular", "anulado", () => {
          this.setState({ disabled: false, anularLoading: false });
        });
      }
    );
  }

  showAutorizarDialog() {
    this.setState({ showAutorizar: true });
  }

  autorizar() {
    this.setState(
      {
        disabled: true,
        autorizarLoading: true,
      },
      () => {
        this.cambiarEstado("autorizar", "autorizado", () => {
          this.setState({ disabled: false, anularLoading: false });
        });
      }
    );
  }

  async obtenerTicket(callback) {
    this.setState(
      {
        disabled: true,
        ticketLoading: true,
      },
      async () => {
        const colors = this.props.value.colors;

        const res = await fetch(
          this.props.value.proxy + `/pm/ticket?idPM=${this.state.pm.idpm}`
        );

        if (res.status == 200) {
          let json = await res.json();
          const fecha = new Date();
          fecha.setHours(fecha.getHours() - 3);
          const strFecha = fecha
            .toISOString()
            .replace("T", " ")
            .replace("Z", "");

          const newEstados =
            this.state.pm.estado_actual.toUpperCase() === "AUTORIZADO"
              ? [
                  {
                    estado: "IMPRESIÓN",
                    usuario: "Yo",
                    fecha: strFecha,
                  },
                  {
                    estado: "PENDIENTE DE PAGO",
                    usuario: "Yo",
                    fecha: strFecha,
                  },
                ]
              : [
                  {
                    estado: "IMPRESIÓN",
                    usuario: "Yo",
                    fecha: strFecha,
                  },
                ];
          const newPM = {
            ...this.state.pm,
            estados: [...newEstados, ...this.state.pm.estados],
          };
          this.props.updatePM(newPM);
          callback(json.base64);
        } else {
          let error;
          if (res.status == 401) {
            error = "Sesión expirada. Por favor ingrese nuevamente.";
            Toast.show(error, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.danger,
              textColor: colors.text2,
            });
            await fetch(this.props.value.proxy + "/accounts/logout", {
              method: "POST",
            });
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: "Login" }],
            });
          } else {
            switch (res.status) {
              case 403:
                error = "Permisos insuficientes.";
                break;
              case 404:
                error = "No se encontró el PM.";
                break;
              case 406:
                error = "Error de conexión.";
                break;
              default:
                error = "Error de servidor.";
                break;
            }

            Toast.show(error, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.danger,
              textColor: colors.text2,
            });
            this.setState({ disabled: false, ticketLoading: false });
          }
        }
      }
    );
  }

  // TODO: iOS
  async descargarTicket() {
    const descargar = async (ticket) => {
      if (Platform.OS === "web") {
        const link = document.createElement("a");
        const byteCharacters = atob(ticket);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], {
          type: "application/pdf",
        });
        /*
        link.href = URL.createObjectURL(file);
        link.download = `ticket_pm_${this.state.pm.idpm}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
        */
        window.open(URL.createObjectURL(file));
        this.setState({ disabled: false, ticketLoading: false });
      } else {
        const fileUri =
          FileSystem.cacheDirectory +
          `ticket_pm_temp_${this.state.pm.idpm}.pdf`;
        const info = await FileSystem.getInfoAsync(fileUri);
        if (!info.exists) {
          await FileSystem.writeAsStringAsync(fileUri, ticket, {
            encoding: FileSystem.EncodingType.Base64,
          });
        }

        const contentUri = await FileSystem.getContentUriAsync(fileUri);
        await IntentLauncher.startActivityAsync("android.intent.action.VIEW", {
          data: contentUri,
          flags: 1,
          type: "application/pdf",
        });
        this.setState({ disabled: false, ticketLoading: false });
        FileSystem.deleteAsync(fileUri);
      }
    };

    this.obtenerTicket(descargar);
  }

  /*
  // TODO: Android / iOS
  async imprimirTicket() {
    const imprimir = async (ticket) => {
      if (Platform.OS === "web") {
        const byteCharacters = atob(ticket);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const file = new Blob([byteArray], {
          type: "application/pdf",
        });

        const iframe = document.createElement("iframe");
        iframe.src = URL.createObjectURL(file);

        iframe.name = "pdf";
        iframe.hidden = true;

        iframe.onload = () => {
          const pdfFrame = window.frames["pdf"];
          pdfFrame.focus();
          pdfFrame.print();
        };
        document.body.appendChild(iframe);

        this.setState({ disabled: false, ticketLoading: false });
      } else {
        // TODO
      }
    };

    this.obtenerTicket(imprimir);
  }
  */

  render() {
    const colors = this.props.value.colors;
    const pm = this.props.pm;
    const pendienteDePago =
      pm.estado_actual.toUpperCase() === "PENDIENTE DE PAGO";
    const styles = StyleSheet.create({
      container: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: colors.field,
      },
    });
    return (
      <>
        <Dialog
          show={this.state.showAnular}
          hide={() => this.setState({ showAnular: false })}
          text="¿Desea anular el PM?"
          button1={{
            text: "No",
            color1: colors.field,
            color2: colors.text3,
          }}
          button2={{
            text: "Si",
            color1: colors.danger,
            color2: colors.text2,
            onPress: () => {
              this.anular();
            },
          }}
        />
        <Dialog
          show={this.state.showAutorizar}
          hide={() => this.setState({ showAutorizar: false })}
          text="¿Desea autorizar el PM?"
          button1={{
            text: "No",
            color1: colors.field,
            color2: colors.text3,
          }}
          button2={{
            text: "Si",
            color1: colors.success,
            color2: colors.text2,
            onPress: () => {
              this.autorizar();
            },
          }}
        />
        {pm.estado_actual === "pendiente" ? (
          <View style={styles.container}>
            <Button
              text="Anular"
              accent={colors.field}
              background="danger"
              color="dark"
              onPress={() => {
                this.showAnularDialog();
              }}
              loading={this.state.anularLoading}
              disabled={this.state.disabled}
            />
            {this.props.value.userData.rol.toUpperCase() === "SUPERVISOR" && (
              <Button
                text="Autorizar"
                accent={colors.field}
                background="success"
                color="dark"
                onPress={() => {
                  this.showAutorizarDialog();
                }}
                loading={this.state.autorizarLoading}
                disabled={this.state.disabled}
              />
            )}
          </View>
        ) : pm.estado_actual === "autorizado" || pendienteDePago ? (
          <View style={styles.container}>
            {this.props.value.userData.rol.toUpperCase() === "SUPERVISOR" ? (
              <>
                <Button
                  text="Anular"
                  accent={colors.field}
                  background="danger"
                  color="dark"
                  onPress={() => {
                    this.showAnularDialog();
                  }}
                  loading={this.state.anularLoading}
                  disabled={this.state.disabled}
                />
                <Button
                  text={pendienteDePago ? "Reimprimir" : "Imprimir"}
                  accent={colors.field}
                  background="info"
                  color="dark"
                  onPress={() => {
                    this.descargarTicket();
                  }}
                  loading={this.state.ticketLoading}
                  disabled={this.state.disabled}
                />
              </>
            ) : (
              !pendienteDePago && (
                <Button
                  text="Imprimir"
                  accent={colors.field}
                  background="info"
                  color="dark"
                  onPress={() => {
                    this.descargarTicket();
                  }}
                  loading={this.state.ticketLoading}
                  disabled={this.state.disabled}
                />
              )
            )}
          </View>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default withGeneralState(withNavigation(withTheme(Buttons)));
