import React, { Component } from "react";
import {
  View,
  StyleSheet,
  ActivityIndicator,
  TouchableWithoutFeedback,
  Dimensions,
  Text,
  TouchableHighlight,
} from "react-native";
import Menu from "../../general/menuGenerico/menu";
import CardCanje from "./cardCanje";
import BasicCard from "../../general/menuGenerico/basicCard";
import DropDownPicker from "react-native-dropdown-picker";
import withTheme from "../../general/withTheme";
import withGeneralState from "../../general/withGeneralState";
import RenderBadgeItem from "../../general/RenderBadgeItem";
import IcoBack from "../../../assets/icons/back.svg";
import Toast from "react-native-root-toast";

class VistaCanjes extends Component {
  state = {
    canjes: [],
    dropDownFiltrosOpen: false,
    itemsFiltros: [],
    oldValueFiltros: [],
    valueFiltros:
      this.props.value.userData.rol.toUpperCase() === "SUPERVISOR"
        ? ["propios:", "dias:1"]
        : ["dias:1"],
    dropDownPromocionesOpen: false,
    itemsPromociones: [],
    valuePromociones: [],
    loading: false,
    styles: {},
    colors: {},
    search: "",
    searching: false,
    currentPage: 1,
    maxPage: 1,
    changedFiltros: false,
    changedPromociones: false,
  };

  constructor(props) {
    super(props);
    this.setOpenFiltros = this.setOpenFiltros.bind(this);
    this.setValueFiltros = this.setValueFiltros.bind(this);
    this.setItemsFiltros = this.setItemsFiltros.bind(this);
    this.setOpenPromociones = this.setOpenPromociones.bind(this);
    this.setValuePromociones = this.setValuePromociones.bind(this);
    this.setItemsPromociones = this.setItemsPromociones.bind(this);
  }

  componentDidMount() {
    const idpromocion = this.props.route.params?.idpromocion;
    if (idpromocion) {
      const filtros =
        this.props.value.userData.rol.toUpperCase() === "SUPERVISOR"
          ? ["todos:"]
          : ["todos:"];
      this.setState(
        {
          valuePromociones: ["promo:" + idpromocion],
          valueFiltros: filtros,
        },
        () => {
          this.updateTheme();
          this.refresh(false, false);
        }
      );
    } else {
      this.updateTheme();
      this.refresh(false, true);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.value.theme != prevProps.value.theme) {
      this.updateTheme();
    }
  }

  updateTheme() {
    const { colors } = this.props.value;

    const badgeColors = {
      "dias:1": colors.success,
      "dias:10": colors.info,
      "dias:30": colors.warning,
      propios: colors.field,
      default: colors.placeholder,
    };

    const styles = StyleSheet.create({
      selectContainer1: {
        width: "100%",
        marginTop: 7,
        marginBottom: 7,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 53,
      },
      select1: {
        alignSelf: "center",
        backgroundColor: colors.card,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        zIndex: 52,
      },
      selectContainer2: {
        width: "100%",
        marginTop: 7,
        marginBottom: 7,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 51,
      },
      select2: {
        alignSelf: "center",
        backgroundColor: colors.card,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        zIndex: 50,
      },
      badgeText: {
        fontSize: 18,
        fontWeight: "500",
        color: colors.text2,
        textAlign: "center",
      },
      label: {
        fontSize: 17,
        fontWeight: "450",
        color: colors.text3,
        textAlign: "left",
      },
      drop: {
        alignSelf: "center",
        backgroundColor: colors.field,
        borderRadius: 5,
        borderWidth: 0,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        paddingHorizontal: 20,
        maxHeight: 500,
        zIndex: 50,
      },
      pageSection: {
        flexDirection: "row",
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        marginTop: 50,
        marginBottom: 50,
        borderRadius: 5,
        height: 45,
      },
      pageButton: {
        alignSelf: "center",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: colors.field,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderWidth: 0,
        paddingVertical: 5,
        width: 35,
        height: 45,
      },
      pageText: {
        alignSelf: "center",
        backgroundColor: colors.card,
        color: colors.text3,
        fontSize: 25,
        borderRadius: 0,
        borderWidth: 0,
        paddingHorizontal: 10,
        paddingVertical: 5,
        height: 45,
      },
    });

    const itemsFiltros = [
      {
        label: "Último día",
        value: "dias:1",
        labelStyle: styles.label,
      },
      {
        label: "Últimos 10 días",
        value: "dias:10",
        labelStyle: styles.label,
      },
      {
        label: "Últimos 30 días",
        value: "dias:30",
        labelStyle: styles.label,
      },
      {
        label: "Propios",
        value: "propios:",
        labelStyle: styles.label,
        disabled: this.props.value.userData.rol.toUpperCase() !== "SUPERVISOR",
      },
      {
        label: "Todos",
        value: "todos:",
        labelStyle: styles.label,
        disabled: true,
      },
    ];

    const itemsPromociones = this.state.itemsPromociones.map((p) => {
      return {
        ...p,
        labelStyle: styles.label,
      };
    });

    this.setState({
      colors: colors,
      badgeColors: badgeColors,
      styles: styles,
      itemsFiltros: itemsFiltros,
      itemsPromociones: itemsPromociones,
    });
  }

  setOpenFiltros(open) {
    this.setState({
      dropDownFiltrosOpen: open,
    });
  }

  setValueFiltros(callback) {
    this.setState((state) => ({
      oldValueFiltros: this.state.valueFiltros,
      valueFiltros: callback(state.valueFiltros),
      changedFiltros: true,
    }));
  }

  setItemsFiltros(callback) {
    this.setState((state) => ({
      itemsFiltros: callback(state.itemsFiltros),
      changedFiltros: true,
    }));
  }

  onCloseFiltros() {
    let { valueFiltros, search, searching, itemsFiltros } = this.state;
    if (searching && search.trim().length > 0) {
      let newItems = [...itemsFiltros];
      let newValue = [...valueFiltros];
      let indexBusqueda = newItems.findIndex((i) => i.busqueda == true);
      if (indexBusqueda != -1) {
        newValue = newValue.filter((v) => v != newItems[indexBusqueda].value);
        newItems.splice(indexBusqueda, 1);
      }
      newItems.push({
        label: search,
        value: "busqueda:" + search,
        busqueda: true,
      });
      newValue = newValue.filter((v) => v !== "todos");
      newValue.push("busqueda:" + search);
      this.setState(
        { searching: false, itemsFiltros: newItems, valueFiltros: newValue },
        () => {
          this.refresh(false);
        }
      );
    } else {
      if (valueFiltros.length == 0) {
        this.setState(
          {
            searching: false,
            valueFiltros: ["todos"],
          },
          () => {
            this.refresh();
          }
        );
      } else {
        if (this.state.changedFiltros) {
          this.setState(
            {
              searching: false,
            },
            () => {
              this.refresh();
            }
          );
        }
      }
    }
  }

  setOpenPromociones(open) {
    this.setState({
      dropDownPromocionesOpen: open,
    });
  }

  setValuePromociones(callback) {
    this.setState((state) => ({
      valuePromociones: callback(state.valuePromociones),
      changedPromociones: true,
    }));
  }

  setItemsPromociones(callback) {
    this.setState((state) => ({
      itemsPromociones: callback(state.itemsPromociones),
      changedPromociones: true,
    }));
  }

  onClosePromociones() {
    let { valuePromociones } = this.state;

    if (valuePromociones.length == 0) {
      this.setState({ valuePromociones: [] }, () => {
        this.refresh(true);
      });
    } else {
      if (this.state.changedPromociones) {
        this.refresh(true);
      }
    }
  }

  // Cantidad de canjes por página
  limit = 10;

  refresh(cambioPromo, fallback) {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          canjes: [],

          dropDownFiltrosOpen: false,
          changedFiltros: false,

          dropDownPromocionesOpen: false,
          changedPromociones: false,
        },
        async () => {
          const colors = this.props.value.colors;
          try {
            let filtros = [...this.state.valueFiltros];
            const indexTodos = filtros.findIndex((f) => f === "todos:");
            if (indexTodos != -1) {
              filtros.splice(indexTodos, 1);
            }

            const indexPropios = filtros.findIndex((f) => f === "propios:");
            let propios = false;
            if (indexPropios != -1) {
              propios = true;
              filtros.splice(indexPropios, 1);
            }

            let dias = 1;
            const filtroDias = filtros.find((f) => f.includes("dias"));
            if (filtroDias) {
              dias = Number(filtroDias.split(":")[1]);
            }

            let itemsPromociones, valuePromociones;
            if (cambioPromo) {
              ({ itemsPromociones, valuePromociones } = this.state);
            } else {
              const resPromociones = await fetch(
                this.props.value.proxy + `/promoTKTs/promociones?dias=${dias}`
              );
              if (resPromociones.status != 200) {
                throw new Error(res.status.toString());
              }

              const promociones = await resPromociones.json();
              const styleLabel = {
                fontSize: 17,
                fontWeight: "450",
                color: colors.text3,
                textAlign: "left",
              };
              itemsPromociones = promociones.map((p) => {
                return {
                  label: p.nombre,
                  value: "promo:" + p.idpromocion,
                  labelStyle: styleLabel,
                };
              });
              valuePromociones = this.state.valuePromociones.filter((v) => {
                const id = Number(v.split(":")[1]);
                const index = promociones.findIndex((p) => p.idpromocion == id);
                return index != -1;
              });
            }

            filtros = [...filtros, ...valuePromociones];

            let resCanjes = await fetch(
              this.props.value.proxy +
                `/promoTKTs/canjes?filtros=${JSON.stringify(filtros)}&limit=${
                  this.limit
                }&page=${this.state.currentPage}&propios=${propios}`
            );
            if (resCanjes.status != 200) {
              throw new Error(resCanjes.status.toString());
            }
            let { canjes, pages } = await resCanjes.json();

            if (canjes.length == 0 && fallback) {
              resCanjes = await fetch(
                this.props.value.proxy +
                  `/promoTKTs/canjes?limit=${this.limit}&propios=${propios}`
              );
              if (resCanjes.status != 200) {
                throw new Error(resCanjes.status.toString());
              }
              ({ canjes, pages } = await resCanjes.json());
              filtros = propios ? ["propios:"] : ["todos:"];
            } else {
              filtros = [...this.state.valueFiltros];
            }

            this.setState({
              valueFiltros: filtros,
              itemsPromociones: itemsPromociones,
              valuePromociones: valuePromociones,
              canjes: canjes,
              maxPage: pages,
              loading: false,
            });
          } catch (e) {
            console.log(e);
            this.error(Number(e.message));
          }
        }
      );
    }
  }

  async error(status) {
    {
      const colors = this.props.value.colors;
      let error;
      if (status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        if (status == 400) {
          error = "Sala no seleccionada.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Salas" }],
          });
        } else {
          switch (status) {
            case 403:
              error = "Permisos insuficientes.";
              break;
            case 406:
              error = "Error de conexión.";
              break;
            default:
              error = "Error de servidor.";
              break;
          }

          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.setState({
            loading: false,
          });
        }
      }
    }
  }

  prevPage() {
    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.refresh(true);
    });
  }

  nextPage() {
    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.refresh(true);
    });
  }

  updateCanje(newCanje) {
    let index = this.state.canjes.findIndex(
      (c) => c.idcanje == newCanje.idcanje
    );
    let canjes = [...this.state.canjes];
    canjes[index] = newCanje;
    this.setState({ canjes: canjes });
  }

  render() {
    const {
      colors,
      badgeColors,
      styles,
      itemsPromociones,
      valuePromociones,
      dropDownPromocionesOpen,
      itemsFiltros,
      valueFiltros,
      dropDownFiltrosOpen,
      canjes,
      loading,
      currentPage,
      maxPage,
    } = this.state;
    return (
      <>
        <Menu
          title={"Canjes"}
          navigation={this.props.navigation}
          refreshing={false}
          onRefresh={() => {
            this.refresh(true);
          }}
        >
          {this.state.dropDownFiltrosOpen && (
            <TouchableWithoutFeedback
              style={{
                width: "100%",
                height: Dimensions.get("window").height,
                position: "absolute",
                zIndex: 52,
              }}
              onPress={() => {
                this.setOpenFiltros(false);
                this.onCloseFiltros();
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: Dimensions.get("window").height,
                  position: "absolute",
                  zIndex: 52,
                }}
              />
            </TouchableWithoutFeedback>
          )}
          {this.state.dropDownPromocionesOpen && (
            <TouchableWithoutFeedback
              style={{
                width: "100%",
                height: Dimensions.get("window").height,
                position: "absolute",
                zIndex: 50,
              }}
              onPress={() => {
                this.setOpenPromociones(false);
                this.onClosePromociones();
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: Dimensions.get("window").height,
                  position: "absolute",
                  zIndex: 50,
                }}
              />
            </TouchableWithoutFeedback>
          )}

          <View style={[styles.selectContainer1]}>
            <DropDownPicker
              style={[
                styles.select1,
                {
                  //width: value.length == 0 ? 230 : 230 + (value.length - 1) * 120,
                  //maxWidth: Dimensions.get("window").width * 0.9,
                  width: "90%",
                },
              ]}
              renderBadgeItem={RenderBadgeItem}
              dropDownContainerStyle={[
                styles.drop,
                {
                  //width: value.length == 0 ? 230 : 230 + (value.length - 1) * 120,
                  //maxWidth: Dimensions.get("window").width * 0.9,
                  width: "90%",
                },
              ]}
              disabledItemContainerStyle={{ display: "none" }}
              open={dropDownFiltrosOpen}
              setOpen={this.setOpenFiltros}
              items={itemsFiltros}
              setItems={this.setItemsFiltros}
              value={valueFiltros}
              setValue={this.setValueFiltros}
              theme={colors.theme}
              multiple={true}
              onChangeValue={(value) => {
                if (value.length == 0) {
                  this.setState({ valueFiltros: ["todos:"] });
                } else {
                  if (value.length > 1) {
                    let newValue = [...value];
                    let change = false;
                    let indexTodos = value.findIndex((v) => v === "todos:");
                    if (indexTodos != -1) {
                      newValue.splice(indexTodos, 1);
                      change = true;
                    }
                    let newDias = value.filter((v) => {
                      const split = v.split(":");
                      const type = split[0];
                      const number = Number(split[1]);
                      return (
                        type === "dias" &&
                        !isNaN(number) &&
                        this.state.oldValueFiltros.findIndex(
                          (v2) => v2 === v
                        ) === -1
                      );
                    });
                    if (newDias.length > 0) {
                      //Se sumó un filtro de días
                      newValue = newValue.filter((v) => {
                        const split = v.split(":");
                        const type = split[0];
                        return type !== "dias" || v === newDias[0];
                      });
                      change = true;
                    }
                    if (change) {
                      this.setState({
                        oldValueFiltros: value,
                        valueFiltros: newValue,
                      });
                    }
                  }
                }
              }}
              onClose={() => this.onCloseFiltros()}
              placeholder=""
              mode="BADGE"
              extendableBadgeContainer={true}
              showBadgeDot={false}
              showTickIcon={true}
              badgeProps={{ badgeColors: badgeColors }}
              badgeTextStyle={styles.badgeText}
              listMode="SCROLLVIEW"
              searchable={true}
              disableLocalSearch={true}
              addCustomItem={true}
              onChangeSearchText={(text) => {
                this.setState({
                  changedFiltros: true,
                  searching: true,
                  search: text,
                });
              }}
              searchTextInputProps={{
                onSubmitEditing: () => {
                  this.setOpenFiltros(false);
                  this.onCloseFiltros();
                },
              }}
              searchPlaceholder="Búsqueda"
            />
          </View>

          <View style={[styles.selectContainer2]}>
            <DropDownPicker
              style={[
                styles.select2,
                {
                  //width: value.length == 0 ? 230 : 230 + (value.length - 1) * 120,
                  //maxWidth: Dimensions.get("window").width * 0.9,
                  width: "90%",
                },
              ]}
              renderBadgeItem={RenderBadgeItem}
              dropDownContainerStyle={[
                styles.drop,
                {
                  //width: value.length == 0 ? 230 : 230 + (value.length - 1) * 120,
                  //maxWidth: Dimensions.get("window").width * 0.9,
                  width: "90%",
                },
              ]}
              disabledItemContainerStyle={{ display: "none" }}
              open={dropDownPromocionesOpen}
              setOpen={this.setOpenPromociones}
              items={itemsPromociones}
              setItems={this.setItemsPromociones}
              value={valuePromociones}
              setValue={this.setValuePromociones}
              theme={colors.theme}
              multiple={true}
              onChangeValue={(value) => {
                this.setState({
                  valuePromociones: value,
                });
              }}
              onClose={() => this.onClosePromociones()}
              placeholder="Promociones"
              mode="BADGE"
              extendableBadgeContainer={true}
              showBadgeDot={false}
              showTickIcon={true}
              badgeProps={{ badgeColors: badgeColors }}
              badgeTextStyle={styles.badgeText}
              listMode="SCROLLVIEW"
              searchable={false}
            />
          </View>

          {loading ? (
            <ActivityIndicator
              size="large"
              color={colors.accent2}
              style={{ width: "100%", marginTop: 30 }}
            />
          ) : (
            <>
              {canjes.length > 0 ? (
                canjes.map((c) => (
                  <CardCanje
                    key={c.idcanje}
                    canje={c}
                    updateCanje={(newCanje) => {
                      this.updateCanje(newCanje);
                    }}
                  />
                ))
              ) : (
                <BasicCard title="No hay canjes para mostrar." />
              )}
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View style={styles.pageSection}>
                  <TouchableHighlight
                    disabled={!(1 < currentPage)}
                    underlayColor={colors.card}
                    onPress={() => {
                      this.prevPage();
                    }}
                  >
                    <View style={styles.pageButton}>
                      {1 < currentPage && (
                        <IcoBack stroke={colors.text3} width={20} height={20} />
                      )}
                    </View>
                  </TouchableHighlight>
                  <Text style={styles.pageText}>
                    {currentPage} / {maxPage}
                  </Text>
                  <TouchableHighlight
                    disabled={!(currentPage < maxPage)}
                    underlayColor={colors.card}
                    onPress={() => {
                      this.nextPage();
                    }}
                  >
                    <View
                      style={[
                        styles.pageButton,
                        {
                          transform: [
                            {
                              rotate: "180deg",
                            },
                          ],
                        },
                      ]}
                    >
                      {currentPage < maxPage && (
                        <IcoBack stroke={colors.text3} width={20} height={20} />
                      )}
                    </View>
                  </TouchableHighlight>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 100,
                }}
              />
            </>
          )}
        </Menu>
      </>
    );
  }
}

export default withGeneralState(withTheme(VistaCanjes));
