import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Keyboard,
  TouchableHighlight,
} from "react-native";
import Toast from "react-native-root-toast";
import withTheme from "../general/withTheme.jsx";
import withGeneralState from "../general/withGeneralState.jsx";
import Logo from "../../assets/icons/neogame.svg";

class Login extends Component {
  state = {
    user: "",
    validUser: false,
    pass: "",
    validPass: false,
    disableButton: true,
    loading: true,
  };

  async componentDidMount() {
    const res = await fetch(this.props.value.proxy + "/accounts/getUserData");
    if (res.status == 200) {
      const userData = await res.json();
      this.props.value.setUserData(userData, () => {
        let routes = [{ name: "Salas" }];
        if (userData.idSala) {
          routes.push({
            name: "Opciones",
          });
          if (userData.secciones && userData.secciones.length == 1) {
            switch (userData.secciones[0]) {
              case "PAGOS MANUALES":
                routes.push({
                  name: "PagosManuales",
                });
                break;
              case "PROMOTKTS":
                routes.push({
                  name: "PromoTKTs",
                });
                break;
            }
          }
        }
        this.props.navigation.reset({
          index: 0,
          routes: routes,
        });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  async login() {
    Keyboard.dismiss();
    const colors = this.props.value.colors;

    this.setState({ disableButton: true, loading: true }, async () => {
      const res = await fetch(this.props.value.proxy + "/accounts/login", {
        method: "POST",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: this.state.user,
          pass: this.state.pass,
        }),
      });

      if (res.status == 200) {
        const userData = await res.json();
        this.props.value.setUserData(userData, () => {
          let routes = [{ name: "Salas" }];
          if (userData.idSala) {
            routes.push({
              name: "Opciones",
            });
            if (userData.secciones && userData.secciones.length == 1) {
              switch (userData.secciones[0]) {
                case "PAGOS MANUALES":
                  routes.push({
                    name: "PagosManuales",
                  });
                  break;
                case "PROMOTKTS":
                  routes.push({
                    name: "PromoTKTs",
                  });
                  break;
              }
            }
          }
          this.props.navigation.reset({
            index: 0,
            routes: routes,
          });
        });
      } else {
        let error;
        switch (res.status) {
          case 400:
            error = "Solicitud incorrecta.";
            break;
          case 401:
            error = "Usuario y/o contraseña incorrectos.";
            break;
          case 403:
            error = "El usuario está inactivo.";
            break;
          case 406:
            error = "Error de conexión.";
            break;
          default:
            error = "Error de servidor.";
            break;
        }

        let toast = Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });

        this.setState({
          disableButton: !this.state.validPass || !this.state.validUser,
          loading: false,
        });
      }
    });
  }

  async forgot() {
    Keyboard.dismiss();
    const colors = this.props.value.colors;

    this.setState({ disableButton: true, loading: true }, async () => {
      const res = await fetch(this.props.value.proxy + "/accounts/forgotSend", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: this.state.user,
        }),
      });

      if (res.status == 200) {
        this.props.navigation.navigate("OlvidoPass", {
          user: this.state.user,
        });
      } else {
        let error;
        switch (res.status) {
          case 400:
            error = "Solicitud incorrecta.";
            break;
          case 409:
            error = "Usuario no existente.";
            break;
          default:
            error = "Error de servidor.";
            break;
        }

        let toast = Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
      }
      this.setState({
        disableButton: !this.state.validPass || !this.state.validUser,
        loading: false,
      });
    });
  }

  render() {
    const colors = this.props.value.colors;
    const styles = StyleSheet.create({
      background: {
        width: "100%",
        height: "100%",
        backgroundColor: colors.background,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 0,
      },
      logo: {
        marginTop: -100,
      },
      card: {
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        width: "90%",
        paddingHorizontal: 20,
        maxWidth: 320,
        height: 330,
        marginTop: 50,
      },
      field: {
        fontSize: 20,
        backgroundColor: colors.field,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        flex: 1,
        width: "100%",
        maxHeight: 50,
        marginTop: 35,
        paddingLeft: "5%",
        alignItems: "center",
        justifyItems: "center",
        color: colors.text3,
      },

      forgotButton: {
        fontSize: 15,
        marginTop: 20,
        marginRight: 10,
        textAlign: "right",
        color: colors.text3,
      },

      loginTouch: {
        marginTop: 35,
        width: "70%",
        alignSelf: "center",
        borderRadius: 5,
      },

      loginButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.disableButton
          ? colors.field
          : colors.accent1,
        padding: 7,
      },

      loginText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.disableButton ? colors.placeholder : colors.text1,
      },

      version: {
        fontSize: 15,
        textAlign: "center",
        color: colors.placeholder,
      },
    });

    return (
      <View style={styles.background}>
        <Logo
          width={300}
          height={66}
          fill={colors.accent1}
          stroke={colors.accent1}
        />
        <View style={styles.card}>
          <TextInput
            ref={(e) => (this.userRef = e)}
            style={styles.field}
            placeholder="Usuario"
            placeholderTextColor={colors.placeholder}
            selectionColor={colors.text}
            value={this.state.user}
            onChangeText={(text) => {
              let valid = text.length > 0 && !text.includes(" ");
              if (valid) {
                this.userRef.setNativeProps({
                  style: { borderColor: colors.border },
                });
              } else {
                this.userRef.setNativeProps({
                  style: { borderColor: "red" },
                });
              }
              this.setState({
                user: text,
                validUser: valid,
                disableButton: !(valid && this.state.validPass),
              });
            }}
            onFocus={() => {
              this.userRef.setNativeProps({
                style: {
                  backgroundColor: colors.focus,
                  color: colors.text4,
                },
              });
            }}
            onBlur={() => {
              this.userRef.setNativeProps({
                style: styles.field,
              });
            }}
            onSubmitEditing={() => this.passRef.focus()}
          />

          <TextInput
            ref={(e) => (this.passRef = e)}
            style={styles.field}
            placeholder="Contraseña"
            placeholderTextColor={colors.placeholder}
            selectionColor={colors.text}
            secureTextEntry={true}
            autoCapitalize="none"
            value={this.state.pass}
            onChangeText={(text) => {
              let valid = text.length > 0;
              if (valid) {
                this.passRef.setNativeProps({
                  style: { borderColor: colors.border },
                });
              } else {
                this.passRef.setNativeProps({
                  style: { borderColor: "red" },
                });
              }
              this.setState({
                pass: text,
                validPass: valid,
                disableButton: !(valid && this.state.validUser),
              });
            }}
            onFocus={() => {
              this.passRef.setNativeProps({
                style: {
                  backgroundColor: colors.focus,
                  color: colors.text4,
                },
              });
            }}
            onBlur={() => {
              this.passRef.setNativeProps({
                style: styles.field,
              });
            }}
            onSubmitEditing={() => this.login()}
          />
          <TouchableOpacity
            disabled={!this.state.validUser}
            onPress={() => this.forgot()}
          >
            <Text style={styles.forgotButton}>
              {this.state.validUser ? "Olvidé mi contraseña" : ""}
            </Text>
          </TouchableOpacity>

          <TouchableHighlight
            disabled={this.state.disableButton}
            style={styles.loginTouch}
            underlayColor={colors.field}
            onPress={() => this.login()}
          >
            <View style={styles.loginButton}>
              {this.state.loading ? (
                <ActivityIndicator size="large" color={colors.accent2} />
              ) : (
                <Text style={styles.loginText}>Ingresar</Text>
              )}
            </View>
          </TouchableHighlight>
        </View>
        <View style={(styles.background, { height: 100 })} />
        <Text style={styles.version}>v1.1.17</Text>
      </View>
    );
  }
}

export default withGeneralState(withTheme(Login));
