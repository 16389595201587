import React, { Component } from "react";
import withTheme from "../withTheme";
import { Text, TouchableHighlight, View } from "react-native";

const BasicCard = ({ title, onPress, value, backgroundColor, children }) => {
  const colors = value.colors;
  const styles = {
    card: {
      backgroundColor: backgroundColor ?? colors.card,
      borderRadius: 5,
      shadowColor: colors.shadow,
      shadowOffset: { width: 3, height: 3 },
      shadowOpacity: 0.2,
      shadowRadius: 15,
      elevation: 12,
      width: "93%",
      maxWidth: 370,
      minHeight: 100,
      justifyContent: "center",
      marginRight: 15,
      marginLeft: 15,
      marginTop: 15,
      marginBottom: 15,
    },
    text: {
      fontSize: 25,
      fontWeight: "500",
      padding: 15,
      textAlign: "left",
      color: backgroundColor ? colors.text2 : colors.text3,
    },
    container: {
      flexDirection: "row",
      flexWrap: "wrap",
      display: "flex",
      width: "100%",
      paddingHorizontal: 15,
      height: "auto",
      borderRadius: 5,
      borderRadius: 5,
      justifyContent: "space-between",
      backgroundColor: backgroundColor ?? colors.card,
    },
  };
  return (
    <TouchableHighlight
      style={styles.card}
      underlayColor={colors.field}
      onPress={
        onPress
          ? () => {
              onPress();
            }
          : null
      }
    >
      <>
        {!children || children.length == 0 ? (
          <Text style={styles.text}>{title}</Text>
        ) : (
          <View style={styles.container}>{children}</View>
        )}
      </>
    </TouchableHighlight>
  );
};

export default withTheme(BasicCard);
