import React, { Component } from "react";
import Button from "../../general/button";
import { View, StyleSheet } from "react-native";
import withTheme from "../../general/withTheme";
import withGeneralState from "../../general/withGeneralState";
import withNavigation from "../../general/withNavigation";

class Buttons extends Component {
  state = {
    canjearLoading: false,
    verCanjesLoading: false,
    disabled: false,
    promocion: this.props.promocion,
    accent: this.props.accent || this.props.value.colors.field,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.promocion != this.props.promocion) {
      this.setState({ promocion: this.props.promocion });
    }
    if (prevProps.accent != this.props.accent) {
      this.setState({ accent: this.props.accent });
    }
  }

  canjear() {
    this.setState(
      {
        disabled: true,
        canjearLoading: true,
      },
      () => {
        this.props.navigation.navigate("Canjear", {
          promocion: this.state.promocion,
        });
        this.setState({ disabled: false, canjearLoading: false });
      }
    );
  }

  verCanjes() {
    this.setState(
      {
        disabled: true,
        verCanjesLoading: true,
      },
      () => {
        this.props.navigation.navigate("Canjes", {
          idpromocion: this.state.promocion.idpromocion,
        });
        this.setState({ disabled: false, verCanjesLoading: false });
      }
    );
  }

  render() {
    const promocion = this.props.promocion;
    const accent = this.state.accent;
    const canjeable =
      promocion.anulador == null && promocion.cant_disponible > 0;
    const styles = StyleSheet.create({
      container: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: accent,
      },
    });
    return (
      <View style={styles.container}>
        <Button
          text="Ver canjes"
          accent={accent}
          background="text3"
          color="dark"
          onPress={() => {
            this.verCanjes();
          }}
          loading={this.state.verCanjesLoading}
          disabled={this.state.disabled}
        />
        {canjeable && (
          <Button
            text="Canjear"
            accent={accent}
            background="success"
            color="dark"
            onPress={() => {
              this.canjear();
            }}
            loading={this.state.canjearLoading}
            disabled={this.state.disabled}
          />
        )}
      </View>
    );
  }
}

export default withGeneralState(withNavigation(withTheme(Buttons)));
