import { createContext } from "react";
import { useColorScheme } from "react-native";

const ThemeContext = createContext();

const ThemeProvider = ({ children, value }) => {
  const lightTheme = !(useColorScheme() === "dark");
  const colors = {
    theme: lightTheme ? "LIGHT" : "DARK",
    accent1: lightTheme ? "#D02022" : "#D02022",
    accent2: lightTheme ? "#B21726" : "#E25D52",
    accent3: lightTheme ? "#D02022" : "#D02022",
    success: lightTheme ? "#648C06" : "#A6C73A",
    info: lightTheme ? "#0073AF" : "#38BEE0",
    warning: lightTheme ? "#D19700" : "#F8CE3E",
    danger: lightTheme ? "#B31F23" : "#E36E5B",
    background: lightTheme ? "#E9E9E9" : "#000000",
    card: lightTheme ? "#E0E0E0" : "#262626",
    staticField: lightTheme ? "#D3D3D3" : "#303030",
    field: lightTheme ? "#E6E6E6" : "#434343",
    focus: lightTheme ? "#FEF07F" : "#FEF07F",
    placeholder: lightTheme ? "#606060" : "#A9A9A9",
    text1: lightTheme ? "#F5F5F5" : "#F5F5F5",
    text2: lightTheme ? "#F5F5F5" : "#121212",
    text3: lightTheme ? "#121212" : "#F5F5F5",
    text4: lightTheme ? "#121212" : "#121212",
    border: lightTheme ? "#979797" : "#3F3F46",
    shadow: lightTheme ? "#3C3C3C" : "#000000",
    pendiente: lightTheme ? "#D19700" : "#F8CE3E",
    autorizado: lightTheme ? "#0073AF" : "#38BEE0",
    pendienteDePago: lightTheme ? "#0073DF" : "#38BEB0",
    anulado: lightTheme ? "#964305" : "#CA8030",
    pagado: lightTheme ? "#648C06" : "#A6C73A",
    rechazado: lightTheme ? "#B31F23" : "#E36E5B",
  };
  let newValue;
  if (typeof value !== "undefined") {
    newValue = { ...value, colors: colors };
  } else {
    newValue = { colors: colors };
  }
  return (
    <ThemeContext.Provider value={newValue}>{children}</ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
