import React, { Component } from "react";
import { View, Text, Modal, TouchableHighlight } from "react-native";
import withTheme from "./withTheme";

class Dialog extends Component {
  state = {
    show: this.props.show,
    b1c1: this.props.button1.color1,
    b1c2: this.props.button1.color2,
    b2c1: this.props.button2.color1,
    b2c2: this.props.button2.color2,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ show: this.props.show });
    }
    if (prevProps.button1.color1 !== this.props.button1.color1) {
      this.setState({ b1c1: this.props.button1.color1 });
    }
    if (prevProps.button1.color2 !== this.props.button1.color2) {
      this.setState({ b1c2: this.props.button1.color2 });
    }
    if (prevProps.button2.color1 !== this.props.button2.color1) {
      this.setState({ b2c1: this.props.button2.color1 });
    }
    if (prevProps.button2.color2 !== this.props.button2.color2) {
      this.setState({ b2c2: this.props.button2.color2 });
    }
  }

  render() {
    const colors = this.props.value.colors;
    const styles = {
      dialogBox: {
        width: "90%",
        maxWidth: 400,
        marginLeft: "auto",
        marginRight: "auto",
        paddingHorizontal: 25,
        paddingVertical: 15,
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        justifyContent: "center",
        alignItems: "center",
      },
      dialogText: {
        width: "100%",
        color: colors.text3,
        fontSize: 20,
        fontWeight: "550",
        marginBottom: 10,
        textAlign: "center",
      },
      buttons: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-evenly",
        backgroundColor: colors.card,
      },
      buttonContainer: {
        backgroundColor: colors.field,
        marginTop: 15,
        marginBottom: 10,
        width: "40%",
        height: 45,
      },
      button: {
        width: "100%",
        height: "100%",
        justifyContent: "center",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        padding: 7,
        zIndex: 1,
      },
      buttonText: {
        fontSize: 18,
        fontWeight: "500",
        textAlign: "center",
      },
    };
    return (
      <Modal
        visible={this.state.show}
        transparent={true}
        onRequestClose={() => {
          this.props.hide();
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <View style={styles.dialogBox}>
            <Text style={styles.dialogText}>{this.props.text}</Text>

            <View style={styles.buttons}>
              <TouchableHighlight
                style={styles.buttonContainer}
                underlayColor={colors.field}
                onPress={() => {
                  this.props.hide();
                  if (this.props.button1.onPress) {
                    this.props.button1.onPress();
                  }
                }}
              >
                <View
                  style={[styles.button, { backgroundColor: this.state.b1c1 }]}
                >
                  <Text style={[styles.buttonText, { color: this.state.b1c2 }]}>
                    {this.props.button1.text}
                  </Text>
                </View>
              </TouchableHighlight>
              <TouchableHighlight
                style={styles.buttonContainer}
                underlayColor={colors.field}
                onPress={() => {
                  this.props.hide();
                  if (this.props.button2.onPress) {
                    this.props.button2.onPress();
                  }
                }}
              >
                <View
                  style={[styles.button, { backgroundColor: this.state.b2c1 }]}
                >
                  <Text style={[styles.buttonText, { color: this.state.b2c2 }]}>
                    {this.props.button2.text}
                  </Text>
                </View>
              </TouchableHighlight>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

export default withTheme(Dialog);
