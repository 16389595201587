import React, { memo, useCallback, useMemo } from "react";

import { View, Text, TouchableOpacity, I18nManager } from "react-native";

const RTL_DIRECTION = (rtl, style) => {
  const newStyle = { ...style };

  if (rtl && !I18nManager.isRTL) {
    if (newStyle.hasOwnProperty("flexDirection")) {
      newStyle["flexDirection"] =
        newStyle["flexDirection"] === "row" ? "row-reverse" : "row";
    } else {
      newStyle["flexDirection"] = "row-reverse";
    }
  }

  return newStyle;
};

const RTL_STYLE = (rtl, style) => {
  const newStyle = { ...style };

  if (rtl && !I18nManager.isRTL) {
    Object.keys(style).map((key) => {
      if (STYLE_DIRECTION_KEYS.hasOwnProperty(key)) {
        newStyle[STYLE_DIRECTION_KEYS[key]] = newStyle[key];
        delete newStyle[key];
      } else {
        newStyle[key] = newStyle[key];
      }
    });
  }

  return newStyle;
};

function RenderBadge({
  rtl,
  label,
  props,
  value,
  textStyle,
  badgeStyle,
  badgeTextStyle,
  badgeDotStyle,
  getBadgeDotColor,
  showBadgeDot,
  onPress,
  THEME,
}) {
  /**
   * onPress.
   */
  const __onPress = useCallback(() => onPress(value), [onPress, value]);

  const getBadgeColor = (value) => {
    if (typeof props.badgeColors[value] === "undefined") {
      return props.badgeColors["default"];
    }
    return props.badgeColors[value];
  };

  /**
   * The badge style.
   * @returns {object}
   */
  const _badgeStyle = useMemo(
    () => [
      RTL_DIRECTION(rtl, THEME.badgeStyle),
      ...[badgeStyle].flat(),
      {
        backgroundColor: getBadgeColor(value),
      },
    ],
    [THEME, rtl, badgeStyle, getBadgeColor]
  );

  /**
   * The badge dot style.
   * @return {object}
   */
  const _badgeDotStyle = useMemo(
    () => [
      RTL_STYLE(rtl, THEME.badgeDotStyle),
      ...[badgeDotStyle].flat(),
      {
        backgroundColor: getBadgeDotColor(value),
      },
    ],
    [THEME, rtl, badgeDotStyle, getBadgeDotColor]
  );

  /**
   * The badge text style.
   * @returns {object}
   */
  const _badgeTextStyle = useMemo(
    () => [...[textStyle].flat(), ...[badgeTextStyle].flat()],
    [textStyle, badgeTextStyle]
  );

  return (
    <TouchableOpacity style={_badgeStyle} disabled={true} onPress={__onPress}>
      {showBadgeDot && <View style={_badgeDotStyle} />}
      <Text style={_badgeTextStyle}>{label}</Text>
    </TouchableOpacity>
  );
}

const areEqual = (nextProps, prevProps) => {
  if (nextProps.label !== prevProps.label) return false;
  if (nextProps.value !== prevProps.value) return false;
  if (nextProps.showBadgeDot !== prevProps.showBadgeDot) return false;
  if (nextProps.rtl !== prevProps.rtl) return false;
  if (nextProps.theme !== prevProps.theme) return false;

  return true;
};

export default memo(RenderBadge, areEqual);
