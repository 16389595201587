import React, { Component } from "react";
import { useContext } from "react";
import { ThemeContext } from "./theme";

const withTheme = (Component) => (props) => {
  let newProps = { ...props };
  if (typeof props.value !== "undefined") {
    newProps.value = { ...props.value, ...useContext(ThemeContext) };
  } else {
    newProps.value = { ...useContext(ThemeContext) };
  }
  return <Component {...newProps} />;
};

export default withTheme;
