import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";

const getCompressionRate = (size) => {
  const MBSize = size / Math.pow(1024, 2);
  if (Math.round(MBSize) === 0) return 1;
  if (Math.round(MBSize) === 1) return 0.9;
  if (Math.round(MBSize) === 2) return 0.8;
  if (Math.round(MBSize) === 3) return 0.7;
  if (Math.round(MBSize) === 4) return 0.6;
  if (Math.round(MBSize) >= 5) return 0.5;
  if (Math.round(MBSize) >= 10) return 0.4;
  if (Math.round(MBSize) >= 15) return 0.3;
  if (Math.round(MBSize) >= 20) return 0.2;
  if (Math.round(MBSize) >= 25) return 0.1;
};

const getCompressedImage = async (image, width, height) => {
  const blob = await (await fetch(image)).blob();

  const compress = getCompressionRate(blob.size);

  let newRes;
  if (width <= height) {
    newRes = { height: 1080 };
  } else {
    newRes = { width: 1920 };
  }

  const compressedImage = await ImageManipulator.manipulateAsync(
    image,
    [{ resize: newRes }],
    {
      compress,
      format: ImageManipulator.SaveFormat.JPEG,
      base64: true,
    }
  );

  return compressedImage.base64;
};

const getImage = async () => {
  let response = null;

  try {
    let permissionResult = await ImagePicker.getCameraPermissionsAsync();
    if (permissionResult.status !== "granted") {
      permissionResult = await ImagePicker.requestCameraPermissionsAsync();
    }
    if (permissionResult.status !== "granted") {
      throw new Error("No permission");
    }
    let result = (
      await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: false,
        quality: 1,
      })
    ).assets[0];

    if (!result.canceled) {
      response = await getCompressedImage(
        result.uri,
        result.width,
        result.height
      );
    }
  } catch (e) {
    console.log(e);
  }

  return response;
};

export { getImage };
