import React, { Component } from "react";
import { View } from "react-native";
import Menu from "../general/menuGenerico/menu.jsx";
import BasicCard from "../general/menuGenerico/basicCard.jsx";
import withGeneralState from "../general/withGeneralState.jsx";
import withTheme from "../general/withTheme";
import Toast from "react-native-root-toast";

class Salas extends Component {
  state = { salas: [] };

  async componentDidMount() {
    let res = await fetch(this.props.value.proxy + "/salas");
    if (res.status == 200) {
      let salas = await res.json();
      this.setState({ salas: salas });
    } else {
      const colors = this.props.value.colors;
      let error;
      if (res.status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        const toast = Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });

        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });

        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        switch (res.status) {
          case 403:
            error = "Permisos insuficientes.";
            break;
          case 406:
            error = "Error de conexión.";
            break;
          default:
            error = "Error de servidor.";
            break;
        }

        const toast = Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
      }
    }
  }

  render() {
    const navigation = this.props.navigation;
    const colors = this.props.value.colors;
    return (
      <Menu title="Salas" navigation={navigation}>
        {this.state.salas.map((s) => (
          <BasicCard
            title={s.nombre}
            onPress={async () => {
              let res = await fetch(this.props.value.proxy + "/salas", {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  idSala: s.id,
                  nombreSala: s.nombre,
                }),
              });

              let errorStatus;
              if (res.status == 200) {
                const resGetUserData = await fetch(
                  this.props.value.proxy + "/accounts/getUserData"
                );
                if (resGetUserData.status == 200) {
                  const userData = await resGetUserData.json();
                  let goTo = "Opciones";
                  if (userData.secciones && userData.secciones.length == 1) {
                    switch (userData.secciones[0]) {
                      case "PAGOS MANUALES":
                        goTo = "PagosManuales";
                        break;
                      case "PROMOTKTS":
                        goTo = "PromoTKTs";
                        break;
                    }
                  }
                  this.props.value.setUserData(userData, () => {
                    navigation.navigate(goTo);
                  });
                } else {
                  errorStatus = resGetUserData.status;
                }
              } else {
                errorStatus = res.status;
              }

              if (errorStatus) {
                let error;
                if (errorStatus == 401) {
                  error = "Sesión expirada. Por favor ingrese nuevamente.";
                  const toast = Toast.show(error, {
                    duration: Toast.durations.LONG,
                    backgroundColor: colors.danger,
                    textColor: colors.text2,
                  });
                  await fetch(this.props.value.proxy + "/accounts/logout", {
                    method: "POST",
                  });
                  navigation.reset({
                    index: 0,
                    routes: [{ name: "Login" }],
                  });
                } else {
                  switch (errorStatus) {
                    case 403:
                      error = "Permisos insuficientes.";
                      break;
                    case 406:
                      error = "Error de conexión.";
                      break;
                    default:
                      error = "Error de servidor.";
                      break;
                  }

                  const toast = Toast.show(error, {
                    duration: Toast.durations.LONG,
                    backgroundColor: colors.danger,
                    textColor: colors.text2,
                  });
                }
              }
            }}
          />
        ))}
        <View style={{ width: "100%", height: 100 }} />
      </Menu>
    );
  }
}

export default withGeneralState(withTheme(Salas));
