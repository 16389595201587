import React, { Component } from "react";
import withTheme from "../../general/withTheme";
import withGeneralState from "../../general/withGeneralState";
import { Text, View, StyleSheet } from "react-native";

import StringMask from "string-mask";
import Buttons from "./buttons";
import Card from "../../general/menuGenerico/card";

class CardPromocion extends Component {
  state = {
    promocion: this.props.promocion,
    updateCounter: 0,
    open: this.props.open,
  };

  constructor(props) {
    super(props);
    this.maskImporte = new StringMask("#.##0,00", { reverse: true });
    this.styles = {
      textTopLeft100: {
        width: "100%",
        paddingTop: 5,
        fontSize: 16,
        fontWeight: "700",
        textAlign: "left",
      },
      textTopLeft: {
        width: "35%",
        paddingTop: 5,
        fontSize: 16,
        fontWeight: "700",
        textAlign: "left",
      },
      textTopRight: {
        width: "65%",
        paddingTop: 5,
        fontSize: 16,
        fontWeight: "700",
        textAlign: "right",
      },
      textMiddle: {
        width: "100%",
        fontSize: 17,
        fontWeight: "500",
        zIndex: 0,
      },
      textMiddlePadded: {
        width: "100%",
        paddingLeft: 20,
        fontSize: 17,
        fontWeight: "450",
        zIndex: 0,
      },
      textMiddleLeft: {
        width: "45%",
        fontSize: 17,
        fontWeight: "500",
        textAlign: "left",
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 0,
      },
      textMiddleRight: {
        width: "55%",
        fontSize: 17,
        fontWeight: "450",
        textAlign: "right",
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 0,
      },
    };
    const promocion = this.props.promocion;
    const fecha1 = new Date(promocion.fecha_desde);
    const fecha2 = new Date(promocion.fecha_hasta);
    this.desde =
      ("00" + fecha1.getDate()).slice(-2) +
      "/" +
      ("00" + (fecha1.getMonth() + 1)).slice(-2);
    this.hasta =
      ("00" + fecha2.getDate()).slice(-2) +
      "/" +
      ("00" + (fecha2.getMonth() + 1)).slice(-2);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.promocion != this.props.promocion) {
      this.setState({ promocion: this.props.promocion });
    }
    if (this.state.open != this.props.open) {
      this.setState({ open: this.props.open });
    }
  }

  updatePromocion(newPromocion) {
    this.setState(
      { promocion: newPromocion, updateCounter: this.state.updateCounter + 1 },
      () => {
        this.props.updatePromocion(newPromocion);
      }
    );
  }

  render() {
    const promocion = this.state.promocion;
    const colors = this.props.value.colors;

    let accent;

    switch (promocion.disponibilidad) {
      case "disponible":
        accent = colors.info;
        break;
      case "sin stock":
        accent = colors.danger;
        break;
      default:
        accent = colors.placeholder;
        break;
    }

    const stylesColor = StyleSheet.create({
      textTop: {
        color: colors.text2,
      },
      textMiddle: {
        color: colors.text3,
      },
      fmButton: {
        borderColor: colors.border,
        backgroundColor: colors.info,
      },
    });

    return (
      <Card
        open={this.state.open}
        accent={accent}
        openCard={this.props.openCard}
        updateCounter={this.state.updateCounter}
      >
        {/*Top*/}
        <>
          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { width: "100%", fontSize: 19, fontWeight: "900" },
            ]}
          >
            {promocion.nombre}
          </Text>

          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { fontSize: 19, fontWeight: "900", width: "55%" },
            ]}
          >
            {promocion.cant_tkt +
              " x $" +
              this.maskImporte.apply(
                promocion.valor_tkt.toFixed(2).replace(".", "")
              )}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { fontSize: 19, fontWeight: "900", width: "45%" },
            ]}
          >
            {"$" +
              this.maskImporte.apply(
                promocion.valor_promocion.toFixed(2).replace(".", "")
              )}
          </Text>

          <Text
            style={[
              this.styles.textTopLeft,
              stylesColor.textTop,
              { fontSize: 18, fontWeight: "800", width: "50%" },
            ]}
          >
            {"Canjeadas: " + promocion.cant_canjeadas}
          </Text>
          <Text
            style={[
              this.styles.textTopRight,
              stylesColor.textTop,
              { fontSize: 18, fontWeight: "800", width: "50%" },
            ]}
          >
            {"Disponibles: " + promocion.cant_disponible}
          </Text>

          <Buttons
            accent={accent}
            promocion={promocion}
            updatePromocion={(newPromocion) => {
              this.updatePromocion(newPromocion);
            }}
          />

          <View style={{ width: "100%", height: 10 }} />
        </>
        {/*Middle*/}
        <>
          <View style={{ width: "100%", height: 10 }} />

          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Validez:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {this.desde + " a " + this.hasta}
          </Text>

          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Stock:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {promocion.stock}
          </Text>

          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Promos por día:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {promocion.cant_promo_dia}
          </Text>

          <Text style={[this.styles.textMiddle, stylesColor.textMiddle]}>
            Descripción:
          </Text>
          <Text style={[this.styles.textMiddlePadded, stylesColor.textMiddle]}>
            {promocion.descripcion}
          </Text>

          <Text style={[this.styles.textMiddleLeft, stylesColor.textMiddle]}>
            Validación:
          </Text>
          <Text style={[this.styles.textMiddleRight, stylesColor.textMiddle]}>
            {promocion.tipo_validacion}
          </Text>

          <View style={{ width: "100%", height: 10 }} />
        </>
      </Card>
    );
  }
}

export default withGeneralState(withTheme(CardPromocion));
