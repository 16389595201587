import React, { Component, useRef } from "react";
import withGeneralState from "../../general/withGeneralState";
import withTheme from "../../general/withTheme";
import { SafeAreaView } from "react-native-safe-area-context";
import {
  Dimensions,
  Text,
  StyleSheet,
  View,
  ScrollView,
  TouchableHighlight,
  Keyboard,
  Platform,
  Image,
  ActivityIndicator,
} from "react-native";
import InputOrText from "./InputOrText";
import { BarCodeScanner } from "expo-barcode-scanner";
import { BrowserPDF417Reader } from "@zxing/browser";
import QrScanner from "qr-scanner";
import { Camera, CameraType } from "expo-camera";
import { Modal } from "react-native";
import ImageViewer from "react-native-image-zoom-viewer";
import StringMask from "string-mask";
import IcoQR from "../../../assets/icons/qr.svg";
import IcoBarcode from "../../../assets/icons/barcode.svg";
import IcoCamera from "../../../assets/icons/camera.svg";
import IcoTrash from "../../../assets/icons/trash.svg";
import NavBar from "../../general/navbar";
import * as ImagePicker from "expo-image-picker";
import Toast from "react-native-root-toast";
import { DefaultTheme, MD3DarkTheme } from "react-native-paper";
import { DatePickerInput } from "react-native-paper-dates";
import Dialog from "../../general/dialog";
import * as ImageManager from "../../general/imageManager.js";

class Canje extends Component {
  state = {
    permissionCamera: false,
    showScannerQR: false,
    showScannerBarcodeDNI: false,
    showScannerBarcodeVoucher: false,
    datos: this.props.route.params.promocion || this.props.route.params.canje,
    dni: null,
    cantCanjesDNI: null,
    dniMasked: null,
    dniValid: false,
    nombre: null,
    nombreValid: false,
    apellido: null,
    apellidoValid: false,
    nacimiento: null,
    cantPromo: "1",
    cantPromoValid: true,
    foto: null,
    fotoLoading: false,
    qr: null,
    qrValid: false,
    voucher: null,
    voucherValid: false,
    obs: null,
    back: false,
    loading: false,
    fotoLoading: false,
    showFoto: null,
    showConfirm: false,
    showCancel: false,
    showRevoke: false,
    editable: this.props.route.params.editable,
    back: !this.props.route.params.editable,
  };

  constructor(props) {
    super(props);
    this.dataSourceCords = new Array();
  }

  componentDidMount() {
    if (this.props.route.params.editable) {
      if (this.props.route.params.promocion) {
        this.setState({ datos: this.props.route.params.promocion });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: this.props.value.userData.idSala
            ? [
                { name: "Salas" },
                {
                  name: "Opciones",
                },
                {
                  name: "PromoTKTs",
                },
                {
                  name: "Promociones",
                },
              ]
            : [{ name: "Salas" }],
        });
      }
    } else {
      const maskDNI = new StringMask("#.##0", { reverse: true });
      const canje = this.props.route.params.canje;
      if (canje) {
        this.setState({
          datos: canje,
          cantPromo: canje.cant_promo.toString(),
          dni: canje.dni_cli,
          dniMasked: maskDNI.apply(canje.dni_cli),
          nombre: canje.nombre_cli,
          apellido: canje.apellido_cli,
          nacimiento: new Date(canje.nacimiento_cli),
          foto: canje.validacion,
          voucher: canje.validacion,
          qr: canje.validacion,
          obs: canje.obs,
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: this.props.value.userData.idSala
            ? [
                { name: "Salas" },
                {
                  name: "Opciones",
                },
                {
                  name: "PromoTKTs",
                },
                {
                  name: "Canjes",
                },
              ]
            : [{ name: "Salas" }],
        });
      }
    }
    this.props.navigation.addListener("beforeRemove", (e) => {
      if (!this.state.back) {
        e.preventDefault();
      }
      if (this.state.showScannerQR) {
        this.setState({
          showScannerQR: false,
          back: !this.props.route.params.editable,
        });
        return;
      }
      if (this.state.showScannerBarcodeDNI) {
        this.setState({
          showScannerBarcodeDNI: false,
          back: !this.props.route.params.editable,
        });
        return;
      }
      if (this.state.showScannerBarcodeVoucher) {
        this.setState({
          showScannerBarcodeVoucher: false,
          back: !this.props.route.params.editable,
        });
        return;
      }
      if (this.state.showFoto) {
        this.setState({
          showFoto: null,
          back: !this.props.route.params.editable,
        });
        return;
      }
    });
  }

  enableBack(callback) {
    this.setState({ back: true }, callback);
  }

  async onPressQRButton() {
    if (Platform.OS !== "web") {
      let permissionCamera = this.state.permissionCamera;
      let showScannerQR = true;
      if (!permissionCamera) {
        let permission = await BarCodeScanner.requestPermissionsAsync();
        if (permission.status === "granted") {
          permissionCamera = true;
        } else {
          showScannerQR = false;
          const error = "Se necesitan permisos para usar la cámara.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: this.props.value.colors.danger,
            textColor: this.props.value.colors.text2,
          });
        }
      }
      this.setState({
        permissionCamera: permissionCamera,
        showScannerQR: showScannerQR,
        back: false,
      });
    } else {
      try {
        let result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          quality: 1,
        });
        if (!result.cancelled) {
          const data = await QrScanner.scanImage(result.uri);
          this.handleQRScanned({ undefined, data });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  async onPressBarcodeButton(tipo) {
    const isDNI = tipo === "DNI";
    if (Platform.OS !== "web") {
      let permissionCamera = this.state.permissionCamera;
      let showScannerBarcode = true;
      if (!permissionCamera) {
        let permission = await BarCodeScanner.requestPermissionsAsync();
        if (permission.status === "granted") {
          permissionCamera = true;
        } else {
          showScannerBarcode = false;
          const error = "Se necesitan permisos para usar la cámara.";
          Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: this.props.value.colors.danger,
            textColor: this.props.value.colors.text2,
          });
        }
      }
      if (isDNI) {
        this.setState({
          permissionCamera: permissionCamera,
          showScannerBarcodeDNI: showScannerBarcode,
          back: false,
        });
      } else {
        this.setState({
          permissionCamera: permissionCamera,
          showScannerBarcodeVoucher: showScannerBarcode,
          back: false,
        });
      }
    } else {
      try {
        let result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: false,
          quality: 1,
          base64: true,
        });
        if (!result.cancelled) {
          // Decoder de código de barra
          const codeReader = new BrowserPDF417Reader();
          const data = (await codeReader.decodeFromImageUrl(result.uri)).text;

          if (data) {
            if (isDNI) {
              this.handleBarcodeScannedDNI({ undefined, data });
            } else {
              this.handleBarcodeScannedVoucher({ undefined, data });
            }
          } else {
            Toast.show("No se detectó un código de barras válido", {
              duration: Toast.durations.LONG,
              backgroundColor: this.props.value.colors.danger,
              textColor: this.props.value.colors.text2,
            });
          }
        }
      } catch (e) {
        Toast.show("No se detectó un código de barras válido", {
          duration: Toast.durations.LONG,
          backgroundColor: this.props.value.colors.danger,
          textColor: this.props.value.colors.text2,
        });
      }
    }
  }

  async getDatosMaquina() {
    if (this.state.maquina && this.state.maquina.length > 0) {
      const colors = this.props.value.colors;

      let res = await fetch(
        this.props.value.proxy + `/pm/maquina?uid=${this.state.maquina}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status == 200) {
        const datos = await res.json();
        this.setState({
          maquinaValid: true,
          fabricante: datos.fabricante,
          juego: datos.juego,
        });
        this.maquinaRef.setNativeProps({
          style: { borderColor: colors.border },
        });
      } else {
        let error;
        if (res.status == 401) {
          error = "Sesión expirada. Por favor ingrese nuevamente.";
          const toast = Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          await fetch(this.props.value.proxy + "/accounts/logout", {
            method: "POST",
          });
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Login" }],
          });
        } else {
          switch (res.status) {
            case 403:
              error = "Permisos insuficientes.";
              break;
            case 404:
              error = "Máquina ingresada no válida.";
              break;
            case 406:
              error = "Error de conexión.";
              break;
            default:
              error = "Error de servidor.";
              break;
          }
          const toast = Toast.show(error, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.danger,
            textColor: colors.text2,
          });
          this.setState(
            {
              maquinaValid: false,
              fabricante: "-",
              juego: "-",
            },
            () => {
              this.maquinaRef.setNativeProps({
                style: { borderColor: "red" },
              });
            }
          );
        }
      }
    } else {
      this.setState(
        {
          maquinaValid: false,
          fabricante: "-",
          juego: "-",
        },
        () => {
          this.maquinaRef.setNativeProps({
            style: { borderColor: "red" },
          });
        }
      );
    }
  }

  async onPressFoto() {
    if (this.state.foto) {
      this.setState({ showFoto: this.state.foto });
    } else {
      this.setState({ fotoLoading: true }, async () => {
        const image = await ImageManager.getImage();
        this.setState({ foto: image, fotoLoading: false });
      });
    }
  }

  handleQRScanned({ type, data }) {
    let scanned = null;
    try {
      Number(data);
      scanned = data;
    } catch (e) {}
    this.setState(
      {
        back: !this.props.route.params.editable,
        showScannerQR: scanned == null,
        qr: scanned,
        qrValid: true,
      },
      () => this.checkValidacion(scanned)
    );
  }

  async getDatosCliente(dni) {
    let res = await fetch(
      this.props.value.proxy +
        `/promoTKTs/canjes/datosCliente?idPromocion=${this.state.datos.idpromocion}&dni=${dni}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status == 200) {
      return await res.json();
    } else {
      return { nombre: null, apellido: null, nacimiento: null, cantidad: 0 };
    }
  }

  async handleBarcodeScannedDNI({ type, data }) {
    const colors = this.props.value.colors;
    try {
      const split = data.split("@");

      // DNI
      const dniFormatter = new StringMask("#.##0", { reverse: true });
      let dni = -1;
      try {
        dni = Number(split[4]);
      } catch (e) {}
      const dniValid = dni != -1 && dni >= 1000000;
      let cantCanjesDNI = null;
      if (dniValid) {
        cantCanjesDNI = (await this.getDatosCliente(dni)).cant;
      }

      // NOMBRE
      const nombre = split[2];
      const nombreValid = nombre.length > 0;

      // APELLIDO
      const apellido = split[1];
      const apellidoValid = apellido.length > 0;

      // NACIMIENTO
      const nacimiento = split[6];
      const nacSplit = nacimiento.split("/");
      const nacimientoValid =
        nacimiento.length > 0 &&
        nacSplit.length == 3 &&
        0 < Number(nacSplit[0]) &&
        Number(nacSplit[0]) <= 31 &&
        0 < Number(nacSplit[1]) &&
        Number(nacSplit[1]) <= 12 &&
        1900 < Number(nacSplit[2]) &&
        Number(nacSplit[2]) <= new Date().getFullYear();

      let date = new Date(nacSplit[2] + "-" + nacSplit[1] + "-" + nacSplit[0]);
      //HUSO HORARIO
      date.setHours(date.getHours() + 3);

      if (isNaN(date.getTime())) {
        date = null;
      }

      // STATE
      this.setState(
        {
          back: !this.props.route.params.editable,
          showScannerBarcodeDNI: false,
          dni: dniValid ? dni : null,
          dniMasked: dniValid ? dniFormatter.apply(dni) : "-",
          dniValid: dniValid,
          nombre: nombre,
          nombreValid: nombreValid,
          apellido: apellido,
          apellidoValid: apellidoValid,
          nacimiento: date,
          nacimientoValid: nacimientoValid,
          cantCanjesDNI: cantCanjesDNI,
          cantPromoValid:
            this.state.cantPromo &&
            !isNaN(Number(this.state.cantPromo)) &&
            (this.state.datos.cant_promo_dia_cliente == 0 ||
              this.state.cantPromo <=
                this.state.datos.cant_promo_dia_cliente - cantCanjesDNI),
        },
        () => {
          this.dniRef.setNativeProps({
            style: { borderColor: dniValid ? colors.success : "red" },
          });
          this.nombreRef.setNativeProps({
            style: { borderColor: nombreValid ? colors.success : "red" },
          });
          this.apellidoRef.setNativeProps({
            style: { borderColor: apellidoValid ? colors.success : "red" },
          });
        }
      );
    } catch (e) {
      Toast.show("No se detectó un código de barras válido", {
        duration: Toast.durations.LONG,
        backgroundColor: colors.danger,
        textColor: colors.text2,
      });
      return;
    }
  }

  async handleBarcodeScannedVoucher({ type, data }) {
    // STATE
    this.setState(
      {
        back: !this.props.route.params.editable,
        showScannerBarcodeVoucher: false,
        voucher: data,
        voucherValid: data.length > 0,
      },
      () => this.checkValidacion(data)
    );
  }

  findFirstError() {
    const {
      cantPromo,
      cantCanjesDNI,
      dni,
      dniValid,
      nombre,
      nombreValid,
      apellido,
      apellidoValid,
      cantPromoValid,
      foto,
      qr,
      qrValid,
      voucher,
      voucherValid,
      datos,
    } = this.state;

    const requireCliente = datos.cant_promo_dia_cliente != 0;

    if (!cantPromoValid) {
      this.cantPromoRef.setNativeProps({
        style: { borderColor: "red" },
      });
      const dif = datos.cant_promo_dia_cliente - cantCanjesDNI;
      if (requireCliente && cantCanjesDNI && dif < cantPromo) {
        return (
          "El cliente no puede canjear más " +
          (dif > 0 ? `de ${dif} ` : "") +
          "promociones."
        );
      } else {
        return "Revise cantidad de promociones a canjear.";
      }
    }
    if ((requireCliente || dni != null) && !dniValid) {
      this.dniRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el DNI.";
    }
    if ((requireCliente || nombre != null) && !nombreValid) {
      this.nombreRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el nombre.";
    }
    if ((requireCliente || apellido != null) && !apellidoValid) {
      this.apellidoRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise el apellido.";
    }
    if (datos.tipo_validacion.toUpperCase() === "FOTO" && !foto) {
      return "Revise la validación por foto.";
    }
    if (datos.tipo_validacion.toUpperCase() === "QR" && qr && !qrValid) {
      this.qrRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise la validación por QR.";
    }
    if (
      datos.tipo_validacion.toUpperCase() === "VOUCHER" &&
      voucher &&
      !voucherValid
    ) {
      this.voucherRef.setNativeProps({
        style: { borderColor: "red" },
      });
      return "Revise la validación por Voucher.";
    }
    return null;
  }

  async checkValidacion(validacion) {
    let res = await fetch(
      this.props.value.proxy +
        `/promoTKTs/canjes/checkValidacion?idPromocion=${this.state.datos.idpromocion}&validacion=${validacion}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    let valid = false;
    if (res.status == 200) {
      valid = await res.json();
    }

    if (!valid) {
      Toast.show("La validación ya fue usada en otro canje", {
        duration: Toast.durations.LONG,
        backgroundColor: this.props.value.colors.danger,
        textColor: this.props.value.colors.text2,
      });
      if (this.state.datos.tipo_validacion.toUpperCase() === "QR") {
        this.qrRef.setNativeProps({
          style: { borderColor: "red" },
        });
      } else if (this.state.datos.tipo_validacion.toUpperCase() === "VOUCHER") {
        this.voucherRef.setNativeProps({
          style: { borderColor: "red" },
        });
      }
    }

    this.setState({ qrValid: valid, voucherValid: valid });
  }

  async getFoto() {
    const colors = this.props.value.colors;
    let res = await fetch(
      this.props.value.proxy +
        `/promoTKTs/canjes/foto?idCanje=${this.state.datos.idcanje}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status == 200) {
      return await res.json();
    } else {
      let error;
      if (res.status == 401) {
        error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        await fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        });
        this.props.navigation.reset({
          index: 0,
          routes: [{ name: "Login" }],
        });
      } else {
        switch (res.status) {
          case 403:
            error = "Permisos insuficientes.";
            break;
          case 404:
            error = "Foto no encontrada.";
            break;
          case 406:
            error = "Error de conexión.";
            break;
          case 412:
            error = "El canje no se valida por foto.";
            break;
          default:
            error = "Error de servidor.";
            break;
        }
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        throw new Error("");
      }
    }
  }

  showFotoBack() {
    this.setState({ fotoLoading: true }, async () => {
      if (this.state.foto) {
        const foto = await this.getFoto();
        this.setState({ showFoto: foto, fotoLoading: false });
      } else {
        const colors = this.props.value.colors;
        Toast.show("No se pudo abrir la foto.", {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
      }
    });
  }

  showConfirmDialog() {
    this.setState({ showConfirm: true });
  }

  confirm() {
    const validationError = this.findFirstError();
    const colors = this.props.value.colors;
    if (!validationError) {
      this.setState(
        {
          loading: true,
          dni: this.state.dni,
        },
        async () => {
          Keyboard.dismiss();

          const nacimiento = this.state.nacimiento?.toISOString().split("T")[0];
          const canje = {
            dni_cli: this.state.dni,
            nombre_cli: this.state.nombre,
            apellido_cli: this.state.apellido,
            nacimiento_cli: nacimiento,
            tipo_validacion: this.state.datos.tipo_validacion,
            idPromocion: this.state.datos.idpromocion,
            cant_promo: Number(this.state.cantPromo),
            obs: this.state.obs,
          };
          switch (this.state.datos.tipo_validacion.toUpperCase()) {
            case "FOTO":
              canje.validacion = this.state.foto;
              break;
            case "QR":
              canje.validacion = this.state.qr;
              break;
            case "VOUCHER":
              canje.validacion = this.state.voucher;
              break;
          }
          let res = await fetch(this.props.value.proxy + "/promoTKTs/canjes", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              canje: canje,
            }),
          });

          this.setState({ loading: false });

          if (res.status == 200) {
            const mensaje = "Canje realizado correctamente.";
            Toast.show(mensaje, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.success,
              textColor: colors.text2,
            });
            setTimeout(() => {
              this.setState({ back: true }, () =>
                this.props.navigation.reset({
                  index: 0,
                  routes: this.props.value.userData.idSala
                    ? [
                        { name: "Salas" },
                        {
                          name: "Opciones",
                        },
                        {
                          name: "PromoTKTs",
                        },
                        {
                          name: "Promociones",
                        },
                      ]
                    : [{ name: "Salas" }],
                })
              );
            }, 1250);
          } else {
            let error;
            if (res.status == 401) {
              error = "Sesión expirada. Por favor ingrese nuevamente.";
              Toast.show(error, {
                duration: Toast.durations.LONG,
                backgroundColor: colors.danger,
                textColor: colors.text2,
              });
              await fetch(this.props.value.proxy + "/accounts/logout", {
                method: "POST",
              });
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: "Login" }],
              });
            } else {
              if (res.status == 400) {
                error = "Sala no seleccionada.";
                Toast.show(error, {
                  duration: Toast.durations.LONG,
                  backgroundColor: colors.danger,
                  textColor: colors.text2,
                });
                this.props.navigation.reset({
                  index: 0,
                  routes: [{ name: "Salas" }],
                });
              } else {
                switch (res.status) {
                  case 403:
                    error = "Permisos insuficientes.";
                    break;
                  case 404:
                    error =
                      "Hubo un error al validar los datos de la promoción.";
                    break;
                  case 406:
                    error = "Error de conexión.";
                    break;
                  case 409:
                    error = "Tipo de validación incorrecto o repetido.";
                    break;
                  case 411:
                    error = "La promoción require un DNI.";
                    break;
                  case 412:
                    error = "Promociones insuficientes.";
                    this.cantPromoRef.setNativeProps({
                      style: { borderColor: "red" },
                    });
                    break;
                  default:
                    error = "Error de servidor.";
                    break;
                }

                Toast.show(error, {
                  duration: Toast.durations.LONG,
                  backgroundColor: colors.danger,
                  textColor: colors.text2,
                });
              }
            }
          }
        }
      );
    } else {
      Toast.show(validationError, {
        duration: Toast.durations.LONG,
        backgroundColor: colors.danger,
        textColor: colors.text2,
      });
    }
  }

  showCancelDialog() {
    this.setState({ showCancel: true });
  }

  cancel() {
    this.setState({ back: true }, () => this.props.navigation.goBack());
  }

  showRevokeDialog() {
    this.setState({ showRevoke: true });
  }

  revoke() {
    const colors = this.props.value.colors;
    this.setState(
      {
        loading: true,
      },
      async () => {
        let res = await fetch(
          this.props.value.proxy + "/promoTKTs/canjes/anular",
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              idCanje: this.state.datos.idcanje,
            }),
          }
        );

        if (res.status == 200) {
          const mensaje = "Canje anulado correctamente.";
          Toast.show(mensaje, {
            duration: Toast.durations.LONG,
            backgroundColor: colors.success,
            textColor: colors.text2,
          });
          const newCanje = {
            ...this.props.route.params.canje,
            anulador: "Yo",
          };
          this.props.route.params.update(newCanje);
          setTimeout(() => {
            this.setState({ back: true }, () => this.props.navigation.goBack());
          }, 2000);
        } else {
          let error;
          if (res.status == 401) {
            error = "Sesión expirada. Por favor ingrese nuevamente.";
            Toast.show(error, {
              duration: Toast.durations.LONG,
              backgroundColor: colors.danger,
              textColor: colors.text2,
            });
            await fetch(this.props.value.proxy + "/accounts/logout", {
              method: "POST",
            });
            this.props.navigation.reset({
              index: 0,
              routes: [{ name: "Login" }],
            });
          } else {
            if (res.status == 400) {
              error = "Sala no seleccionada.";
              Toast.show(error, {
                duration: Toast.durations.LONG,
                backgroundColor: colors.danger,
                textColor: colors.text2,
              });
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: "Salas" }],
              });
            } else {
              switch (res.status) {
                case 403:
                  error = "Permisos insuficientes.";
                  break;
                case 404:
                  error = "El canje no pudo ser anulado.";
                  break;
                case 406:
                  error = "Error de conexión.";
                  break;
                default:
                  error = "Error de servidor.";
                  break;
              }

              Toast.show(error, {
                duration: Toast.durations.LONG,
                backgroundColor: colors.danger,
                textColor: colors.text2,
              });
            }
          }
        }
      }
    );
  }

  onLayout(event, key) {
    this.dataSourceCords[key] = event.nativeEvent.layout.y;
  }

  render() {
    const datos = this.state.datos;
    const requireCliente = datos.cant_promo_dia_cliente != 0;
    const tipo = datos.tipo_validacion.toUpperCase();
    const editable = this.state.editable;
    const anulado = datos.anulador != null;
    const fechaCanje = new Date(datos.fecha);
    //HUSO HORARIO
    fechaCanje.setHours(fechaCanje.getHours() + 3);
    const fechaActual = new Date();
    const anulable =
      !anulado &&
      !editable &&
      this.props.value.userData.rol.toUpperCase() === "SUPERVISOR" &&
      fechaCanje.getFullYear() +
        "-" +
        fechaCanje.getMonth() +
        "-" +
        fechaCanje.getDate() ===
        fechaActual.getFullYear() +
          "-" +
          fechaActual.getMonth() +
          "-" +
          fechaActual.getDate();
    const colors = this.props.value.colors;
    const { height } = Dimensions.get("window");
    let minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 100);
    let maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    const styles = StyleSheet.create({
      background: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: height,
        justifyContent: "center",
        backgroundColor: colors.background,
        zIndex: 0,
      },
      master: {
        display: "flex",
        width: 400,
        height: "100%",
        zIndex: 1,
      },
      scroll: {
        display: "flex",
      },
      container: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: 15,
      },
      card: {
        width: "95%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        justifyContent: "flex-start",
      },
      field: {
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: 370,
        marginTop: 3,
        marginBottom: 3,
        paddingVertical: 3,
        zIndex: 49,
      },
      fieldCompressed: {
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        maxWidth: 370,
        marginTop: 2,
        marginBottom: 2,
        paddingVertical: 2,
        zIndex: 49,
      },
      fieldLabel: {
        width: "100%",
        color: colors.text3,
        fontSize: 17,
        fontWeight: "550",
        marginBottom: 5,
        zIndex: 49,
      },
      fieldLabelCliente: {
        width: "100%",
        color: colors.text3,
        fontSize: 15,
        fontWeight: "550",
        marginBottom: 5,
        zIndex: 49,
      },
      title: {
        width: "100%",
        backgroundColor: colors.text3,
        color: colors.text2,
        textAlign: "center",
        borderRadius: 5,
        fontSize: 24,
        fontWeight: "550",
        marginBottom: 5,
        zIndex: 49,
      },
      dniLabel: {
        width: "85%",
        color: colors.text3,
        fontSize: 17,
        fontWeight: "550",
        alignSelf: "baseline",
        marginBottom: 5,
      },
      fieldInput: {
        width: "100%",
        paddingTop: "1.7%",
        paddingBottom: "1.7%",
        paddingLeft: "5%",
        paddingRight: "5%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: colors.field,
        color: colors.text3,
        fontSize: 22,
        fontWeight: "500",
        zIndex: 49,
      },
      importeInput: {
        width: "100%",
        paddingTop: "1.7%",
        paddingBottom: "1.7%",
        paddingLeft: "5%",
        paddingRight: "5%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: colors.field,
        color: colors.text3,
        fontSize: 25,
        fontWeight: "500",
        textAlign: "right",
        zIndex: 49,
      },
      staticField: {
        width: "100%",
        paddingTop: "0%",
        paddingBottom: "0%",
        paddingLeft: "0%",
        paddingRight: "0%",
        borderWidth: 0,
        backgroundColor: colors.card,
        color: colors.text3,
        fontSize: 25,
        fontWeight: "500",
        zIndex: 49,
      },
      qrButton: {
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
      },
      barcodeButton: {
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
      },
      imageButton: {
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      },
      image: {
        width: 90,
        height: 90,
        flexShrink: 0,
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
      },
      touch: {
        width: "45%",
        alignSelf: "center",
        borderRadius: 5,
        zIndex: 49,
      },
      buttonsContainer: {
        justifyContent: "space-evenly",
        flexDirection: "row",
        width: "100%",
        maxWidth: 370,
        marginTop: 3,
        marginBottom: 3,
        paddingVertical: 3,
        zIndex: 49,
        backgroundColor: colors.card,
      },
      fotoButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.loading ? colors.field : colors.info,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2,
        zIndex: 49,
      },
      sendButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.loading ? colors.field : colors.success,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2,
        zIndex: 49,
      },
      cancelButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.loading ? colors.field : colors.accent1,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2,
        zIndex: 49,
      },
      revokeButton: {
        width: "100%",
        borderRadius: 5,
        borderWidth: 1,
        borderColor: colors.border,
        backgroundColor: this.state.loading ? colors.field : colors.accent1,
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 2,
        paddingRight: 2,
        zIndex: 49,
      },
      fotoText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.loading ? colors.placeholder : colors.text2,
        zIndex: 49,
      },
      sendText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.loading ? colors.placeholder : colors.text2,
        zIndex: 49,
      },
      cancelText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.loading ? colors.placeholder : colors.text1,
        zIndex: 49,
      },
      revokeText: {
        fontSize: 25,
        textAlign: "center",
        color: this.state.loading ? colors.placeholder : colors.text1,
        zIndex: 49,
      },
      dialogBox: {
        width: "95%",
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: colors.card,
        borderRadius: 5,
        shadowColor: colors.shadow,
        shadowOffset: { width: 3, height: 3 },
        shadowOpacity: 0.2,
        shadowRadius: 15,
        elevation: 12,
        justifyContent: "center",
        alignItems: "center",
      },
      dialogText: {
        width: "100%",
        color: colors.text3,
        fontSize: 17,
        fontWeight: "550",
        marginBottom: 5,
      },
    });
    const maskImporte = new StringMask("#.##0,00", { reverse: true });
    const maskDNI = new StringMask("#.##0", { reverse: true });
    const dark = colors.theme === "DARK";
    const theme = dark ? { ...MD3DarkTheme } : { ...DefaultTheme };
    const dateTheme = {
      ...theme,
      dark: dark,
      roundness: 5,
    };
    return (
      <>
        <Modal
          visible={this.state.showFoto}
          transparent={true}
          onRequestClose={() => {
            this.setState({ showFoto: null });
          }}
        >
          <ImageViewer
            imageUrls={[
              { url: "data:image/jpeg;base64," + this.state.showFoto },
            ]}
            saveToLocalByLongPress={false}
            menuContext={null}
            renderIndicator={() => null}
          />
        </Modal>
        <SafeAreaView>
          {this.state.showScannerQR ||
          this.state.showScannerBarcodeDNI ||
          this.state.showScannerBarcodeVoucher ? (
            Platform.OS === "web" ? (
              <Camera
                style={styles.background}
                onBarCodeScanned={(object) => {
                  if (this.state.showScannerQR) {
                    this.handleQRScanned(object);
                  } else {
                    if (this.state.showScannerBarcodeDNI) {
                      this.handleBarcodeScannedDNI(object);
                    } else {
                      this.handleBarcodeScannedVoucher(object);
                    }
                  }
                }}
                type={CameraType.back}
                barCodeScannerSettings={{
                  barCodeTypes: [
                    this.state.showScannerQR
                      ? BarCodeScanner.Constants.BarCodeType.qr
                      : BarCodeScanner.Constants.BarCodeType.pdf417,
                  ],
                }}
                ref={this.props.ref}
              />
            ) : (
              <BarCodeScanner
                onBarCodeScanned={(object) => {
                  if (this.state.showScannerQR) {
                    this.handleQRScanned(object);
                  } else {
                    if (this.state.showScannerBarcodeDNI) {
                      this.handleBarcodeScannedDNI(object);
                    } else {
                      this.handleBarcodeScannedVoucher(object);
                    }
                  }
                }}
                barCodeTypes={[this.state.showScannerQR ? "qr" : "pdf417"]}
                style={styles.background}
              />
            )
          ) : (
            <>
              <Modal
                visible={this.state.showFoto != null}
                transparent={true}
                onRequestClose={() => {
                  this.setState({ showFoto: null });
                }}
              >
                <ImageViewer
                  imageUrls={[
                    { url: "data:image/jpeg;base64," + this.state.showFoto },
                  ]}
                  saveToLocalByLongPress={false}
                  menuContext={null}
                  renderIndicator={() => null}
                />
              </Modal>
              <Dialog
                show={this.state.showConfirm}
                hide={() => this.setState({ showConfirm: false })}
                text="¿Desea realizar el canje?"
                button1={{
                  text: "No",
                  color1: colors.field,
                  color2: colors.text3,
                }}
                button2={{
                  text: "Si",
                  color1: colors.success,
                  color2: colors.text2,
                  onPress: () => {
                    this.confirm();
                  },
                }}
              />
              <Dialog
                show={this.state.showCancel}
                hide={() => this.setState({ showCancel: false })}
                text="¿Desea cancelar el canje?"
                button1={{
                  text: "No",
                  color1: colors.field,
                  color2: colors.text3,
                }}
                button2={{
                  text: "Si",
                  color1: colors.accent1,
                  color2: colors.text1,
                  onPress: () => {
                    this.cancel();
                  },
                }}
              />
              <Dialog
                show={this.state.showRevoke}
                hide={() => this.setState({ showRevoke: false })}
                text="¿Desea anular el canje?"
                button1={{
                  text: "No",
                  color1: colors.field,
                  color2: colors.text3,
                }}
                button2={{
                  text: "Si",
                  color1: colors.accent1,
                  color2: colors.text1,
                  onPress: () => {
                    this.revoke();
                  },
                }}
              />
              <NavBar
                title={editable ? "Canjear" : "Canje"}
                navigation={this.props.navigation}
                enableBack={(callback) => this.enableBack(callback)}
              />
              <View style={styles.background}>
                <View style={styles.master}>
                  <ScrollView
                    ref={(e) => (this.scrollRef = e)}
                    style={styles.scroll}
                    contentContainerStyle={styles.container}
                    showsVerticalScrollIndicator={false}
                    keyboardShouldPersistTaps="always"
                  >
                    <View style={styles.card}>
                      <View style={styles.fieldCompressed}>
                        <Text
                          style={[
                            styles.staticField,
                            {
                              textAlign: "center",
                              color: colors.info,
                              fontSize: 25,
                              fontWeight: "bold",
                            },
                          ]}
                        >
                          {datos.nombre}
                        </Text>
                      </View>
                      <View style={styles.fieldCompressed}>
                        <Text
                          style={[
                            styles.staticField,
                            { textAlign: "center", fontSize: 18 },
                          ]}
                        >
                          {datos.descripcion}
                        </Text>
                      </View>
                      <View
                        style={[
                          styles.fieldCompressed,
                          {
                            justifyContent: "center",
                          },
                        ]}
                      >
                        <Text>
                          <Text
                            style={[
                              styles.staticField,
                              {
                                fontSize: 30,
                                color: colors.success,
                                fontWeight: "800",
                              },
                            ]}
                          >
                            {" $" +
                              maskImporte.apply(
                                datos.valor_promocion
                                  .toFixed(2)
                                  .replace(".", "")
                              )}
                          </Text>
                          <Text
                            style={[
                              styles.staticField,
                              {
                                fontSize: 18,
                              },
                            ]}
                          >
                            {" (" +
                              datos.cant_tkt +
                              "x" +
                              maskImporte.apply(
                                datos.valor_tkt.toFixed(2).replace(".", "")
                              ) +
                              ")"}
                          </Text>
                        </Text>
                      </View>
                      {editable && (
                        <View style={styles.field}>
                          <Text
                            style={[
                              styles.staticField,
                              {
                                fontSize: 15,
                                textAlign: "left",
                                maxWidth: "50%",
                              },
                            ]}
                          >
                            {"Disponibles: " + datos.cant_disponible}
                          </Text>
                          <Text
                            style={[
                              styles.staticField,
                              {
                                fontSize: 15,
                                textAlign: "right",
                                maxWidth: "50%",
                              },
                            ]}
                          >
                            {"Canjeadas: " + datos.cant_canjeadas}
                          </Text>
                        </View>
                      )}

                      <View style={{ width: "100%", height: 15 }} />
                      <Text style={styles.title}>Canje</Text>
                      <View style={styles.field}>
                        <Text
                          style={[
                            styles.fieldLabel,
                            { width: "72%", textAlignVertical: "center" },
                          ]}
                        >
                          Cantidad de Promociones:
                        </Text>
                        <InputOrText
                          editable={editable}
                          reference={(e) => (this.cantPromoRef = e)}
                          style={[
                            styles.fieldInput,
                            {
                              width: "28%",
                              fontWeight:
                                this.state.cantPromo && this.state.cantPromo > 0
                                  ? "500"
                                  : "300",
                              textAlign: "center",
                            },
                          ]}
                          valueInput={this.state.cantPromo}
                          placeholder="0"
                          placeholderTextColor={colors.placeholder}
                          keyboardType="numeric"
                          onChangeText={(text) => {
                            let value = -1;
                            try {
                              value = Number(text.replace(/[.,]/g, ""));
                            } catch (e) {}
                            let valid =
                              0 < value &&
                              value <= datos.cant_disponible &&
                              (!requireCliente ||
                                !this.state.cantCanjesDNI ||
                                value <=
                                  datos.cant_promo_dia_cliente -
                                    this.state.cantCanjesDNI);
                            if (valid) {
                              this.cantPromoRef.setNativeProps({
                                style: { borderColor: colors.border },
                              });
                            } else {
                              this.cantPromoRef.setNativeProps({
                                style: { borderColor: "red" },
                              });
                            }
                            this.setState({
                              cantPromo: value > 0 ? value.toString() : null,
                              cantPromoValid: valid,
                            });
                          }}
                          selectTextOnFocus
                          onFocus={() => {
                            this.cantPromoRef.setNativeProps({
                              style: {
                                backgroundColor: colors.focus,
                                color: colors.text4,
                              },
                            });
                          }}
                          onBlur={() => {
                            this.cantPromoRef.setNativeProps({
                              style: [
                                styles.fieldInput,
                                {
                                  width: "28%",
                                  fontWeight:
                                    this.state.cantPromo &&
                                    this.state.cantPromo > 0
                                      ? "500"
                                      : "300",
                                  textAlign: "center",
                                },
                              ],
                            });
                          }}
                          onSubmitEditing={() => {
                            Keyboard.dismiss();
                            this.scrollRef?.scrollTo(this.dataSourceCords[0]);
                          }}
                        />
                      </View>
                      <View style={styles.field}>
                        <Text style={[styles.fieldLabel, { width: "50%" }]}>
                          Importe Total:
                        </Text>
                        <Text
                          style={[
                            styles.staticField,
                            { width: "50%", textAlign: "right", fontSize: 20 },
                          ]}
                        >
                          {"$" +
                            maskImporte.apply(
                              (this.state.cantPromo * datos.valor_promocion)
                                .toFixed(2)
                                .replace(".", "")
                            )}
                        </Text>
                      </View>
                      {!editable && (
                        <View style={styles.field}>
                          <Text
                            style={[
                              styles.fieldLabel,
                              { width: "50%", textAlignVertical: "center" },
                            ]}
                          >
                            {anulado ? "Anulado por:" : "Canjeado por:"}
                          </Text>
                          <Text
                            style={[
                              styles.staticField,
                              {
                                width: "50%",
                                textAlign: "right",
                                fontSize: 20,
                              },
                            ]}
                          >
                            {(anulado ? datos.anulador : datos.usuario) || "-"}
                          </Text>
                        </View>
                      )}

                      <View style={{ width: "100%", height: 15 }} />
                      <Text
                        style={styles.title}
                        onLayout={(event) => {
                          this.onLayout(event, 0);
                        }}
                      >
                        Cliente
                      </Text>
                      <View style={styles.field}>
                        <InputOrText
                          editable={editable}
                          reference={(e) => (this.dniRef = e)}
                          style={[
                            styles.fieldInput,

                            {
                              width: "80%",
                              fontWeight:
                                this.state.dniMasked &&
                                this.state.dniMasked.length > 0
                                  ? "500"
                                  : "300",
                            },
                          ]}
                          valueInput={this.state.dniMasked}
                          placeholder="DNI"
                          placeholderTextColor={colors.placeholder}
                          keyboardType="numeric"
                          onChangeText={(text) => {
                            let value = -1;
                            try {
                              value = Number(text.replace(/[.,]/g, ""));
                            } catch (e) {}
                            let valid = value != -1 && value >= 1000000;
                            if (valid) {
                              this.dniRef.setNativeProps({
                                style: { borderColor: colors.border },
                              });
                            } else {
                              this.dniRef.setNativeProps({
                                style: { borderColor: "red" },
                              });
                            }
                            this.setState({
                              dni: value,
                              dniMasked: text,
                              dniValid: valid,
                            });
                          }}
                          selectTextOnFocus
                          onFocus={() => {
                            this.dniRef.setNativeProps({
                              style: {
                                backgroundColor: colors.focus,
                                color: colors.text4,
                              },
                            });
                          }}
                          onBlur={async () => {
                            this.dniRef.setNativeProps({
                              style: [
                                styles.fieldInput,
                                {
                                  width: "80%",
                                  fontWeight:
                                    this.state.dniMasked &&
                                    this.state.dniMasked.length > 0
                                      ? "500"
                                      : "300",
                                },
                              ],
                            });
                            if (this.state.dni) {
                              const datosCliente = await this.getDatosCliente(
                                this.state.dni
                              );
                              const { cant, nombre, apellido, nacimiento } =
                                datosCliente;
                              this.setState({
                                dniMasked: maskDNI.apply(this.state.dni),
                                cantCanjesDNI: cant,
                                nombre: nombre,
                                nombreValid: nombre && nombre.length > 0,
                                apellido: apellido,
                                apellidoValid: apellido && apellido.length > 0,
                                nacimiento: nacimiento
                                  ? new Date(nacimiento)
                                  : null,
                                nacimientoValid: nacimiento != null,
                                cantPromoValid:
                                  this.state.cantPromo &&
                                  !isNaN(Number(this.state.cantPromo)) &&
                                  (!requireCliente ||
                                    this.state.cantPromo <=
                                      datos.cant_promo_dia_cliente -
                                        datosCliente.cant),
                              });
                            }
                          }}
                          onSubmitEditing={() => {
                            this.scrollRef?.scrollTo(this.dataSourceCords[1]);
                            this.nombreRef.focus();
                          }}
                        />
                        {editable && (
                          <TouchableHighlight
                            onPress={() => {
                              this.onPressBarcodeButton("DNI");
                            }}
                            style={styles.barcodeButton}
                          >
                            <IcoBarcode
                              width={30}
                              height={30}
                              stroke={colors.text3}
                              fill={colors.text3}
                            />
                          </TouchableHighlight>
                        )}
                      </View>
                      <View
                        style={styles.field}
                        onLayout={(event) => {
                          this.onLayout(event, 1);
                        }}
                      >
                        <InputOrText
                          editable={editable}
                          reference={(e) => (this.nombreRef = e)}
                          style={[
                            styles.fieldInput,
                            {
                              fontWeight:
                                this.state.nombre &&
                                this.state.nombre.length > 0
                                  ? "500"
                                  : "300",
                            },
                          ]}
                          valueInput={this.state.nombre}
                          placeholder="NOMBRE"
                          placeholderTextColor={colors.placeholder}
                          onChangeText={(text) => {
                            let valid = text.length > 0;
                            if (valid) {
                              this.nombreRef.setNativeProps({
                                style: { borderColor: colors.border },
                              });
                            } else {
                              this.nombreRef.setNativeProps({
                                style: { borderColor: "red" },
                              });
                            }
                            this.setState({
                              nombre: text,
                              nombreValid: valid,
                            });
                          }}
                          onFocus={() => {
                            this.nombreRef.setNativeProps({
                              style: {
                                backgroundColor: colors.focus,
                                color: colors.text4,
                              },
                            });
                          }}
                          onBlur={() => {
                            this.nombreRef.setNativeProps({
                              style: [
                                styles.fieldInput,
                                {
                                  fontWeight:
                                    this.state.nombre &&
                                    this.state.nombre.length > 0
                                      ? "500"
                                      : "300",
                                },
                              ],
                            });
                          }}
                          onSubmitEditing={() => {
                            this.scrollRef?.scrollTo(this.dataSourceCords[2]);
                            this.apellidoRef.focus();
                          }}
                        />
                      </View>
                      <View
                        style={styles.field}
                        onLayout={(event) => {
                          this.onLayout(event, 2);
                        }}
                      >
                        <InputOrText
                          editable={editable}
                          reference={(e) => (this.apellidoRef = e)}
                          style={[
                            styles.fieldInput,
                            {
                              fontWeight:
                                this.state.apellido &&
                                this.state.apellido.length > 0
                                  ? "500"
                                  : "300",
                            },
                          ]}
                          valueInput={this.state.apellido}
                          placeholder="APELLIDO"
                          placeholderTextColor={colors.placeholder}
                          onChangeText={(text) => {
                            let valid = text.length > 0;
                            if (valid) {
                              this.apellidoRef.setNativeProps({
                                style: { borderColor: colors.border },
                              });
                            } else {
                              this.apellidoRef.setNativeProps({
                                style: { borderColor: "red" },
                              });
                            }
                            this.setState({
                              apellido: text,
                              apellidoValid: valid,
                            });
                          }}
                          onFocus={() => {
                            this.apellidoRef.setNativeProps({
                              style: {
                                backgroundColor: colors.focus,
                                color: colors.text4,
                              },
                            });
                          }}
                          onBlur={() => {
                            this.apellidoRef.setNativeProps({
                              style: [
                                styles.fieldInput,
                                {
                                  fontWeight:
                                    this.state.apellido &&
                                    this.state.apellido.length > 0
                                      ? "500"
                                      : "300",
                                },
                              ],
                            });
                          }}
                          onSubmitEditing={() => {
                            this.scrollRef?.scrollTo(this.dataSourceCords[3]);
                            Keyboard.dismiss();
                          }}
                        />
                      </View>
                      <View
                        style={styles.field}
                        onLayout={(event) => {
                          this.onLayout(event, 3);
                        }}
                      >
                        {editable ? (
                          <DatePickerInput
                            theme={dateTheme}
                            locale="es"
                            value={this.state.nacimiento}
                            onChange={(d) => this.setState({ nacimiento: d })}
                            inputMode="start"
                            placeholder="Fecha de nacimiento"
                            validRange={{
                              startDate: minDate,
                              endDate: maxDate,
                            }}
                          />
                        ) : (
                          <Text style={[styles.staticField, { fontSize: 20 }]}>
                            {(() => {
                              if (this.state.nacimiento) {
                                const split = this.state.nacimiento
                                  .toISOString()
                                  .split("T")[0]
                                  .split("-");
                                return `${split[2]}-${split[1]}-${split[0]}`;
                              } else {
                                return `-`;
                              }
                            })()}
                          </Text>
                        )}
                      </View>
                      {this.state.cantCanjesDNI != null ? (
                        <View style={styles.field}>
                          <Text style={styles.fieldLabelCliente}>
                            Canjes realizados en el día
                          </Text>
                          <Text style={[styles.staticField, { fontSize: 20 }]}>
                            {this.state.cantCanjesDNI +
                              (requireCliente
                                ? " (el máximo permitido es: " +
                                  datos.cant_promo_dia_cliente +
                                  ")"
                                : "")}
                          </Text>
                        </View>
                      ) : (
                        <></>
                      )}

                      <View style={{ width: "100%", height: 15 }} />
                      <Text
                        style={styles.title}
                        onLayout={(event) => {
                          this.onLayout(event, 4);
                        }}
                      >
                        Validación
                      </Text>
                      {tipo === "VOUCHER" ? (
                        <View style={styles.field}>
                          <InputOrText
                            editable={editable}
                            reference={(e) => (this.voucherRef = e)}
                            style={[
                              styles.fieldInput,

                              {
                                width: "80%",
                                fontWeight:
                                  this.state.voucher &&
                                  this.state.voucher.length > 0
                                    ? "500"
                                    : "300",
                              },
                            ]}
                            valueInput={this.state.voucher}
                            placeholder="VOUCHER"
                            placeholderTextColor={colors.placeholder}
                            onChangeText={(text) => {
                              let valid = text.length > 0;
                              if (valid) {
                                this.voucherRef.setNativeProps({
                                  style: { borderColor: colors.border },
                                });
                              } else {
                                this.voucherRef.setNativeProps({
                                  style: { borderColor: "red" },
                                });
                              }
                              this.setState({
                                voucher: text,
                                voucherValid: valid,
                              });
                            }}
                            selectTextOnFocus
                            onFocus={() => {
                              this.voucherRef.setNativeProps({
                                style: {
                                  backgroundColor: colors.focus,
                                  color: colors.text4,
                                },
                              });
                            }}
                            onBlur={async () => {
                              this.voucherRef.setNativeProps({
                                style: [
                                  styles.fieldInput,
                                  {
                                    width: "80%",
                                    fontWeight:
                                      this.state.voucher &&
                                      this.state.voucher.length > 0
                                        ? "500"
                                        : "300",
                                  },
                                ],
                              });
                              if (
                                this.state.voucher &&
                                this.state.voucher.length > 0
                              )
                                await this.checkValidacion(this.state.voucher);
                            }}
                            onSubmitEditing={async () => {
                              if (
                                this.state.voucher &&
                                this.state.voucher.length > 0
                              )
                                await this.checkValidacion(this.state.voucher);
                              this.scrollRef?.scrollTo(this.dataSourceCords[5]);
                              this.obsRef.focus();
                            }}
                          />
                          {editable && (
                            <TouchableHighlight
                              onPress={() => {
                                this.onPressBarcodeButton("VOUCHER");
                              }}
                              style={styles.barcodeButton}
                            >
                              <IcoBarcode
                                width={30}
                                height={30}
                                stroke={colors.text3}
                                fill={colors.text3}
                              />
                            </TouchableHighlight>
                          )}
                        </View>
                      ) : tipo === "QR" ? (
                        <View style={styles.field}>
                          <InputOrText
                            editable={editable}
                            block={true}
                            reference={(e) => (this.qrRef = e)}
                            style={[
                              styles.fieldInput,
                              {
                                width: "80%",
                                fontWeight:
                                  this.state.qr && this.state.qr.length > 0
                                    ? "500"
                                    : "300",
                              },
                            ]}
                            valueInput={this.state.qr}
                            placeholder="QR"
                            placeholderTextColor={colors.placeholder}
                            onChangeText={(text) => {
                              let valid = text.length > 0;
                              if (valid) {
                                this.qrRef.setNativeProps({
                                  style: { borderColor: colors.border },
                                });
                              } else {
                                this.qrRef.setNativeProps({
                                  style: { borderColor: "red" },
                                });
                              }
                              this.setState({
                                qr: text,
                                qrValid: valid,
                              });
                            }}
                            onSubmitEditing={() => {
                              this.scrollRef?.scrollTo(this.dataSourceCords[5]);
                              this.obsRef.focus();
                            }}
                            onFocus={() => {
                              this.qrRef.setNativeProps({
                                style: {
                                  backgroundColor: colors.focus,
                                  color: colors.text4,
                                },
                              });
                            }}
                            onBlur={() => {
                              this.qrRef.setNativeProps({
                                style: [
                                  styles.fieldInput,
                                  {
                                    width: "80%",
                                    fontWeight:
                                      this.state.qr && this.state.qr.length > 0
                                        ? "500"
                                        : "300",
                                  },
                                ],
                              });
                            }}
                          />
                          {editable && (
                            <TouchableHighlight
                              onPress={() => {
                                this.onPressQRButton();
                              }}
                              style={styles.qrButton}
                            >
                              <IcoQR
                                width={30}
                                height={30}
                                stroke={colors.text3}
                                fill={colors.text3}
                              />
                            </TouchableHighlight>
                          )}
                        </View>
                      ) : (
                        <View style={styles.field}>
                          {editable ? (
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                paddingLeft: 50,
                                paddingRight: 50,
                              }}
                            >
                              <TouchableHighlight
                                onPress={() => {
                                  this.onPressFoto();
                                }}
                                style={styles.imageButton}
                              >
                                {this.state.fotoLoading ? (
                                  <ActivityIndicator
                                    size="large"
                                    color={colors.text3}
                                  />
                                ) : this.state.foto ? (
                                  <Image
                                    source={{
                                      uri:
                                        "data:image/jpeg;base64," +
                                        this.state.foto,
                                    }}
                                    style={styles.image}
                                  />
                                ) : (
                                  <IcoCamera
                                    width={45}
                                    height={45}
                                    stroke={colors.text3}
                                    fill={colors.text3}
                                  />
                                )}
                              </TouchableHighlight>
                              {this.state.foto != null && (
                                <TouchableHighlight
                                  onPress={() => {
                                    this.setState({ foto: null });
                                  }}
                                  style={styles.imageButton}
                                >
                                  <IcoTrash
                                    width={50}
                                    height={50}
                                    stroke={colors.text3}
                                    fill={colors.text3}
                                  />
                                </TouchableHighlight>
                              )}
                            </View>
                          ) : (
                            <View style={styles.buttonsContainer}>
                              <TouchableHighlight
                                disabled={this.state.fotoLoading}
                                style={styles.touch}
                                underlayColor={colors.field}
                                onPress={() => this.showFotoBack()}
                              >
                                <View style={styles.fotoButton}>
                                  <Text style={styles.fotoText}>Ver foto</Text>
                                </View>
                              </TouchableHighlight>
                            </View>
                          )}
                        </View>
                      )}

                      <View style={{ width: "100%", height: 15 }} />
                      <Text
                        style={styles.title}
                        onLayout={(event) => {
                          this.onLayout(event, 5);
                        }}
                      >
                        Observaciones
                      </Text>
                      <View style={styles.field}>
                        <InputOrText
                          editable={editable}
                          reference={(e) => (this.obsRef = e)}
                          style={[
                            styles.fieldInput,
                            {
                              fontWeight:
                                this.state.obs && this.state.obs.length > 0
                                  ? "500"
                                  : "300",
                              fontSize: 18,
                              height: 60,
                              textAlignVertical: "top",
                            },
                          ]}
                          valueInput={this.state.obs}
                          placeholder="Observaciones"
                          placeholderTextColor={colors.placeholder}
                          onChangeText={(text) => {
                            this.setState({
                              obs: text,
                            });
                          }}
                          onFocus={() => {
                            this.obsRef.setNativeProps({
                              style: {
                                backgroundColor: colors.focus,
                                color: colors.text4,
                              },
                            });
                          }}
                          onBlur={() => {
                            this.obsRef.setNativeProps({
                              style: [
                                styles.fieldInput,
                                {
                                  fontWeight:
                                    this.state.obs && this.state.obs.length > 0
                                      ? "500"
                                      : "300",
                                  fontSize: 18,
                                  height: 80,
                                  textAlignVertical: "top",
                                },
                              ],
                            });
                          }}
                          multiline={true}
                          numberOfLines={1}
                        />
                      </View>

                      <View style={{ width: "100%", height: 10 }} />
                      <View style={styles.buttonsContainer}>
                        {editable ? (
                          <>
                            <TouchableHighlight
                              disabled={this.state.loading}
                              style={styles.touch}
                              underlayColor={colors.field}
                              onPress={() => this.showCancelDialog()}
                            >
                              <View style={styles.cancelButton}>
                                <Text style={styles.cancelText}>Cancelar</Text>
                              </View>
                            </TouchableHighlight>

                            <TouchableHighlight
                              disabled={this.state.loading}
                              style={styles.touch}
                              underlayColor={colors.field}
                              onPress={() => this.showConfirmDialog()}
                            >
                              <View style={styles.sendButton}>
                                {this.state.loading ? (
                                  <ActivityIndicator
                                    size="large"
                                    color={colors.success}
                                  />
                                ) : (
                                  <Text style={styles.sendText}>Confirmar</Text>
                                )}
                              </View>
                            </TouchableHighlight>
                          </>
                        ) : (
                          anulable && (
                            <TouchableHighlight
                              disabled={this.state.loading}
                              style={styles.touch}
                              underlayColor={colors.field}
                              onPress={() => this.showRevokeDialog()}
                            >
                              <View style={styles.revokeButton}>
                                {this.state.loading ? (
                                  <ActivityIndicator
                                    size="large"
                                    color={colors.danger}
                                  />
                                ) : (
                                  <Text style={styles.revokeText}>Anular</Text>
                                )}
                              </View>
                            </TouchableHighlight>
                          )
                        )}
                      </View>

                      <View style={{ width: "100%", height: 15 }} />
                    </View>

                    <View style={{ width: "100%", height: 300 }} />
                  </ScrollView>
                </View>
              </View>
            </>
          )}
        </SafeAreaView>
      </>
    );
  }
}

const withRef = (Component) => (props) => {
  const ref = useRef(null);
  return <Component {...props} ref={ref} />;
};

export default withRef(withGeneralState(withTheme(Canje)));
