import React, { Component } from "react";
import Menu from "../general/menuGenerico/menu";
import BasicCard from "../general/menuGenerico/basicCard";
import Toast from "react-native-root-toast";
import withTheme from "../general/withTheme";
import withGeneralState from "../general/withGeneralState";

class MenuPM extends Component {
  async componentDidMount() {
    if (!this.props.value.userData) {
      const colors = this.props.value.colors;

      const res = await fetch(this.props.value.proxy + "/accounts/getUserData");
      if (res.status == 200) {
        const userData = await res.json();
        this.props.value.setUserData(userData, () => {
          if (!this.props.value.userData.nombreSala) {
            this.props.navigation.reset({
              index: 0,
              routes: userData.idSala
                ? [
                    { name: "Salas" },
                    {
                      name: "Opciones",
                    },
                  ]
                : [{ name: "Salas" }],
            });
          } else {
            this.setState({});
          }
        });
      } else {
        const error = "Sesión expirada. Por favor ingrese nuevamente.";
        Toast.show(error, {
          duration: Toast.durations.LONG,
          backgroundColor: colors.danger,
          textColor: colors.text2,
        });
        fetch(this.props.value.proxy + "/accounts/logout", {
          method: "POST",
        }).then(() => {
          this.props.navigation.reset({
            index: 0,
            routes: [{ name: "Login" }],
          });
        });
      }
    }
  }

  render() {
    const value = this.props.value;
    const navigation = this.props.navigation;

    if (value.userData && value.userData.nombreSala) {
      return (
        <Menu title={"PM"} navigation={navigation}>
          <BasicCard
            backgroundColor={value.colors.success}
            onPress={async () => {
              const res = await fetch(value.proxy + "/accounts/getUserData");
              if (res.status == 200) {
                navigation.navigate("AgregarPM");
              } else {
                const error = "Sesión expirada. Por favor ingrese nuevamente.";
                Toast.show(error, {
                  duration: Toast.durations.LONG,
                  backgroundColor: value.colors.danger,
                  textColor: value.colors.text2,
                });
                await fetch(value.proxy + "/accounts/logout", {
                  method: "POST",
                });
                navigation.reset({
                  index: 0,
                  routes: [{ name: "Login" }],
                });
              }
            }}
            title="Agregar PM"
          />
          {value.userData.rol.toUpperCase() === "SUPERVISOR" && (
            <BasicCard
              backgroundColor={value.colors.warning}
              onPress={() => {
                navigation.navigate("ConsultarPM");
              }}
              title="Consultar PM"
            />
          )}
          <BasicCard
            backgroundColor={value.colors.info}
            onPress={() => {
              navigation.navigate("MisPM");
            }}
            title="Mis PM"
          />
        </Menu>
      );
    } else {
      return <></>;
    }
  }
}

export default withGeneralState(withTheme(MenuPM));
