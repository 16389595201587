import React, { Component } from "react";

import { ThemeProvider } from "./components/general/theme";
import { GeneralProvider } from "./components/general/generalProvider";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { RootSiblingParent } from "react-native-root-siblings";
import { StatusBar, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { MenuProvider } from "react-native-popup-menu";
import Login from "./components/login/login";
import CambiarPass from "./components/cambiarPass/cambiarPass";
import OlvidoPass from "./components/olvidoPass/olvidoPass";
import Salas from "./components/salas/salas";
import Opciones from "./components/opciones/opciones";
import MenuPM from "./components/pagosManuales/menuPM";
import MisPM from "./components/pagosManuales/misPM";
import ConsultarPM from "./components/pagosManuales/consultarPM";
import AgregarPM from "./components/pagosManuales/agregarPM";
import MenuPromoTKTs from "./components/promoTKTs/menuPromoTKTs";
import Promociones from "./components/promoTKTs/promociones/promociones";
import Canje from "./components/promoTKTs/canjes/canje";
import VistaCanjes from "./components/promoTKTs/canjes/vistaCanjes";
import { LogBox } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import { registerTranslation } from "react-native-paper-dates";
import "setimmediate";

import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";

if (Platform.OS === "web") {
  serviceWorkerRegistration.register();
}

LogBox.ignoreAllLogs();

registerTranslation("es", {
  save: "Guardar",
  selectSingle: "Seleccionar fecha",
  selectMultiple: "Seleccionar fechas",
  selectRange: "Seleccionar rango",
  notAccordingToDateFormat: (inputFormat) =>
    `El formato debe ser ${inputFormat}`,
  mustBeHigherThan: (date) => `Debe ser mayor a ${date}`,
  mustBeLowerThan: (date) => `Debe ser menor a ${date}`,
  mustBeBetween: (startDate, endDate) =>
    `Debe estar entre ${startDate} y ${endDate}`,
  dateIsDisabled: "Día no permitido",
  previous: "Anterior",
  next: "Siguiente",
  typeInDate: "Escribir fecha",
  pickDateFromCalendar: "Elegir fecha de calendario",
  close: "Cerrar",
});

const Stack = createNativeStackNavigator();

ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);

class App extends Component {
  state = { userData: null };

  setUserData(userData, callback) {
    this.setState({ userData: userData }, callback);
  }

  render() {
    return (
      <SafeAreaProvider style={{ backgroundColor: "black" }}>
        <MenuProvider>
          <RootSiblingParent>
            <GeneralProvider
              userData={this.state.userData}
              setUserData={(userData, callback) => {
                this.setUserData(userData, callback);
              }}
              //proxy={"https://app-netbi-eerr.netgen.com.ar/api"}
              //proxy={"http://192.168.0.192:27015/api"}
              proxy={"/api"}
            >
              <ThemeProvider>
                <StatusBar />
                <NavigationContainer
                  documentTitle={{
                    formatter: () => "NEOGAME",
                  }}
                  linking={{
                    config: {
                      screens: {
                        Login: "",
                        OlvidoPass: "forgotPassword",
                        CambiarPass: "changePassword",
                        Salas: "salas",
                        Opciones: "opciones",
                        PagosManuales: "pagosManuales",
                        AgregarPM: "pagosManuales/agregar",
                        ConsultarPM: "pagosManuales/consultar",
                        MisPM: "pagosManuales/misPM",
                        PromoTKTs: "promoTKTs",
                        Promociones: "promoTKTs/promociones",
                        Canjear: "promoTKTs/promociones/canjear",
                        Canjes: "promoTKTs/canjes",
                        ConsultarCanje: "promoTKTs/canjes/consultar",
                      },
                    },
                  }}
                >
                  <Stack.Navigator
                    initialRouteName="Login"
                    screenOptions={{
                      headerShown: false,
                    }}
                  >
                    <Stack.Screen name="Login" component={Login} />
                    <Stack.Screen name="OlvidoPass" component={OlvidoPass} />
                    <Stack.Screen name="CambiarPass" component={CambiarPass} />
                    <Stack.Screen name="Salas" component={Salas} />
                    <Stack.Screen name="Opciones" component={Opciones} />
                    <Stack.Screen name="PagosManuales" component={MenuPM} />
                    <Stack.Screen name="AgregarPM" component={AgregarPM} />
                    <Stack.Screen name="ConsultarPM" component={ConsultarPM} />
                    <Stack.Screen name="MisPM" component={MisPM} />
                    <Stack.Screen name="PromoTKTs" component={MenuPromoTKTs} />
                    <Stack.Screen name="Promociones" component={Promociones} />
                    <Stack.Screen
                      name="Canjear"
                      component={Canje}
                      initialParams={{ editable: true }}
                    />
                    <Stack.Screen name="Canjes" component={VistaCanjes} />
                    <Stack.Screen
                      name="ConsultarCanje"
                      component={Canje}
                      initialParams={{ editable: false }}
                    />
                  </Stack.Navigator>
                </NavigationContainer>
              </ThemeProvider>
            </GeneralProvider>
          </RootSiblingParent>
        </MenuProvider>
      </SafeAreaProvider>
    );
  }
}

export default App;
